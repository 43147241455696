import React, {FC} from "react";
import {Customer} from "../../models";

export interface InputProps {
    customer?: Customer|null;
}

const CurrencyPrefix: FC<InputProps> = (props) => {
    const {customer} = props;
    return (
        (customer?.company?.currencyPrefix && <span className={'transaction-currency-prefix'} dangerouslySetInnerHTML={{__html: customer.company.currencyPrefix}}/>)
        || null
    )
};

export default CurrencyPrefix;
