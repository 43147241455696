import {ToastOptions} from "@ionic/core/dist/types/components/toast/toast-interface";

export const type = 'modal-pages';

export interface ModalPageState extends ToastOptions {
    isOpenWaitingBookingAccept: boolean,
    isOpenAbout: boolean,
    isOpenPrivacy: boolean,
    isOpenTermsOfUse: boolean,
}

export const initialState: ModalPageState = {
    isOpenWaitingBookingAccept: false,
    isOpenAbout: false,
    isOpenPrivacy: false,
    isOpenTermsOfUse: false,
};
