import {createAsyncThunk} from '@reduxjs/toolkit';

import {type} from './state';
import {bookingsService, PostBookingCancelArgs, PostBookingCreateArgs, PostBookingDeleteArgs} from "../../services";
import {ApiResponse} from "../../models";
import {createClearAllAsyncThunk, createClearErrorAsyncThunk} from "../shared-actions";

export const getAllBookings = createAsyncThunk<ApiResponse, { customerId?: number, learnerId?: number }>(
    `${type}/getAllBookings`,
    async (args, {rejectWithValue}) => {
        try {
            const {customerId, learnerId} = args;
            return await bookingsService.getAllBookings(customerId, learnerId);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const bookingCreateAction = createAsyncThunk(
    `${type}/bookingCreateAction`,
    async (args: PostBookingCreateArgs, {rejectWithValue}) => {
        try {
            return await bookingsService.postBookingCreate(args);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const bookingDeleteAction = createAsyncThunk(
    `${type}/bookingDeleteAction`,
    async (args: PostBookingDeleteArgs, {rejectWithValue}) => {
        try {
            const result = await bookingsService.postBookingDelete(args);
            return {bookingId: args.bookingId, message: result?.message};
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const bookingCancelAction = createAsyncThunk(
    `${type}/bookingCancelAction`,
    async (args: PostBookingCancelArgs, {rejectWithValue}) => {
        try {
            const result = await bookingsService.postBookingCancel(args);
            return {message: result?.message};
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const clearErrorsAction = createClearErrorAsyncThunk(type);
export const clearAllAction = createClearAllAsyncThunk(type);

