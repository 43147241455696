import {unwrapResult} from '@reduxjs/toolkit';
import {useCallback} from 'react';

import {clearAllAction, getAllAction, setSelectedIdAction,} from './actions';
import {
    communicationsSelector,
    errorMessageSelector,
    isFetchingSelector,
    selectedCommunicationSelector,
    selectedIdSelector,
} from './selectors';

// Action (service) interfaces
import {useAppDispatch, useAppSelector} from "../hooks";
import {AppDispatch} from "../store";

export const useCommunicationsStore = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const communications = useAppSelector(communicationsSelector);
    const isFetching = useAppSelector(isFetchingSelector);
    const selectedId = useAppSelector(selectedIdSelector);
    const selectedCommunication = useAppSelector(selectedCommunicationSelector);
    const errorMessage = useAppSelector(errorMessageSelector);

    const getAll = useCallback(
        (args: {customerId?: number, learnerId?: number}) => {
            return dispatch(getAllAction(args)).then(unwrapResult);
        },
        [dispatch]
    );

    const setSelectedId = useCallback(
        (args: {id?: number}) => {
            return dispatch(setSelectedIdAction(args)).then(unwrapResult);
        },
        [dispatch]
    );

    const clearAll = useCallback(
        () => {
            return dispatch(clearAllAction()).then(unwrapResult);
        },
        [dispatch]
    );


    return {
        isFetching,
        errorMessage,
        selectedCommunication,
        communications,
        getAll,
        clearAll,
        selectedId,
        setSelectedId,
    } as const;
};
