import React from "react";
import {IonButton} from "@ionic/react";
import {Booking, Company, ProfilePackage, Slot} from "../../models";
import BookingAddToCalendarButton from "../bookings/BookingAddToCalendarButton";

interface SlotButtonsProps {
    company: Company;
    slot: Slot;
    profilePackage: ProfilePackage;
    visibleBookingIds: {[id: number]: number};
    visibleBookings?: Booking[];
    visibleWaitingBookingIds: {[id: number]: number};
    canAction: boolean;
    slotDescription: string;
    onBookClicked: (slotId: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
    onDeleteClicked: (bookingId: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
    onCancelClicked: (bookingId: number, lessonDateTs: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
    onWaitClicked: (slotId: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
    onCancelWaitClicked: (waitingBookingId: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
    deleteText?: string;
    cancelText?: string;
    bookText?: string;
    waitText?: string;
    waitCancelText?: string;

    expand?: 'full' | 'block';
    fill?: 'clear' | 'outline' | 'solid' | 'default';
}

const SlotButtons: React.FC<SlotButtonsProps> = ({
                                                     company,
                                                     slot,
                                                     profilePackage,
                                                     visibleBookings,
                                                     visibleBookingIds,
                                                     visibleWaitingBookingIds,
                                                     canAction,
                                                     slotDescription,
                                                     onCancelClicked,
                                                     onDeleteClicked,
                                                     onCancelWaitClicked,
                                                     onWaitClicked,
                                                     onBookClicked,
                                                     deleteText = 'Cancel',
                                                     cancelText = 'Cancel',
                                                     bookText = 'Book',
                                                     waitText = 'Waiting List',
                                                     waitCancelText = 'Cancel Waiting',
                                                     expand,
                                                     fill,
                                                 }) => {
    const isBooked = slot.isBooked;
    const isWaitingBooked = slot.isWaitingBooked;
    const canBook = slot.canBook;
    const canDelete = slot.canDelete;
    const canWait = slot.canWait;
    const canCancelWait = slot.canCancelWait;
    const isRecurring = profilePackage?.isRecurring;
    const loadedBookingId = visibleBookingIds[slot.id];
    const loadedWaitingBookingId = visibleWaitingBookingIds[slot.id];
    const loadedBooking: Booking|undefined = (visibleBookings && loadedBookingId) ? visibleBookings.find((b) => b.id === loadedBookingId) : undefined;

    const cancelBtn = isBooked && isRecurring && slot.canCancel && !!loadedBookingId && (
        <IonButton
            key={`slot-${slot.id}-cancel`}
            disabled={canAction}
            color={"warning"}
            expand={expand}
            fill={fill}
            onClick={(event) =>
                onCancelClicked(loadedBookingId, slot.lessonDateTs, slotDescription, event)
            }
        >
            {cancelText}
        </IonButton>
    );

    const deleteBtn = isBooked && !isRecurring && canDelete && !!loadedBookingId && (
        <IonButton
            key={`slot-${slot.id}-delete`}
            disabled={canAction}
            color={"warning"}
            expand={expand}
            fill={fill}
            onClick={(event) => onDeleteClicked(loadedBookingId, slotDescription, event)}
        >
            {deleteText}
        </IonButton>
    );

    const bookBtn = canBook && !isBooked && !loadedBookingId && !!slot.availableCount && (
        <IonButton
            key={`slot-${slot.id}-book`}
            disabled={canAction}
            color={"primary"}
            expand={expand}
            fill={fill}
            onClick={(event) => onBookClicked(slot.id, slotDescription, event)}
        >
            {bookText}
        </IonButton>
    );

    const waitBtn = canWait && !isBooked && !isWaitingBooked && !loadedWaitingBookingId && (
        <IonButton
            key={`slot-${slot.id}-wait`}
            disabled={canAction}
            color={"primary"}
            expand={expand}
            fill={fill}
            onClick={(event) => onWaitClicked(slot.id, slotDescription, event)}
        >
            {waitText}
        </IonButton>
    );

    const cancelWaitBtn = canCancelWait && isWaitingBooked && !isBooked && !!loadedWaitingBookingId && (
        <IonButton
            key={`slot-${slot.id}-cancel-wait`}
            disabled={canAction}
            color={"warning"}
            expand={expand}
            fill={fill}
            onClick={(event) => onCancelWaitClicked(loadedWaitingBookingId, slotDescription, event)}
        >
            {waitCancelText}
        </IonButton>
    );

    const addToCalendarBtn = isBooked && !isRecurring && canDelete && !!loadedBookingId && !!loadedBooking && (
        <BookingAddToCalendarButton
            company={company}
            booking={loadedBooking}
            profilePackage={profilePackage}
            key={`slot-${slot.id}-add-to-calendar`}
            canAction={canAction}
            expand={expand}
            fill={fill}
        />
    );

    if (!profilePackage || (!cancelBtn && !deleteBtn && !bookBtn && !waitBtn && !cancelWaitBtn)){
        return null;
    }
    return (
        <>
            {addToCalendarBtn}
            {cancelBtn}
            {deleteBtn}
            {bookBtn}
            {waitBtn}
            {cancelWaitBtn}
        </>
    );
};

export default SlotButtons;
