import {unwrapResult} from '@reduxjs/toolkit';
import {useCallback} from 'react';

import {
    bookingCancelAction,
    bookingCreateAction,
    bookingDeleteAction,
    clearAllAction,
    clearErrorsAction,
    getAllBookings
} from './actions';
import {
    bookingsSelector,
    errorMessageSelector,
    isChangingBookingSelector,
    isFetchingBookingsSelector,
    selectedIdSelector,
} from './selectors';

// Action (service) interfaces
import {PostBookingCancelArgs, PostBookingCreateArgs, PostBookingDeleteArgs} from "../../services";

import {useAppDispatch, useAppSelector} from "../hooks";
import {AppDispatch} from "../store";

export const useBookingsStore = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const bookings = useAppSelector(bookingsSelector);
    const isFetching = useAppSelector(isFetchingBookingsSelector);
    const selectedId = useAppSelector(selectedIdSelector);
    const isChangingBooking = useAppSelector(isChangingBookingSelector);
    const errorMessage = useAppSelector(errorMessageSelector);

    const getAll = useCallback(
        (args: {customerId?: number, learnerId?: number}) => {
            return dispatch(getAllBookings(args)).then(unwrapResult);
        },
        [dispatch]
    );

    const bookingCreate = useCallback(
        (args: PostBookingCreateArgs) => {
            return dispatch(bookingCreateAction(args)).then(unwrapResult);
        },
        [dispatch]
    );

    const bookingDelete = useCallback(
        (args: PostBookingDeleteArgs) => {
            return dispatch(bookingDeleteAction(args)).then(unwrapResult);
        },
        [dispatch]
    );

    const bookingCancel = useCallback(
        (args: PostBookingCancelArgs) => {
            return dispatch(bookingCancelAction(args)).then(unwrapResult);
        },
        [dispatch]
    );

    const clearAll = useCallback(
        () => {
            return dispatch(clearAllAction()).then(unwrapResult);
        },
        [dispatch]
    );


    const clearErrors = useCallback(
        () => {
            return dispatch(clearErrorsAction()).then(unwrapResult);
        },
        [dispatch]
    );


    return {
        isFetching,
        isChangingBooking,
        errorMessage,
        bookings,
        getAll,
        clearAll,
        clearErrors,
        selectedId,
        bookingCreate,
        bookingDelete,
        bookingCancel,
    } as const;
};
