import {createSelector} from '@reduxjs/toolkit';

import {State, type} from './state';
import {AuthStatus} from "../../models";

const featureStateSelector = (state: { [type]: State }) =>
    state[type];


export const isAuthedSelector = createSelector(
    featureStateSelector,
    state => state.status === AuthStatus.LOGGED_IN
);

export const isEmailVerifiedSelector = createSelector(
    featureStateSelector,
    state => state.status === AuthStatus.LOGGED_IN && state?.user?.isEmailVerified
);

export const authEmailSelector = createSelector(
    featureStateSelector,
    state => state.status === AuthStatus.NOT_LOGGED_IN ? state.authEmail : undefined
);

export const authTokenSelector = createSelector(
    featureStateSelector,
    state => state.apiAuth?.token
);

export const authIdSelector = createSelector(
    featureStateSelector,
    state => state.apiAuth?.token
);

export const authFcmTokenSelector = createSelector(
    featureStateSelector,
    state => state.fcmToken
);

export const utcOffsetSelector = createSelector(
    featureStateSelector,
    state => state.utcOffset
);

export const dstOffsetSelector = createSelector(
    featureStateSelector,
    state => state.dstOffset
);

export const authHeaderValueSelector = createSelector(
    featureStateSelector,
    state => `${state.apiAuth?.tokenType} ${state.apiAuth?.token}`
);


export const authErrorMessageSelector = createSelector(
    featureStateSelector,
    state => state.errorMessage
);

export const authErrorsSelector = createSelector(
    featureStateSelector,
    state => state.errors
);

export const authFormMessageSelector = createSelector(
    featureStateSelector,
    state => state.formMessage
);

export const apiAuthSelector = createSelector(
    featureStateSelector,
    state => state.apiAuth
)

export const authStatusSelector = createSelector(
    featureStateSelector,
    state => state.status
);

export const userNameSelector = createSelector(
    featureStateSelector,
    state => state.user?.name
);

export const userUuidSelector = createSelector(
    featureStateSelector,
    state => state.user?.uuid
);

export const userSelector = createSelector(
    featureStateSelector,
    state => state.user
);

export const isLoadingSelector = createSelector(
    featureStateSelector,
    state => state.isLoading
);

export const isLoggingOutSelector = createSelector(
    featureStateSelector,
    state => state.isLoggingOut
);

export const isUpdatingSelector = createSelector(
    featureStateSelector,
    state => state.isUpdating
);
