import {unwrapResult} from '@reduxjs/toolkit';
import {useCallback} from 'react';

import {clearAllAction, clearErrorsAction, deleteAction, getAction, setIsOpenDeleteModalAction,} from './actions';

import {useAppDispatch, useAppSelector} from "../hooks";
import {AppDispatch} from "../store";
import {errorMessageSelector, isLoadingSelector, isOpenDeleteModalSelector} from "./selectors";

export const useUserStore = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const isLoading = useAppSelector(isLoadingSelector);
    const errorMessage = useAppSelector(errorMessageSelector);
    const isOpenDeleteModal = useAppSelector(isOpenDeleteModalSelector);

    const me = useCallback(
        () => {
            return dispatch(getAction()).then(unwrapResult);
        },
        [dispatch]
    );

    const userDelete = useCallback(
        (args: {password: string}) => {
            return dispatch(deleteAction(args)).then(unwrapResult);
        },
        [dispatch]
    );

    const setIsOpenDeleteModal = useCallback(
        (isOpen: boolean) => {
            return dispatch(setIsOpenDeleteModalAction(isOpen)).then(unwrapResult);
        },
        [dispatch]
    );


    const clearAll = useCallback(
        () => {
            return dispatch(clearAllAction()).then(unwrapResult);
        },
        [dispatch]
    );


    const clearErrors = useCallback(
        () => {
            return dispatch(clearErrorsAction()).then(unwrapResult);
        },
        [dispatch]
    );

    return {
        isLoading,
        isOpenDeleteModal,
        setIsOpenDeleteModal,
        me,
        userDelete,
        errorMessage,
        clearAll,
        clearErrors,
    } as const;
};
