import {
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonChip,
    IonIcon,
    IonImg,
    IonItem,
    IonLabel,
    IonList
} from '@ionic/react';
import * as React from 'react';
import {RefObject} from 'react';

import Page from "../../../components/core/Page";
import './customer-selector-page.component.css';
import {Customer, Learner} from "../../../models";
import {person, personOutline} from 'ionicons/icons';

type Props = {
    activeCustomer: Customer | null;
    // onRefresh: () => void;
    doRefresh: (event: CustomEvent) => void,
    refresherRef?: RefObject<HTMLIonRefresherElement> | ((ref: HTMLIonRefresherElement | null) => void),
    isEmailVerified: boolean;
    isFetching: boolean;
    customerSelected: (selectedId: number) => void;
    customers: Customer[];
};

export const CustomerSelectorPage: React.FC<Props> = props => {
    const {
        customerSelected,
        doRefresh,
        refresherRef,
        isEmailVerified,
        isFetching,
        customers,
    } = props;
    const hasCustomers = (customers && customers.length > 0);

    return (
        <Page className={"customer-selector-page"}
              title={'Switch Account'}
              showMenuButton={true}
              showBackButton={false}
              // topRightButton={<IonButton slot={'end'} onClick={onRefresh}><IonIcon icon={refresh}/></IonButton>}
              showLoading={(isFetching && !hasCustomers)}

              doRefresh={doRefresh}
              refresherRef={refresherRef}
        >
            <IonCard>
                <IonCardHeader>
                    {
                        (!isEmailVerified &&
                            <IonCardSubtitle color={'danger'}>
                                Please verify your email address to continue.
                            </IonCardSubtitle>
                        )
                        ||
                        (hasCustomers && (
                            <IonCardSubtitle color={'primary'}>
                                Please select an account
                            </IonCardSubtitle>
                        ))
                        ||
                        (!isFetching && (
                            <IonCardTitle color={'danger'}>
                                No Accounts found
                                <IonCardSubtitle>Please contact your provider to enable and activate your
                                    account.</IonCardSubtitle>
                            </IonCardTitle>
                        ))}
                </IonCardHeader>
            </IonCard>

            <div className="header-logo-wrapper ion-text-center">
            </div>

            {isEmailVerified &&
                <IonList className={"customer-options"}>
                    {
                        (customers.length > 0) && customers && customers.map((customer: Customer) => (
                            <IonItem button key={`customer-option-${customer.id}`}
                                     onClick={() => customerSelected(customer.id)}
                            >
                                {customer.company?.appLogoUrl &&
                                    <div className={'company-logo-holder'}>
                                        <IonImg src={customer.company?.appLogoUrl} className={"company-logo"}/>
                                    </div>
                                }
                                <IonLabel>
                                    <h2>{customer.company?.name}</h2>
                                    <h3>{customer.fullName}</h3>
                                    {
                                        customer.learners && (customer.learners.length > 0) &&
                                        (
                                            <>
                                                {
                                                    customer.learners && customer.learners.map((learner: Learner) => (
                                                        <IonChip key={`customer-learner-${learner.id}`}>
                                                            <IonIcon icon={person} ios={person} md={personOutline}/>
                                                            <IonLabel>{learner.fullName}</IonLabel>
                                                        </IonChip>
                                                    ))
                                                }
                                            </>
                                        )
                                    }
                                </IonLabel>
                            </IonItem>
                        ))
                    }
                </IonList>
            }
        </Page>
    );
};
