import React from "react";
import {Booking, Company, ProfilePackage} from "../../models";
import BookingAddToCalendarButton from "./BookingAddToCalendarButton";

interface BookingButtonsProps {
    company: Company;
    booking: Booking;
    profilePackage: ProfilePackage;
    canAction?: boolean;

    calendarText?: string;
    calendarEnabled?: boolean;

    expand?: 'full' | 'block';
    fill?: 'clear' | 'outline' | 'solid' | 'default';
}

const BookingButtons: React.FC<BookingButtonsProps> = (props, context) => {
    const {profilePackage,} = props;

    const calendarButton = <BookingAddToCalendarButton {...props} />;

    if (!profilePackage || (!calendarButton)){
        return null;
    }
    return (
        <>
            {calendarButton}
        </>
    );
};

export default BookingButtons;
