import React from 'react';
import {IonButton, IonChip, IonIcon, IonItem, IonLabel, IonModal, IonText,} from '@ionic/react';
import {Communication, CommunicationType} from '../../models';
import {Ages} from "../../utils/ages";
import './CommunicationModal.css';
import {atOutline, mailOutline, notificationsOutline} from "ionicons/icons";

interface CommunicationModalProps {
    isOpen: boolean;
    onClose: () => void;
    communication?: Communication;
}

const CommunicationModal: React.FC<CommunicationModalProps> = ({
                                                                   isOpen,
                                                                   onClose,
                                                                   communication,
                                                               }) => {
    return (
        <IonModal isOpen={!!communication && isOpen} onDidDismiss={onClose} className={`communication-modal`}>
            {communication && (
                <>
                    <IonItem lines="full">
                        <IonLabel>
                            <h2 className="ion-padding-bottom pre-wrap">
                                {communication.type === CommunicationType.EMAIL && <IonChip color="secondary">
                                    <IonIcon className={"communication-type"} icon={atOutline}/>
                                </IonChip>}
                                {communication.type === CommunicationType.PUSH && <IonChip color="tertiary">
                                    <IonIcon className={"communication-type"} icon={notificationsOutline}/>
                                </IonChip>}
                                {communication.type === CommunicationType.SMS && <IonChip color="success">
                                    <IonIcon className={"communication-type"} icon={mailOutline}/>
                                </IonChip>}
                                <IonText className="text-semi-bold">{communication.subject}</IonText>
                            </h2>
                            <p className="communication-message pre-wrap">{communication.message}</p>
                            <p className="communication-sent">
                                <IonText>
                                    <small>Sent: {new Ages(communication.sentAtTs).toFormat("dd mmm yyyy")}</small>
                                </IonText>
                            </p>
                        </IonLabel>
                    </IonItem>

                    <IonButton onClick={onClose} expand="full">Close</IonButton>
                </>
            )}
        </IonModal>
    );
};

export default CommunicationModal;
