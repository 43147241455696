import {createReducer} from '@reduxjs/toolkit';

import {adapter, initialState} from './state';
import {
    clearAllAction,
    clearErrorsAction,
    getAllProfilePackages,
    setSelectedCustomerIdAction,
    setSelectedCustomerLearnerId,
} from './actions';
import {ApiResponse} from "../../models";
import {setSelectedIdAction} from "../communications";
import {getAllSlotsWeeklyAction} from "../slots";

export const reducer = createReducer(initialState, builder =>
    builder
        .addCase(getAllProfilePackages.pending, state => {
            return {...state, isFetching: true, errorMessage: undefined};
        })
        .addCase(getAllProfilePackages.fulfilled, (state, action) => {
            const {profilePackages} = action.payload.results;
            return adapter.setAll({...state, isFetching: false}, profilePackages || []);
        })
        .addCase(getAllProfilePackages.rejected, (state, action) => {
            const { errorMessage } = action.payload as ApiResponse;
            return {...state, isFetching: false, errorMessage};
        })
        .addCase(getAllSlotsWeeklyAction.pending, state => {
            return {...state, isFetching: true, errorMessage: undefined};
        })
        .addCase(getAllSlotsWeeklyAction.fulfilled, (state, action) => {
            const {profilePackage} = action.payload.results;
            return adapter.updateOne({...state, isFetching: false}, {
                id: profilePackage.id,
                changes: {
                    bookingLimit: profilePackage.bookingLimit,
                    isRecurring: profilePackage.isRecurring,
                    lessonLimit: profilePackage.lessonLimit,
                    usedLessons: profilePackage.usedLessons,
                    lessonStartTs: profilePackage.lessonStartTs,
                    lessonEndTs: profilePackage.lessonEndTs,
                }
            });
        })
        .addCase(getAllSlotsWeeklyAction.rejected, (state, action) => {
            const { errorMessage } = action.payload as ApiResponse;
            return {...state, isFetching: false, errorMessage};
        })

        /** HANDLE NON-API ACTIONS ====================================== */
        .addCase(setSelectedIdAction.fulfilled, (state, action) => {
            const {selectedId} = action.payload;
            return {...state, selectedId: selectedId};
        })
        .addCase(setSelectedCustomerIdAction.fulfilled, (state, action) => {
            const {selectedId} = action.payload;
            return {...state, selectedId: selectedId};
        })
        .addCase(setSelectedCustomerLearnerId.fulfilled, (state, action) => {
            const {selectedLearnerId} = action.payload;
            return {...state, selectedLearnerId: selectedLearnerId};
        })

        .addCase(clearAllAction.fulfilled, () => adapter.removeAll({...initialState}))
        .addCase(clearErrorsAction.fulfilled, state => ({...state, errorMessage: undefined}))
);
