import {unwrapResult} from '@reduxjs/toolkit';
import {useCallback} from 'react';

import {
    clearAllAction,
    clearErrorsAction,
    getAllProfilePackages,
    setSelectedCustomerIdAction,
    setSelectedCustomerLearnerId,
} from './actions';
import {
    errorMessageProfilePackagesSelector,
    isFetchingProfilePackagesSelector,
    profilePackagesSelector,
    selectedIdSelector,
} from './selectors';
import {useAppDispatch, useAppSelector} from "../hooks";
import {AppDispatch} from "../store";
import {setSelectedIdAction} from "../communications";

export const useProfilePackageStore = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const isFetching = useAppSelector(isFetchingProfilePackagesSelector);
    const errorMessage = useAppSelector(errorMessageProfilePackagesSelector);
    const profilePackages = useAppSelector(profilePackagesSelector);
    const selectedId = useAppSelector(selectedIdSelector);

    const getAll = useCallback(
        (args: {customerId?: number, learnerId?: number}) => {
            return dispatch(getAllProfilePackages(args)).then(unwrapResult);
        },
        [dispatch]
    );

    const clearAll = useCallback(
        () => {
            return dispatch(clearAllAction()).then(unwrapResult);
        },
        [dispatch]
    );

    const clearErrors = useCallback(
        () => {
            return dispatch(clearErrorsAction()).then(unwrapResult);
        },
        [dispatch]
    );

    const setSelectedId = useCallback(
        (arg: { id?: number }) => {
            return dispatch(setSelectedIdAction(arg)).then(unwrapResult);
        },
        [dispatch]
    );

    const setSelectedCustomerId = useCallback(
        (arg: { id: number }) => {
            return dispatch(setSelectedCustomerIdAction(arg)).then(unwrapResult);
        },
        [dispatch]
    );

    const setSelectedLearnerId = useCallback(
        (arg: { learnerId: number }) => {
            return dispatch(setSelectedCustomerLearnerId(arg)).then(unwrapResult);
        },
        [dispatch]
    );

    return {
        isFetching,
        errorMessage,
        profilePackages,
        getAll,
        clearAll,
        clearErrors,
        setSelectedCustomerId,
        setSelectedLearnerId,
        setSelectedId,
        selectedId,
    } as const;
};
