import {createEntityAdapter, EntityState} from '@reduxjs/toolkit';

import {Booking} from '../../models';

export const type = 'bookings';

export interface BookingsState extends EntityState<Booking, number> {
    isFetching: boolean;
    isChangingBooking: boolean;
    selectedId: string | null;
    errorMessage?: string;
}

export const adapter = createEntityAdapter<Booking>({
});

export const initialState: BookingsState = adapter.getInitialState({
    isFetching: false,
    isChangingBooking: false,
    selectedId: null,
    errorMessage: undefined,
});
