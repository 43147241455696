import {ApiResponse, WaitingBooking} from "../models";
import apiClient from "./api/apiClient";
import {handleApiError} from "./api/apiErrorHandler";

interface getAllWaitingBookingsResponse extends ApiResponse {
    results: { waitingBookings: WaitingBooking[] },
}

interface getByIdWaitingBookingsResponse extends ApiResponse {
    results: { waitingBooking: WaitingBooking },
}

interface PostWaitingBookingCreateResponse extends ApiResponse {
    results: { waitingBooking: WaitingBooking },
}

export interface PostWaitingBookingCreateArgs {
    profilePackageId: number;
    slotId: number;
    startedTimestamp: number;
}

export interface PostWaitingBookingDeleteArgs {
    waitingBookingId: number;
}


class WaitingBookingsService {
    async getAll(customerId?: number, learnerId?: number): Promise<getAllWaitingBookingsResponse> {
        let url = this.baseUrl;
        if (customerId || learnerId) {
            url += '?';
        }
        if (customerId) {
            url += `customer_id=${customerId}`;
            if (learnerId) {
                url += '&';
            }
        }
        if (learnerId) {
            url += `learner_id=${learnerId}`;
        }
        return await apiClient
            .get(url)
            .then(
                (response) => response.data,
                (error) => handleApiError(error, "Failed to retrieve waiting bookings data.")
            )
    }

    async getById(waitingBookingId: number): Promise<getByIdWaitingBookingsResponse> {
        return await apiClient
            .get(`${this.baseUrl}/${waitingBookingId}`)
            .then(
                (response) => response.data,
                (error) => handleApiError(error, "Failed to retrieve waiting booking data.")
            )
    }

    async postCreate(args: PostWaitingBookingCreateArgs): Promise<PostWaitingBookingCreateResponse> {
        return await apiClient
            .post(this.baseUrl + `/add`, {
                slot_id: args.slotId,
                profile_package_id: args.profilePackageId,
                started: args.startedTimestamp,
            })
            .then(
                (response) => response.data,
                (error) => handleApiError(error, "Failed to add to the waiting list.")
            )
    }

    async setSelectedId(waitingBookingId?: number): Promise<any> {
        const fakePromise = () => {
            return new Promise(resolve => {
                resolve({selectedId: waitingBookingId});
            })
        }
        await fakePromise();
        return await fakePromise();
    }

    async postDelete(args: PostWaitingBookingDeleteArgs): Promise<ApiResponse> {
        return await apiClient
            .post(this.baseUrl + `/delete/${args.waitingBookingId}`)
            .then(
                (response) => response.data,
                (error) => handleApiError(error, "Failed to remove waiting booking.")
            )
    }

    constructor(private readonly baseUrl: string) {
    }
}

export const waitingBookingsService = new WaitingBookingsService(
    '/waiting-bookings'
);
