import {unwrapResult} from '@reduxjs/toolkit';
import {useCallback} from 'react';

import {setIsOpenAboutAction, setIsOpenPrivacyAction, setIsOpenTermsOfUseAction,} from './actions';
import {isOpenAboutSelector, isOpenPrivacySelector, isOpenTermsOfUseSelector,} from './selectors';

import {useAppDispatch, useAppSelector} from "../hooks";
import {AppDispatch} from "../store";

export const useModalPageStore = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const isOpenAbout = useAppSelector(isOpenAboutSelector);
    const isOpenPrivacy = useAppSelector(isOpenPrivacySelector);
    const isOpenTermsOfUse = useAppSelector(isOpenTermsOfUseSelector);

    const setIsOpenAbout = useCallback(
        (isOpen: boolean) => {
            return dispatch(setIsOpenAboutAction(isOpen)).then(unwrapResult);
        },
        [dispatch]
    );

    const setIsOpenPrivacy = useCallback(
        (isOpen: boolean) => {
            return dispatch(setIsOpenPrivacyAction(isOpen)).then(unwrapResult);
        },
        [dispatch]
    );

    const setIsOpenTermsOfUse = useCallback(
        (isOpen: boolean) => {
            return dispatch(setIsOpenTermsOfUseAction(isOpen)).then(unwrapResult);
        },
        [dispatch]
    );

    return {
        isOpenAbout,
        setIsOpenAbout,
        isOpenPrivacy,
        setIsOpenPrivacy,
        isOpenTermsOfUse,
        setIsOpenTermsOfUse,
    } as const;
};
