import {createAsyncThunk} from '@reduxjs/toolkit';

import {ApiResponse} from '../../models';
import {UserDeleteApiRequest, usersService} from '../../services';
import {type} from './state';
import {createClearAllAsyncThunk, createClearErrorAsyncThunk} from "../shared-actions";


export const getAction = createAsyncThunk<ApiResponse, void>(
    `${type}/getAction`,
    async (_, {rejectWithValue}) => {
        try {
            return await usersService.getUser();
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteAction = createAsyncThunk<ApiResponse, UserDeleteApiRequest>(
    `${type}/deleteAction`,
    async (args, {rejectWithValue}) => {
        try {
            return await usersService.userDelete({...args});
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const setIsOpenDeleteModalAction = createAsyncThunk(
    `${type}/setIsOpenDeleteModalAction`,
    async (isOpen?: boolean) => ({isOpen}));

export const clearErrorsAction = createClearErrorAsyncThunk(type);
export const clearAllAction = createClearAllAsyncThunk(type);
