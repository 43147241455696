import {createSelector} from '@reduxjs/toolkit';

import {adapter, CommunicationsState, type} from './state';

const {selectAll, selectEntities} = adapter.getSelectors();

const featureStateSelector = (state: { [type]: CommunicationsState }) => state[type];

const entitiesSelector = createSelector(
    featureStateSelector,
    selectEntities
);

export const selectedIdSelector = createSelector(
    featureStateSelector,
    state => state.selectedId
);


export const isFetchingSelector = createSelector(
    featureStateSelector,
    state => state.isFetching
);

export const errorMessageSelector = createSelector(
    featureStateSelector,
    (state) => state.errorMessage
);

export const communicationsSelector = createSelector(
    featureStateSelector,
    selectAll
);

export const selectedCommunicationSelector = createSelector(
    entitiesSelector,
    selectedIdSelector,
    (entities, selectedId) => (selectedId && entities[selectedId]) || undefined
);
