import {createAsyncThunk} from '@reduxjs/toolkit';

import {ApiResponse} from '../../models';
import {authService, LoginApiRequest, LoginApiResponse, passwordService} from '../../services';
import {type} from './state';
import {createClearAllAsyncThunk, createClearErrorAsyncThunk} from "../shared-actions";


export const getUserAction = createAsyncThunk<ApiResponse, void>(
    `${type}/getUserAction`,
    async (_, {rejectWithValue}) => {
        try {
            return await authService.getUser();
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const loginAction = createAsyncThunk<LoginApiResponse, LoginApiRequest>(
    `${type}/loginAction`,
    async (args, { rejectWithValue }) => {
        try {
            return await authService.login({...args});
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const forgotAction = createAsyncThunk<ApiResponse, { forgotEmail: string }>(
    `${type}/forgotAction`,
    async (arg, {rejectWithValue}) => {
        try {
            const {forgotEmail} = arg;
            return await passwordService.forgot(forgotEmail);
        }
        catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const setPasswordAction = createAsyncThunk<ApiResponse, { email: string, password: string, code: string }>(
    `${type}/setPasswordAction`,
    async (arg, {rejectWithValue}) => {
        try {
            const { email, password, code } = arg;
            return await passwordService.setPassword(email, password, code);
        }
        catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const logoutAction = createAsyncThunk<ApiResponse, {deviceUuid?: string}>(
    `${type}/logoutAction`,
    async (arg,  {rejectWithValue}) => {
        try {
            const {deviceUuid} = arg;
            await authService.logout(deviceUuid);
            return { message: 'Logout successful' };
        }
        catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const registerFcmTokenAction = createAsyncThunk<ApiResponse, { fcmToken: string, deviceUuid: string, platform: string }>(
    `${type}/registerFcmTokenAction`,
    async (arg, {rejectWithValue}) => {
        try {
            const {fcmToken, deviceUuid, platform} = arg;
            return await authService.registerFcmToken(fcmToken, deviceUuid, platform);
        }
        catch (error) {
            return rejectWithValue({ errorMessage: error });
        }
    }
);

export const deregisterFcmTokenAction = createAsyncThunk<ApiResponse, { fcmToken: string }>(
    `${type}/deregisterFcmTokenAction`,
    async (arg, {rejectWithValue}) => {
        try {
            const {fcmToken} = arg;
            return await authService.deregisterFcmToken(fcmToken);
        }
        catch (error) {
            return rejectWithValue({ errorMessage: error });
        }
    }
);

export const clearErrorsAction = createClearErrorAsyncThunk(type);
export const clearAllAction = createClearAllAsyncThunk(type);
