import {createEntityAdapter, EntityState} from '@reduxjs/toolkit';

import {WaitingBooking} from '../../models';

export const type = 'waitingBookings';

export interface WaitingBookingsState extends EntityState<WaitingBooking, number> {
    isFetching: boolean;
    isChangingWaitingBooking: boolean;
    selectedId: number | null;
    errorMessage?: string;
}

export const adapter = createEntityAdapter<WaitingBooking>({
});

export const initialState: WaitingBookingsState = adapter.getInitialState({
    isFetching: false,
    isChangingWaitingBooking: false,
    selectedId: null,
    errorMessage: undefined,
});
