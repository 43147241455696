import {IonButton, IonButtons, IonRouterLink} from '@ionic/react';
import * as React from 'react';

import Page from "../../../../components/core/Page";
import './auth-login-page.component.css';
import Input, {FormFieldInputs} from "../../../../components/input/Input";
import FormSubmitButton from "../../../../components/common/FormSubmitButton";
import PublicHeaderTitle from "../../../../components/core/PublicHeaderTitle";
import {useModalPageStore} from "../../../../redux/modal-pages";
import {UseFormReturn} from "react-hook-form";

type Props = {
    isLoading: boolean;
    errorMessage?: string | null;
    goToForgot: any;
    loginHandleSubmit: any;
    loginForm:  UseFormReturn;
    formFields: FormFieldInputs;
};

export const AuthLoginPage: React.FC<Props> = props => {
    const {isLoading, loginHandleSubmit, loginForm, formFields} = props;
    const {handleSubmit, register, control, formState: { errors }} = loginForm;

    const modalPageStore = useModalPageStore();
    const {setIsOpenPrivacy, setIsOpenTermsOfUse} = modalPageStore;

    return (
        <Page className={"auth-login-page"}
              title={<PublicHeaderTitle/>}
              showHeader={true}
              showMenuButton={false}
              showBackButton={false}
              showHeaderBackgroundColor={false}
              showLoading={isLoading}
              footer={
                  <IonButtons className="ion-justify-content-center">
                      <IonButton onClick={() => {setIsOpenPrivacy(true)}}>Privacy Policy</IonButton>
                      <IonButton onClick={() => {setIsOpenTermsOfUse(true)}}>Terms of Use</IonButton>
                  </IonButtons>}
        >
            {
                <>
                    <form onSubmit={handleSubmit(loginHandleSubmit)} className="ion-margin-bottom ion-padding-bottom">
                        {Object.entries(formFields).map(([fieldName, field], index) => (
                            <Input {...field} control={control} register={register} key={index} errors={errors} isDisabled={isLoading}/>
                        ))}
                        <FormSubmitButton outerClassName={'ion-text-center ion-margin-vertical'} disabled={isLoading}>Login</FormSubmitButton>
                    </form>
                    <IonRouterLink className="ion-margin" routerLink={"/auth/request-verify"}>First time here?</IonRouterLink>
                    <IonRouterLink className="ion-margin" routerLink={"/auth/forgot"}>Forgot Password?</IonRouterLink>
                </>
            }
        </Page>
    );
};
