import React from "react";
import {IonButton} from "@ionic/react";
import {Booking, Company, ProfilePackage} from "../../models";
import {Calendar} from "@awesome-cordova-plugins/calendar";
import {Ages} from "../../utils/ages";
import {useToastStore} from "../../redux/toast";

interface BookingAddToCalendarButtonProps {
    company: Company;
    booking: Booking;
    profilePackage: ProfilePackage;
    canAction?: boolean;

    calendarText?: string;
    calendarEnabled?: boolean;

    expand?: 'full' | 'block';
    fill?: 'clear' | 'outline' | 'solid' | 'default';
}

const BookingAddToCalendarButton: React.FC<BookingAddToCalendarButtonProps> = ({
                                                     company,
                                                     booking,
                                                     profilePackage,
                                                     canAction = false,
                                                     calendarText = "Add to Calendar",
                                                     calendarEnabled = true,
                                                     expand,
                                                     fill,
                                                 }) => {
    const toastStore = useToastStore();
    const isRecurring = profilePackage?.isRecurring;

    const handleCalendarClicked = (booking: Booking) => {
        const convertToLocalDate = function (timestamp: number) {
            /** This is a hack to get around the bad timezone management from the server side */
            const date = new Date(timestamp * 1000);
            const offset = date.getTimezoneOffset() * 60 * 1000;
            const targetOffset = -2 * 60 * 60 * 1000;
            return new Date(date.getTime() - offset + targetOffset);
        };

        const createEvent = () => {
            if (!booking || !booking.slot){return;}
            const slotStartTime = convertToLocalDate(booking.slot.startTimeTs);
            const slotEndTime = convertToLocalDate(booking.slot.endTimeTs);
            const bookingStartedDate = (new Ages(booking.startedTimestamp)).date;
            const bookingExpiredDate = isRecurring ? (booking.expiredTimestamp ? (new Ages(booking.expiredTimestamp)).date : undefined) : bookingStartedDate;
            const bookingStart = new Date(bookingStartedDate.getFullYear(),
                bookingStartedDate.getMonth(),
                bookingStartedDate.getDate(),
                slotStartTime.getHours(),
                slotStartTime.getMinutes()
            );
            const bookingEnd = !!bookingExpiredDate ? new Date(bookingExpiredDate.getFullYear(),
                    bookingExpiredDate.getMonth(),
                    bookingExpiredDate.getDate(),
                    slotEndTime.getHours(),
                    slotEndTime.getMinutes()
                ) : undefined;

            Calendar.createEventInteractivelyWithOptions(
                `${booking.slot.lessonType ? booking.slot.lessonType?.name : ''} ${company?.langLesson}`,
                `${booking.slot.facility?.name}`,
                `With ${booking.slot.employees?.map((employee) => employee.fullName).join(", ")}`,
                bookingStart,
                bookingEnd,
                {
                    id: `swool-booking-${booking.id}-${booking.startedTimestamp}`,
                    ...(isRecurring ? {
                        recurrence: "weekly",
                        recurrenceInterval: (slotEndTime.getTime() - slotStartTime.getTime()) / (1000 * 60)
                    } : {})
                }
            ).catch((reason) => {
                toastStore.showError({
                    header: "Calendar event could not be created",
                    message: reason,
                });
            })
        };

        Calendar.requestWritePermission()
            .then(() => {
                createEvent();
            }).catch((reason) => {
                toastStore.showError({
                    header: "Calendar is not accessible or permission is denied",
                    // message: reason,
                })
            }
        );
    }

    const calendarButton = calendarEnabled && (
        <IonButton
            key={`booking-${booking.id}-calendar`}
            disabled={canAction}
            color={"tertiary"}
            expand={expand}
            fill={fill}
            onClick={() => handleCalendarClicked(booking)}
        >
            {calendarText}
        </IonButton>
    );

    if (!profilePackage || (!calendarButton)){
        return null;
    }
    return (
        <>
            {calendarButton}
        </>
    );
};

export default BookingAddToCalendarButton;
