import React from "react";
import {IonButton, IonItem, IonSelect, IonSelectOption} from "@ionic/react";

import './SlotFilterModal.css';

interface SlotFilterProps {
    filterOptions?: any[],
    selectedId?: number;
    setSelectedId: (id?: number) => void;

    displayField: string;
    name: string;
}

const SlotFilter: React.FC<SlotFilterProps> = (
    {
        filterOptions,
        selectedId,
        setSelectedId,

        name,
        displayField,
    }) => {

    return (filterOptions && filterOptions.length > 0 && (selectedId || filterOptions.length > 1) &&
                <IonItem lines="full">
                    {
                        filterOptions.length > 1 && <IonSelect
                            value={selectedId}
                            aria-label={`Filter ${name}`}
                            interface="popover"
                            placeholder={`Filter ${name}`}
                            onIonChange={e => setSelectedId(e.detail.value)}
                        >
                            <IonSelectOption key={`filter-${displayField}-any`} value={null}>Any {name}</IonSelectOption>
                            {
                                filterOptions && filterOptions.map(option => (
                                    <IonSelectOption key={`filter-${name.toLowerCase()}-${option.id}`} value={option.id}
                                    >{`${option[displayField]}`}</IonSelectOption>))
                            }
                        </IonSelect>
                    }
                    {
                        selectedId && (!filterOptions.find(f => f.id === selectedId) || filterOptions.length === 1) &&
                        <IonButton onClick={() => {
                            setSelectedId(undefined)
                        }} color="light">
                            Clear {name}
                        </IonButton>
                    }
                </IonItem>) || <></>;
            ;
};

export default SlotFilter;
