import {IonButton, IonIcon, IonText, IonToolbar,} from '@ionic/react';
import * as React from 'react';

import Page from "../../../../components/core/Page";
import './document-page.component.css';
import {Customer, FinancialTransaction} from "../../../../models";
import SubtitleText from "../../../../components/common/SubtitleText/SubtitleText";
import {chevronBack, chevronForward} from "ionicons/icons";
import CurrencyPrefix from "../../../../components/financials/currency-prefix";
import Separator from "../../../../components/common/Seperator/Seperator";

type Props = {
    activeCustomer: Customer|null;
    document?: FinancialTransaction;
    nextDocumentAvailable: boolean;
    prevDocumentAvailable: boolean;
    goToPrevDocument: () => void;
    goToNextDocument: () => void;
};

export const DocumentPage: React.FC<Props> = props => {
    const {
        activeCustomer,
        document,
        nextDocumentAvailable,
        prevDocumentAvailable,
        goToPrevDocument,
        goToNextDocument,
    } = props;

    return (
        <Page className={"document-page"}
              title={'Transaction'}
              showMenuButton={false}
              showBackButton={true}
              backRouterLink={'/account'}
              topRightButton={false}
              showLoading={false}>


            { (nextDocumentAvailable || prevDocumentAvailable) &&
                <IonToolbar className={"document-paging-holder"}>
                    {prevDocumentAvailable &&
                        <IonButton slot={'start'} size={'small'} className={"ion-margin"} onClick={goToPrevDocument}><IonIcon icon={chevronBack}/> </IonButton>}
                    <IonText className={"text-bold"}>{document?.description}</IonText>
                    {nextDocumentAvailable &&
                        <IonButton slot={'end'} size={'small'} className={"ion-margin"} onClick={goToNextDocument}> <IonIcon icon={chevronForward}/></IonButton>}
                </IonToolbar>
            }

            <Separator className={'ion-margin-bottom'} />
            {document &&
                <div className={"document-details-holder ion-padding-horizontal"}>
                    <h1>{document.transactionContentTypeName}</h1>
                    <div><span>{document.transactionContentTypeName} #:</span> {document.description}</div>
                    <div><span>Date:</span> {document.transactionDateDisplay}</div>
                    <div><span>Amount:</span> <CurrencyPrefix customer={activeCustomer} />{document?.amountSignedDisplay}</div>
                </div>
            }
                {document?.notes &&
                    <>
                        <Separator className={'ion-margin-bottom'} />
                        <div>
                            <SubtitleText>Transaction Details</SubtitleText>
                            <div className={"ion-margin-start"}>
                                <IonText className={"transaction-notes pre-wrap"}>
                                    {document?.notes}
                                </IonText>

                            </div>
                        </div>
                    </>
                }
        </Page>
    );
};
