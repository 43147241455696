import {IonButton, IonText} from '@ionic/react';
import * as React from 'react';

import './auth-set-password-partial.component.css';
import Page from "../../../../components/core/Page";
import Input, {FormFieldInputs} from "../../../../components/input/Input";
import FormSubmitButton from "../../../../components/common/FormSubmitButton";
import PublicHeaderTitle from "../../../../components/core/PublicHeaderTitle";

type Props = {
    processTitle: string;
    processMessage?: string;
    isLoading: boolean;
    hasCode: boolean | null;
    requestCodeHandleSubmit: any;
    requestCodeForm: any;
    requestCodeFormFields: FormFieldInputs;
    setPasswordHandleSubmit: any;
    setPasswordForm: any;
    setPasswordFormFields: FormFieldInputs;
    chooseHasCodeClicked: (selectedHasCode: boolean) => void;
    goBackClicked: any;
    setHasCodeClicked: any;
};

export const AuthSetPasswordPartial: React.FC<Props> = props => {
    const {
        processTitle,
        processMessage,
        isLoading,
        hasCode,
        chooseHasCodeClicked,
        requestCodeFormFields,
        requestCodeHandleSubmit,
        requestCodeForm,
        setPasswordHandleSubmit,
        setPasswordForm,
        goBackClicked,
        setHasCodeClicked,
        setPasswordFormFields,
    } = props;

    const {
        register: requestCodeFormRegister,
        control: requestCodeFormControl,
        handleSubmit: requestCodeFormHandleSubmit,
        formState: {errors: requestCodeFormErrors}
    } = requestCodeForm;

    const {
        register: setPasswordFormRegister,
        control: setPasswordFormControl,
        handleSubmit: setPasswordFormHandleSubmit,
        formState: {errors: setPasswordFormErrors}
    } = setPasswordForm;

    return (
        <Page className={"auth-set-password-partial"}
              title={<PublicHeaderTitle/>}
              showMenuButton={false}
              showBackButton={false}
              showLoading={isLoading}
              showHeaderBackgroundColor={false}
        >
            <IonText color={"secondary"} >
                <h2>{processTitle}</h2>
                {processMessage && <p>{processMessage}</p>}
            </IonText>
            {hasCode === null &&
                <>
                    <div className={"ion-padding ion-margin-top"}>
                        <IonText className={"text-bold"} color={"primary"}>Do you have a code yet?</IonText>
                    </div>
                    <IonButton onClick={() => {
                        chooseHasCodeClicked(true)
                    }}
                    >Yes!</IonButton>
                    <IonButton onClick={() => {
                        chooseHasCodeClicked(false)
                    }}
                    >What code?</IonButton>
                </>
            }

            {hasCode !== null && !hasCode &&
                <form onSubmit={requestCodeFormHandleSubmit(requestCodeHandleSubmit)} className={'ion-padding'}>
                    <p>Enter your email address and we will send you a code.</p>
                    {Object.entries(requestCodeFormFields).map(([fieldName, field], index) => (
                        <Input {...field} control={requestCodeFormControl} register={requestCodeFormRegister} key={index} errors={requestCodeFormErrors} isDisabled={isLoading}/>
                    ))}
                    <FormSubmitButton outerClassName={'ion-text-center ion-margin-vertical'} disabled={isLoading}>
                        Send Reset Email</FormSubmitButton>
                </form>
            }

            {!!hasCode &&
                <form onSubmit={setPasswordFormHandleSubmit(setPasswordHandleSubmit)} className={'ion-padding'}>
                    <p>Enter your 6 digit code, and your new password.</p>
                    {Object.entries(setPasswordFormFields).map(([fieldName, field], index) => (
                        <Input {...field} control={setPasswordFormControl} register={setPasswordFormRegister} key={index} errors={setPasswordFormErrors} isDisabled={isLoading}/>
                    ))}
                    <FormSubmitButton outerClassName={'ion-text-center ion-margin-vertical'} disabled={isLoading}>
                        Set Password</FormSubmitButton>
                </form>
            }
            {
                (hasCode === null &&
                    <div className={"ion-padding-top"}>
                        <IonButton fill={'clear'} onClick={goBackClicked}>Back</IonButton>
                    </div>)
                ||
                (<div className={"ion-padding-top"}>
                    <IonButton fill={'clear'} onClick={setHasCodeClicked}>Back</IonButton>
                </div>)
            }
        </Page>
    );
};
