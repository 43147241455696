import {ApiAuth, AuthStatus, User} from '../../models';

export const type = 'auth';

export interface State {
    status: AuthStatus;
    authEmail?: string; // The email the user is trying to auth with - Used to reduce typing during forget/reset/login process
    isLoading: boolean;
    isLoggingOut: boolean;
    isUpdating: boolean;
    errorMessage?: string;
    errors?: { [fieldName: string]: string[] };
    formMessage?: string;
    dstOffset: number;
    utcOffset: number;
    user: User | null;
    apiAuth: ApiAuth | null;
    fcmToken: string | null;
}

export const initialState: State = {
    status: AuthStatus.NOT_LOGGED_IN,
    authEmail: undefined,
    errorMessage: undefined,
    errors: undefined,
    formMessage: undefined,
    isLoading: false,
    isLoggingOut: false,
    isUpdating: false,
    dstOffset: 0,
    utcOffset: 0,
    user: null,
    apiAuth: null,
    fcmToken: null,
};
