import React, {useCallback, useEffect} from "react";
import {useCustomerStore} from "../../redux/customers";
import {useSystemStore} from "../../redux/system";

let Network: any = null;
if (typeof window !== "undefined") {
    const { Network: CapacitorNetwork } = require("@capacitor/core");
    Network = CapacitorNetwork;
}

interface NetworkCheckerProps {}

const NetworkChecker: React.FC<NetworkCheckerProps> = () => {
    const customerStore = useCustomerStore();
    const { getAll } = customerStore;
    const systemStore = useSystemStore();
    const { setIsOnline, isOnline } = systemStore;

    const fetchFreshData = useCallback(() => {
        if(isOnline){
            getAll();
        }
    }, [getAll, isOnline]);

    const checkInitialNetworkStatus = async () => {
        if (Network) {
            const status = await Network.getStatus();
            setIsOnline({isOnline: status.connected});
        } else {
            setIsOnline({isOnline: window.navigator.onLine});
        }
    };

    useEffect(() => {
        let unsubscribe: any = null;
        if (Network) {
            unsubscribe = Network.addListener("networkStatusChange", handleNetworkChange);
        } else {
            window.addEventListener("online", handleOnline);
            window.addEventListener("offline", handleOffline);
        }

        return () => {
            if (Network) {
                unsubscribe();
            } else {
                window.removeEventListener("online", handleOnline);
                window.removeEventListener("offline", handleOffline);
            }
        };
    }, [fetchFreshData, Network]);

    const handleNetworkChange = (status: any) => {
        setIsOnline({isOnline: status.connected});
        if (status.connected) {
            fetchFreshData();
        }
    };

    const handleOnline = () => {
        setIsOnline({isOnline: true});
        fetchFreshData();
    };

    const handleOffline = () => {
        setIsOnline({isOnline: false});
    };

    useEffect(() => {
        checkInitialNetworkStatus();
    }, []);

    return null;
};

export default NetworkChecker;
