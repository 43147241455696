import * as React from 'react';
import {useCallback, useEffect, useMemo, useRef} from 'react';

import {AccountPage} from '../components';
import {useCustomerStore} from "../../../../redux/customers";
import {useHistory} from "react-router";
import {useFinancialTransactionStore} from "../../../../redux/financial-transactions";
import {useToastStore} from "../../../../redux/toast";

type Props = {};

export const AccountPageContainer: React.FC<Props> = props => {
    const {} = props;
    const history = useHistory();
    const toastStore = useToastStore();
    const refresherRef = useRef<HTMLIonRefresherElement>(null);

    const financialTransactionsStore = useFinancialTransactionStore();
    const customerStore = useCustomerStore();
    const {activeCustomer, selectedId: customerId} = customerStore;
    const {
        isFetching,
        financialTransactions,
        getAll,
        errorMessage: financialErrorMessage,
        clearErrors: financialClearErrors
    } = financialTransactionsStore;

    const customerTransactions = useMemo(() => {
        return financialTransactions.filter((transaction) => {
            return activeCustomer && transaction.customerId === activeCustomer.id;
        });
    }, [financialTransactions, activeCustomer]);

    const fetchCustomer = useCallback(() => {
        if (customerId && typeof customerId === "number") {
            getAll({customerId}).catch(() => { /** Handled with state and toast */
            }).finally(() => {
                if (refresherRef.current) {
                    refresherRef.current.complete();
                }
            });
        }
    }, [getAll, customerId])

    useEffect(() => {
        if (customerId && typeof customerId === "number") {
            fetchCustomer();
        }
    }, [customerId]);

    useEffect(() => {
        financialErrorMessage && toastStore.showError({message: financialErrorMessage}).then(() => {
            financialClearErrors();
        });
    }, [financialErrorMessage])

    const handleTransactionSelected = (documentId: number) => {
        history.push(`document/${documentId}`);
    }

    const handleRefresh = () => {
        fetchCustomer();
    }

    return <AccountPage
        isFetching={isFetching}
        activeCustomer={activeCustomer}
        transactions={customerTransactions}
        transactionSelected={handleTransactionSelected}
        doRefresh={handleRefresh}
        refresherRef={refresherRef}
    />;
};
