import {createEntityAdapter, EntityState} from '@reduxjs/toolkit';

import {FinancialTransaction} from '../../models';

export const type = 'financial-transactions';

export interface FinancialTransactionsState extends EntityState<FinancialTransaction, number> {
    isFetching: boolean;
    selectedId: number | null;
    errorMessage?: string;
}

export const adapter = createEntityAdapter<FinancialTransaction>({
});

export const initialState: FinancialTransactionsState = adapter.getInitialState({
    isFetching: false,
    selectedId: null,
    errorMessage: undefined,
});
