import {createAsyncThunk} from '@reduxjs/toolkit';

import {type} from './state';

const modalPageAction = (typePrefix: string) => {
    return createAsyncThunk(
        typePrefix,
        async (isOpen?: boolean) => { return { isOpen }}
    );
}

export const setIsOpenWaitingBookingAcceptAction = modalPageAction(`${type}/setIsOpenWaitingBookingAcceptAction`)
export const setIsOpenAboutAction = modalPageAction(`${type}/setIsOpenAboutAction`)
export const setIsOpenPrivacyAction = modalPageAction(`${type}/setIsOpenPrivacyAction`)
export const setIsOpenTermsOfUseAction = modalPageAction(`${type}/setIsOpenTermsOfUseAction`)

export const modalPageActions = [
    setIsOpenAboutAction,
    setIsOpenPrivacyAction,
    setIsOpenTermsOfUseAction,
    setIsOpenWaitingBookingAcceptAction,
]
