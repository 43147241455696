import {unwrapResult} from '@reduxjs/toolkit';
import {useCallback} from 'react';

import {
    errorMessageSelector,
    financialTransactionIdsByActiveCustomerSelector,
    financialTransactionsByActiveCustomerSelector,
    financialTransactionsSelector,
    isFetchingFinancialTransactionsSelector,
    selectedFinancialTransactionSelector,
    selectedIdSelector,
} from './selectors';

// Action (service) interfaces
import {
    clearAllAction,
    clearErrorsAction,
    getAllFinancialTransactions,
    setSelectedIdFinancialTransaction,
} from './actions';

import {useAppDispatch, useAppSelector} from "../hooks";
import {AppDispatch} from "../store";

export const useFinancialTransactionStore = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const financialTransactions = useAppSelector(financialTransactionsSelector);
    const selectedFinancialTransaction = useAppSelector(selectedFinancialTransactionSelector);
    const financialTransactionsByActiveCustomer = useAppSelector(financialTransactionsByActiveCustomerSelector);
    const financialTransactionIdsByActiveCustomer = useAppSelector(financialTransactionIdsByActiveCustomerSelector);
    const isFetching = useAppSelector(isFetchingFinancialTransactionsSelector);
    const selectedId = useAppSelector(selectedIdSelector);
    const errorMessage = useAppSelector(errorMessageSelector);

    const getAll = useCallback(
        (args: { customerId?: number }) => {
            return dispatch(getAllFinancialTransactions(args)).then(unwrapResult);
        },
        [dispatch]
    );

    const setSelectedId = useCallback(
        (id?: number) => {
            return dispatch(setSelectedIdFinancialTransaction(id)).then(unwrapResult);
        },
        [dispatch]
    );

    const clearAll = useCallback(
        () => {
            return dispatch(clearAllAction()).then(unwrapResult);
        },
        [dispatch]
    );

    const clearErrors = useCallback(
        () => {
            return dispatch(clearErrorsAction()).then(unwrapResult);
        },
        [dispatch]
    );


    return {
        isFetching,
        financialTransactions,
        financialTransactionsByActiveCustomer,
        financialTransactionIdsByActiveCustomer,
        selectedFinancialTransaction,
        getAll,
        setSelectedId,
        clearAll,
        clearErrors,
        errorMessage,
        selectedId,
    } as const;
};
