export const type = 'users';

export interface State {
    isLoading: boolean;
    errorMessage?: string;
    isOpenDeleteModal: boolean,
}

export const initialState: State = {
    isLoading: false,
    errorMessage: undefined,
    isOpenDeleteModal: false,
};
