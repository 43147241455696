import {createReducer} from '@reduxjs/toolkit';

import {adapter, initialState} from './state';
import {
    clearAllAction,
    clearErrorsAction,
    getAllFinancialTransactions,
    setSelectedIdFinancialTransaction,
} from './actions';
import {ApiResponse} from "../../models";

export const reducer = createReducer(initialState, builder =>
    builder
        /** HANDLE ACTION ====================================== */
        .addCase(getAllFinancialTransactions.pending, state => {
            return {...state, isFetching: true};
        })
        .addCase(getAllFinancialTransactions.fulfilled, (state, action) => {
            const { results, customerId } = action.payload;
            const { financialTransactions } = results;
            let newState = adapter.getInitialState({ ...state, isFetching: false });

            if (!!customerId) {
                // Get existing transactions for the specified customer
                const existingTransactions = adapter.getSelectors().selectAll(state).filter((transaction) => transaction.customerId === customerId);
                // Create a Set of new transaction IDs for faster lookup
                const newTransactionIdsSet = new Set(Object.values(financialTransactions).map((transaction) => transaction.id));
                // Remove transactions that are not in the new transactions array
                for (const transaction of existingTransactions) {
                    if (!newTransactionIdsSet.has(transaction.id)) {
                        newState = adapter.removeOne(newState, transaction.id);
                    }
                }
                // Upsert the new transactions
                newState = adapter.upsertMany(newState, Object.values(financialTransactions));
            } else {
                // Replace all transactions with the new transactions
                newState = adapter.setAll(newState, Object.values(financialTransactions));
            }

            return newState;
        })
        .addCase(getAllFinancialTransactions.rejected, (state, action) => {
            const {errorMessage} = action.payload as ApiResponse;
            return {...state, isFetching: false, errorMessage};
        })


        /** HANDLE NON-API ACTIONS ====================================== */
        .addCase(setSelectedIdFinancialTransaction.fulfilled, (state, action) => {
            const {selectedId} = action.payload;
            return {...state, selectedId: selectedId};
        })
        .addCase(clearAllAction.fulfilled, () => adapter.removeAll({...initialState}))
        .addCase(clearErrorsAction.fulfilled, state => ({...state, errorMessage: undefined}))
);
