import {unwrapResult} from '@reduxjs/toolkit';
import {useCallback} from 'react';

import {
    setButtonsAction,
    setColorAction,
    setHeaderAction,
    setIsOpenAction,
    setMessageAction,
    showAction,
} from './actions';

import {useAppDispatch, useAppSelector} from "../hooks";
import {AppDispatch} from "../store";
import {buttonsSelector, colorSelector, headerSelector, isOpenSelector, messageSelector} from "./selectors";
import {ToastButton, ToastOptions} from "@ionic/react";

export const useToastStore = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const isOpen = useAppSelector(isOpenSelector);
    const header = useAppSelector(headerSelector);
    const message = useAppSelector(messageSelector);
    const color = useAppSelector(colorSelector);
    const buttons = useAppSelector(buttonsSelector);

    const show = useCallback(
        (toastOptions: ToastOptions) => {
            return dispatch(showAction(toastOptions)).then(unwrapResult);
        },
        [dispatch]
    );

    const showSuccess = useCallback(
        (toastOptions: ToastOptions) => {
            toastOptions.color = 'success';
            return dispatch(showAction(toastOptions)).then(unwrapResult);
        },
        [dispatch]
    );

    const showError = useCallback(
        (toastOptions: ToastOptions) => {
            toastOptions.color = 'danger';
            return dispatch(showAction(toastOptions)).then(unwrapResult);
        },
        [dispatch]
    );

    const hide = useCallback(
        () => {
            return dispatch(setIsOpenAction(false)).then(unwrapResult);
        },
        [dispatch]
    );

    const setIsOpen = useCallback(
        (isOpen: boolean) => {
            return dispatch(setIsOpenAction(isOpen)).then(unwrapResult);
        },
        [dispatch]
    );

    const setMessage = useCallback(
        (newMessage?: string) => {
            return dispatch(setMessageAction(newMessage)).then(unwrapResult);
        },
        [dispatch]
    );

    const setHeader = useCallback(
        (newHeader?: string) => {
            return dispatch(setHeaderAction(newHeader)).then(unwrapResult);
        },
        [dispatch]
    );

    const setButtons = useCallback(
        (newButtons: (ToastButton | string)[]) => {
            return dispatch(setButtonsAction(newButtons)).then(unwrapResult);
        },
        [dispatch]
    );

    const setColor = useCallback(
        (newColor?: string) => {
            return dispatch(setColorAction(newColor)).then(unwrapResult);
        },
        [dispatch]
    );

    return {
        isOpen,
        header,
        message,
        color,
        buttons,
        show,
        showSuccess,
        showError,
        hide,
        setIsOpen,
        setMessage,
        setHeader,
        setButtons,
        setColor,
    } as const;
};
