import React from 'react';
import {IonLoading} from "@ionic/react";

interface PageLoadingProps {
    showLoading?: boolean;
    loadingMessage?: string;
}

const PageLoading: React.FC<PageLoadingProps> = ({
                                                     showLoading ,
                                                     loadingMessage
                                                 }) => {
    return (
        <>
            <IonLoading
                cssClass='swl-page-loading'
                isOpen={showLoading || false}
                message={loadingMessage}
            />
        </>
    );
};

export default PageLoading;
