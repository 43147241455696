import React from "react";
import {IonChip} from "@ionic/react";
import {Slot} from "../../models";


interface AvailabilityStatusChipsProps {
    canAction: boolean;
    slot: Slot;
    positionSlot?: string;
}

const SlotBookingStatusChips: React.FC<AvailabilityStatusChipsProps> = ({slot, canAction, positionSlot}) => {
    if (slot.bookingDisplayStatuses && slot.bookingDisplayStatuses.length > 0) {
        return (
            <>
                {slot.bookingDisplayStatuses.map((displayStatus, iDisplayStatus) => (
                    <IonChip key={`slot-${slot.id}-booking-status-${iDisplayStatus}`}
                             disabled={canAction}
                             {...positionSlot && { slot: positionSlot }}
                             className={"chip-label"}
                             color={displayStatus.color}
                    >{displayStatus.text}</IonChip>
                ))}
            </>
        );
    } else {
        return null;
    }
};

export default SlotBookingStatusChips;
