import * as React from 'react';

import {HomePageContainer} from './containers';

interface Props {}

export const HomePage: React.FC<Props> = () => {
    return (
        <HomePageContainer />
    );
};
