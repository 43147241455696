import {unwrapResult} from '@reduxjs/toolkit';
import {useCallback} from 'react';

import {
    clearAllAction,
    clearErrorsAction,
    deregisterFcmTokenAction,
    forgotAction,
    getUserAction,
    loginAction,
    logoutAction,
    registerFcmTokenAction,
    setPasswordAction
} from './actions';

import {
    apiAuthSelector,
    authEmailSelector,
    authErrorMessageSelector,
    authErrorsSelector,
    authFormMessageSelector,
    authStatusSelector,
    isAuthedSelector,
    isEmailVerifiedSelector,
    isLoadingSelector,
    isLoggingOutSelector,
    userNameSelector,
    userSelector
} from './selectors';

import {useAppDispatch, useAppSelector} from "../hooks";
import {AppDispatch} from "../store";
import {LoginApiRequest} from "../../services";

export const useAuthStore = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const isLoading = useAppSelector(isLoadingSelector);
    const isLoggingOut = useAppSelector(isLoggingOutSelector);
    const isAuthed = useAppSelector(isAuthedSelector);
    const apiAuth = useAppSelector(apiAuthSelector);
    const isRefreshingToken = useAppSelector(isAuthedSelector);
    const isEmailVerified = useAppSelector(isEmailVerifiedSelector);
    const user = useAppSelector(userSelector);
    const userName = useAppSelector(userNameSelector);
    const errorMessage = useAppSelector(authErrorMessageSelector);
    const errors = useAppSelector(authErrorsSelector);
    const formMessage = useAppSelector(authFormMessageSelector);
    const authStatus = useAppSelector(authStatusSelector);
    const authEmail = useAppSelector(authEmailSelector);

    const login = useCallback(
        (args: LoginApiRequest) => {
            return dispatch(loginAction(args)).then(unwrapResult);
        },
        [dispatch]
    );

    const logout = useCallback(
        (arg: { deviceUuid?: string }) => {
            return dispatch(logoutAction(arg)).then(unwrapResult);
        },
        [dispatch]
    );

    const forgot = useCallback(
        (arg: { forgotEmail: string }) => {
            return dispatch(forgotAction(arg)).then(unwrapResult);
        },
        [dispatch]
    );

    const setPassword = useCallback(
        (arg: { email: string, password: string, code: string }) => {
            return dispatch(setPasswordAction(arg)).then(unwrapResult);
        },
        [dispatch]
    );

    const me = useCallback(
        () => {
            return dispatch(getUserAction()).then(unwrapResult);
        },
        [dispatch]
    );

    const registerFcmToken = useCallback(
        (arg: { fcmToken: string, deviceUuid: string, platform: string }) => {
            return dispatch(registerFcmTokenAction(arg)).then(unwrapResult);
        },
        [dispatch]
    );

    const deregisterFcmToken = useCallback(
        (arg: {fcmToken: string}) => {
            return dispatch(deregisterFcmTokenAction(arg)).then(unwrapResult);
        },
        [dispatch]
    );

    const clearAll = useCallback(
        () => {
            return dispatch(clearAllAction()).then(unwrapResult);
        },
        [dispatch]
    );


    const clearErrors = useCallback(
        () => {
            return dispatch(clearErrorsAction()).then(unwrapResult);
        },
        [dispatch]
    );

    return {
        isLoading,
        isLoggingOut,
        isAuthed,
        apiAuth,
        isRefreshingToken,
        isEmailVerified,
        user,
        userName,
        authEmail,
        authStatus,
        errorMessage,
        errors,
        formMessage,
        me,
        forgot,
        setPassword,
        login,
        logout,
        getUser: getUserAction,
        registerFcmToken,
        deregisterFcmToken,
        clearAll,
        clearErrors,
    } as const;
};
