export interface User {
    username: string,
    name: string,
    uuid: string,
    isEmailVerified: boolean,
}

export interface ApiAuth {
    createdTimestamp: number,
    token: string,
    tokenType: string,
    error?: string,
}

export enum AuthStatus {
    NOT_LOGGED_IN,
    LOGGED_IN,
}
