import {createSelector} from '@reduxjs/toolkit';

import {adapter, ProfilePackagesState, type} from './state';

const {selectAll} = adapter.getSelectors();

const featureStateSelector = (state: { [type]: ProfilePackagesState }) =>
    state[type];

export const selectedIdSelector = createSelector(
    featureStateSelector,
    state => state.selectedId
);


export const selectedLearnerIdSelector = createSelector(
    featureStateSelector,
    state => state.selectedLearnerId
);

export const isFetchingProfilePackagesSelector = createSelector(
    featureStateSelector,
    state => state.isFetching
);

export const errorMessageProfilePackagesSelector = createSelector(
    featureStateSelector,
    state => state.errorMessage
);


export const profilePackagesSelector = createSelector(
    featureStateSelector,
    selectAll
);


export const profilePackageByIdSelector = createSelector(
    [
        profilePackagesSelector,
        // Take the second arg, `profilePackageId`, and forward to the output selector
        (state, profilePackageId: number | undefined): number => profilePackageId || -1
    ],
    // Output selector
    (profilePackages, profilePackageId) => profilePackages ? profilePackages.find(profilePackage => profilePackage.id === profilePackageId) : profilePackages
)


export const profilePackagesByLearnerIdSelector = createSelector(
    [
        // Usual first input - extract value from `state`
        profilePackagesSelector,
        // Take the second arg, `profilePackageId`, and forward to the output selector
        (state, learnerId): string => learnerId
    ],
    // Output selector gets (`items, category)` as args
    (profilePackages, learnerId) => profilePackages ? profilePackages.filter(profilePackage => `${profilePackage.learnerId}` === `${learnerId}`) : profilePackages
)
