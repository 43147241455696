import * as React from 'react';

import {SlotsPageContainer} from './containers';
import {useParams} from "react-router";

interface RouterParams {
    id: string;
}

export const SlotsPage: React.FC<RouterParams> = (props) => {
    const {id} = useParams<RouterParams>();

    return (
        <SlotsPageContainer profilePackageId={parseInt(id)} />
    );
};
