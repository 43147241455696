import {createSelector} from '@reduxjs/toolkit';

import {adapter, HolidaysState, type} from './state';
import {selectedSiteIdSelector as customerSelectedSiteIdSelector} from "../customers/selectors";

const {selectAll, selectEntities} = adapter.getSelectors();

const featureStateSelector = (state: { [type]: HolidaysState }) => state[type];

const entitiesSelector = createSelector(
    featureStateSelector,
    selectEntities
);

export const selectedIdSelector = createSelector(
    featureStateSelector,
    state => state.selectedId
);

export const isFetchingSelector = createSelector(
    featureStateSelector,
    state => state.isFetching
);

export const holidaysSelector = createSelector(
    featureStateSelector,
    selectAll
);

export const selectedHolidaySelector = createSelector(
    holidaysSelector,
    selectedIdSelector,
    (holidays, selectedId) => {
        return holidays.find(holiday => holiday.id === selectedId);
    }
);

export const holidaysByActiveCustomerSelector = createSelector(
    [
        holidaysSelector,
        customerSelectedSiteIdSelector,
    ],
    // Output selector
    (holidays, siteId) => holidays
        ? holidays.filter(holiday => holiday.siteId === siteId) : holidays
);

export const holidayIdsByActiveCustomerSelector = createSelector(
    holidaysByActiveCustomerSelector,
    (holidays) => {
        // Find the financial holiday with the selectedId
        return holidays.map(holiday => holiday.id);
    }
);
