import {createAsyncThunk} from '@reduxjs/toolkit';

import {type} from './state';
import {PostWaitingBookingCreateArgs, PostWaitingBookingDeleteArgs, waitingBookingsService,} from "../../services";
import {ApiResponse} from "../../models";
import {createClearAllAsyncThunk, createClearErrorAsyncThunk} from "../shared-actions";

export const getAllAction = createAsyncThunk<ApiResponse, { customerId?: number, learnerId?: number }>(
    `${type}/getAllAction`,
    async (args, {rejectWithValue}) => {
        try {
            const {customerId, learnerId} = args;
            return await waitingBookingsService.getAll(customerId, learnerId);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getByIdAction = createAsyncThunk<ApiResponse, { waitingBookingId: number }>(
    `${type}/getByIdAction`,
    async (args, {rejectWithValue}) => {
        try {
            const {waitingBookingId} = args;
            return await waitingBookingsService.getById(waitingBookingId);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


export const setSelectedIdAction = createAsyncThunk(
    `${type}/setSelectedIdAction`,
    async (arg: { waitingBookingId?: number }) => {
        const {waitingBookingId} = arg;
        const result = await waitingBookingsService.setSelectedId(waitingBookingId);
        return {
            selectedId: result.selectedId,
        };
    }
);

export const createAction = createAsyncThunk(
    `${type}/createAction`,
    async (args: PostWaitingBookingCreateArgs, {rejectWithValue}) => {
        try {
            return await waitingBookingsService.postCreate(args);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteAction = createAsyncThunk(
    `${type}/deleteAction`,
    async (args: PostWaitingBookingDeleteArgs, {rejectWithValue}) => {
        try {
            const result = await waitingBookingsService.postDelete(args);
            return {waitingBookingId: args.waitingBookingId, message: result?.message};
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const clearErrorsAction = createClearErrorAsyncThunk(type);
export const clearAllAction = createClearAllAsyncThunk(type);

