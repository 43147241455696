import React from "react";
import {Slot} from "../../models";
import {Ages} from "../../utils/ages";


interface AvailabilityStatusChipsProps {
    slot: Slot;
    className?: string;
    waitingOrderNumber?: number;
}

const SlotAvailableLabel: React.FC<AvailabilityStatusChipsProps> = ({slot, className, waitingOrderNumber}) => {
    if (!slot.isBooked
        && slot.lessonDateTs > (new Ages()).subDays().toTs()
        && slot.bookingLimit > 0
        && slot.lessonDateTs
        && (slot.canBook || slot.canWait || slot.canCancelWait)
    ) {
        const waitingOfText = (slot.canCancelWait && !isNaN(parseInt(`${slot.profilePackageWaitingBookingOrder}`)) && `#${slot.profilePackageWaitingBookingOrder + 1} of `) || "";
        return (
            <span className={className}>
                {slot.canBook && slot.availableCount && `Available: ${(slot.availableCount > 0) ? slot.availableCount : 0}`}
                {(slot.canWait || slot.canCancelWait) && `Waiting: ${waitingOfText}${(slot.waitingBookingsCount > 0) ? slot.waitingBookingsCount : 0}`}
            </span>
        );
    } else {
        return null;
    }
};

export default SlotAvailableLabel;
