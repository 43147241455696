import React, {ReactElement} from 'react';
import {IonText} from "@ionic/react";
import './SubtitleText.css'

export interface SubtitleTextProps{
    outerClassName?: string;
    className?: string;
    color?: string;
    mustWrap?: boolean;
    children?: any;
}

const SubtitleText: React.FC<SubtitleTextProps> = (props) => {
    const {className, outerClassName, mustWrap = true, color='primary', children} = props;
    const inner: ReactElement = (<IonText
        className={(className || 'ion-margin') + ' subtitle-text'}
        color={color}
    >{children}</IonText>);

    return (
        (!mustWrap && inner) ||
        (<div className={outerClassName}>
            {inner}
        </div>)
    );
};

export default SubtitleText;
