import {ApiResponse, BalanceSummary, KeyedFinancialTransactions} from "../models";
import apiClient from "./api/apiClient";
import {handleApiError} from "./api/apiErrorHandler";

export interface getAllFinancialTransactionsResponse extends ApiResponse {
    results: { financialTransactions: KeyedFinancialTransactions, balances: BalanceSummary[] }
    customerId?: number,
}

class FinancialTransactionsService {
    async getAllFinancialTransactions(customerId?: number): Promise<getAllFinancialTransactionsResponse> {

        const url = `${this.baseUrl}` + (!!customerId ? `?customer_id=${customerId}` : '' )
        return await apiClient
            .get(url)
            .then(
                (response) => ({...response.data, customerId: customerId}),
                (error) => handleApiError(error, "Failed to retrieve profile package data.")
            )
    }

    async setSelectedId(id?: number): Promise<any> {
        const fakePromise = () => {
            return new Promise(resolve => {
                resolve({id: id});
            })
        }
        await fakePromise();
        return await fakePromise();
    }

    constructor(private readonly baseUrl: string) { }
}

export const financialTransactionsService = new FinancialTransactionsService(
    '/financial-transactions'
);
