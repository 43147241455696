import React from 'react';
import {IonSkeletonText} from "@ionic/react";

interface SkeletonTextProps {
    className?: string;
    animated?: boolean;
    style: any;
}

const SkeletonText: React.FC<SkeletonTextProps> = ({ animated = true, ...props }) => {
    return <IonSkeletonText {...props}
    />;
};

export default SkeletonText;
