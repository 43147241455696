import * as React from 'react';
import {RefObject} from 'react';

import Page from "../../../components/core/Page";
import './communications-page.component.css';
import {IonCheckbox, IonChip, IonIcon, IonItem, IonLabel, IonList} from "@ionic/react";
import {Communication, CommunicationType} from "../../../models";
import {Ages} from "../../../utils/ages";
import CommunicationModal from "../../../components/communications/CommunicationModal";
import {atOutline, hourglassOutline, mailOutline, notificationsOutline} from "ionicons/icons";
import SkeletonText from "../../../components/common/SkeleltonText";

type Props = {
    isFetching: boolean,
    selectedCommunication?: Communication,
    communications: Communication[],
    communicationTypeCounts: { [p: number]: number },
    isOpenCommunication: boolean,
    setIsOpenCommunication: (isOpen: boolean) => void,
    filteredCommunications: Communication[],
    doRefresh: (event: CustomEvent) => void,
    refresherRef: RefObject<HTMLIonRefresherElement> | ((ref: HTMLIonRefresherElement | null) => void),
    handleTypeFilterChange: (type: CommunicationType, checked: boolean) => void,
    onCommunicationClicked: (id: number) => void,
    typeFilter: {
        [CommunicationType.EMAIL]: boolean,
        [CommunicationType.SMS]: boolean,
        [CommunicationType.PUSH]: boolean,
    },
};

export const CommunicationsPage: React.FC<Props> = props => {
    const {
        isFetching,
        selectedCommunication,
        communications,
        communicationTypeCounts,
        onCommunicationClicked,
        isOpenCommunication,
        setIsOpenCommunication,
        filteredCommunications,
        doRefresh,
        refresherRef,
        typeFilter,
        handleTypeFilterChange,
    } = props;

    const communicationTypeValues = Object.values(CommunicationType).filter(
        (value) => typeof value === 'number'
    ) as CommunicationType[];

    const communicationTypeIcons = {
        [CommunicationType.EMAIL]: {
            icon: atOutline,
            color: "secondary",
        },
        [CommunicationType.SMS]: {
            icon: mailOutline,
            color: "success",
        },
        [CommunicationType.PUSH]: {
            icon: notificationsOutline,
            color: "tertiary",
        },
    };

    return (
        <Page className={"communications-page"}
              title={'Communications'}
              showMenuButton={false}
              showBackButton={true}
              backRouterLink={"/home"}
              doRefresh={doRefresh}
              refresherRef={refresherRef}
        >
            {communications &&
                <div className="communication-type-filters-container">
                    {communicationTypeValues.map((type) => {
                        if (!communicationTypeCounts.hasOwnProperty(type) || communicationTypeCounts[type] === 0) {
                            return null;
                        }

                        const key = CommunicationType[type];
                        return (
                            <IonItem className="communication-type-filter" key={`communication-type-filter-${key}`}
                                     lines="none"
                            >
                                <IonCheckbox
                                    checked={typeFilter[type]}
                                    onIonChange={(e) => handleTypeFilterChange(type, e.detail.checked)}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <IonIcon className="ion-no-margin communication-type-filter-icon" {...communicationTypeIcons[type]}/>
                                        &nbsp;
                                        <small>{key}</small>
                                        &nbsp;
                                    </div>
                                </IonCheckbox>
                            </IonItem>
                        );
                    })}
                </div>}

            <IonList className="communications-list">

                {isFetching &&
                    <IonItem>
                        <IonLabel>
                            <>
                                <div className="ion-text-right communication-type-date-container">

                                    <p className="communication-sent">
                                        <SkeletonText style={{width: "70px",height: "14px", display: "inline-block"}}/>
                                    </p>
                                </div>
                                <IonChip color="medium">
                                    <IonIcon className="ion-no-margin" icon={hourglassOutline}/>
                                </IonChip>
                                <SkeletonText style={{lineHeight:"18px", width: "200px",height: "18px", display: "inline-block"}}/>
                                <br/>
                                <SkeletonText style={{width: "100%",height: "12px", display: "inline-block"}}/>
                                <br/>
                                <SkeletonText style={{width: "100%",height: "12px", display: "inline-block"}}/>
                            </>
                        </IonLabel>
                    </IonItem>
                }
                {filteredCommunications.map((comm, index) => (
                    [
                        <IonItem button key={`communication-${comm.id}`} onClick={() => {
                            onCommunicationClicked(comm.id)
                        }}
                        >
                            <IonLabel>
                                <div className="ion-text-right communication-type-date-container">

                                    <p className="communication-sent">
                                        {new Ages(comm.sentAtTs).toFormat("dd mmm 'yy")}
                                    </p>
                                </div>
                                <h2>
                                    {comm.type === CommunicationType.EMAIL && <IonChip color="secondary">
                                        <IonIcon className="ion-no-margin" icon={atOutline}/>
                                    </IonChip>}
                                    {comm.type === CommunicationType.SMS && <IonChip color="success">
                                        <IonIcon className="ion-no-margin" icon={mailOutline}/>
                                    </IonChip>}
                                    {comm.type === CommunicationType.PUSH && <IonChip color="tertiary">
                                        <IonIcon className="ion-no-margin" icon={notificationsOutline}/>
                                    </IonChip>}
                                    {comm.subject}
                                </h2>
                                <p>{comm.message}</p>
                            </IonLabel>
                        </IonItem>
                    ]
                ))}

                {
                    (!isFetching && (!filteredCommunications || filteredCommunications.length === 0)) && (
                        <IonItem lines={"full"}>
                            <IonLabel>
                                <div className="ion-text-center">
                                    <h2>
                                        No communications
                                    </h2>
                                </div>
                            </IonLabel>
                        </IonItem>
                    )

                }
            </IonList>
            <CommunicationModal communication={selectedCommunication} isOpen={isOpenCommunication} onClose={() => {
                setIsOpenCommunication(false);
            }}
            />
        </Page>
    );
};
