import {createAsyncThunk} from "@reduxjs/toolkit";


export const createClearErrorAsyncThunk = (type: string) => createAsyncThunk(
    `${type}/clearErrors`,
    async () => {
        const fakePromise = () => {
            return new Promise(resolve => {
                resolve({});
            })
        }
        await fakePromise();
        return {};
    }
);

export const createClearAllAsyncThunk = (type: string) => createAsyncThunk(
    `${type}/clearAll`,
    async () => {
        const fakePromise = () => {
            return new Promise(resolve => {
                resolve({});
            })
        }
        await fakePromise();
        return {};
    }
);
