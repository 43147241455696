import React, {useEffect, useState} from "react";
import './UserDeleteModal.css';
import ActionModal from "../../core/ActionModal";
import {IonButton, IonCheckbox, IonInput, IonItem} from "@ionic/react";
import {useUserStore} from "../../../redux/users";
import {useToastStore} from "../../../redux/toast";


interface UserDeleteModalProps {
    isOpen: boolean;
    onClose: () => void;
    setMustLogout: (mustLogout: boolean) => void;
}

const UserDeleteModal: React.FC<UserDeleteModalProps> = (
    {
        isOpen,
        onClose,
        setMustLogout,
    }) => {
    const [confirmed, setConfirmed] = useState<boolean>(false);
    const [password, setPassword] = useState('');
    const toastStore = useToastStore();
    const userStore = useUserStore();
    const { userDelete, clearErrors, errorMessage, setIsOpenDeleteModal } = userStore;

    useEffect(() => {
        clearErrors();
        setPassword('');
    }, []);

    useEffect(() => {
        errorMessage && toastStore.showError({message: errorMessage}).then(() => {
            clearErrors();
        });
    }, [errorMessage])

    const handleDeleteUser =(password: string)  => {
        userDelete({password}).then(() => {
            setIsOpenDeleteModal(false).finally(() => {
                setMustLogout(true);
            });
        }).catch(() => {/** Handled by toast */})
    }

    return (
        <ActionModal isOpen={isOpen} onClose={onClose} title={"Delete User"} buttonCloseText="Cancel">
            <IonItem className="ion-text-center" lines="none">
                <p>
                    After deleting the account, you will no longer be able to access this app, manage bookings or view any account details.
                    <br/>
                    <br/>
                    <strong> Enter your password to continue</strong>
                </p>
            </IonItem>
            <IonItem>
                <IonInput type="password" label="Password" labelPlacement="fixed" value={password} onIonChange={e => setPassword(e.detail.value!)} />
            </IonItem>
            <IonItem>
                <IonCheckbox color="danger"
                             name="confirmUserDelete"
                             aria-label="Permanently delete your user account?"
                             labelPlacement="start"
                             justify="start"
                             onIonChange={() => {
                                 setConfirmed(!confirmed);
                             }}>
                    Permanently delete your user account?
                </IonCheckbox>
            </IonItem>
            <IonItem lines="none"></IonItem>

            <IonButton color="danger" expand="full" disabled={!confirmed} onClick={() => {
                handleDeleteUser(password);
            }}>Delete</IonButton>
        </ActionModal>
    );
};

export default UserDeleteModal;
