import {createReducer} from '@reduxjs/toolkit';

import {adapter, initialState} from './state';
import {clearAllAction, clearErrorsAction, getAllAction, setSelectedIdAction,} from './actions';

export const reducer = createReducer(initialState, builder =>
    builder
        /** HANDLE ACTION ====================================== */
        .addCase(getAllAction.pending, state => {
            return {...state, isFetching: true};
        })
        .addCase(getAllAction.fulfilled, (state, action) => {
            const { results, customerId } = action.payload;
            const { communications } = results;
            let newState = adapter.getInitialState({ ...state, isFetching: false });

            if (!!customerId) {
                let newState = adapter.getInitialState({ ...state, isFetching: false });
                if (!!customerId) {
                    // Get existing communications for the specified customer
                    const existingCommunications = adapter.getSelectors().selectAll(state).filter((communication) => communication.customerId === customerId);
                    // Create a Set of new communication IDs for faster lookup
                    const newCommunicationIdsSet = new Set(Object.values(communications).map((communication) => communication.id));
                    // Remove communications that are not in the new communications array
                    for (const communication of existingCommunications) {
                        if (!newCommunicationIdsSet.has(communication.id)) {
                            newState = adapter.removeOne(newState, communication.id);
                        }
                    }
                    // Upsert the new communications
                    newState = adapter.upsertMany(newState, Object.values(communications));
                } else {
                    // Replace all communications with the new communications
                    newState = adapter.setAll(newState, Object.values(communications));
                }
                return newState;
            } else {
                // Replace all communications with the new communications
                newState = adapter.setAll(newState, Object.values(communications));
            }

            return newState;
        })
        .addCase(getAllAction.rejected, state => {
            return {...state, isFetching: false};
        })


        /** HANDLE NON-API ACTIONS ====================================== */
        .addCase(setSelectedIdAction.fulfilled, (state, action) => {
            const {selectedId} = action.payload;
            return {...state, selectedId: selectedId};
        })
        .addCase(clearAllAction.fulfilled, () => adapter.removeAll({...initialState}))
        .addCase(clearErrorsAction.fulfilled, state => ({...state, errorMessage: undefined}))
);
