import {createEntityAdapter, EntityState} from '@reduxjs/toolkit';

import {Communication} from "../../models";

export const type = 'communications';

export interface CommunicationsState extends EntityState<Communication, number> {
    isFetching: boolean;
    selectedId: number | null;
    errorMessage?: string;
}

export const adapter = createEntityAdapter<Communication>({
});

export const initialState: CommunicationsState = adapter.getInitialState({
    isFetching: false,
    selectedId: null,
    errorMessage: undefined,
});
