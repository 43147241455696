import {createReducer} from '@reduxjs/toolkit';

import {adapter, initialState} from './state';
import {clearAllAction, clearErrorsAction,} from './actions';
import {getAllCustomers, getCustomerById,} from '../customers';

export const reducer = createReducer(initialState, builder =>
    builder
        /** HANDLE ACTION ====================================== */
        .addCase(getAllCustomers.pending, state => {
            return {...state, isFetching: true};
        })
        .addCase(getAllCustomers.fulfilled, (state, action) => {
            const {holidays} = action.payload.results;
            return adapter.setAll({...state, isFetching: false}, holidays);
        })
        .addCase(getAllCustomers.rejected, state => {
            return {...state, isFetching: false};
        })

        .addCase(getCustomerById.pending, state => {
            return {...state, isFetching: true};
        })
        .addCase(getCustomerById.fulfilled, (state, action) => {
            const {holidays, customer} = action.payload.results;
            let newState = adapter.getInitialState({ ...state, isFetching: false });
            // Get existing transactions for the specified customer
            const existingHolidays = adapter.getSelectors().selectAll(state).filter((holiday) => holiday.siteId === customer.company.siteId);
            // Create a Set of new transaction IDs for faster lookup
            const newIdsSet = new Set(Object.values(holidays).map((holiday) => holiday.id));
            // Remove transactions that are not in the new transactions array
            for (const holiday of existingHolidays) {
                if (!newIdsSet.has(holiday.id)) {
                    newState = adapter.removeOne(newState, holiday.id);
                }
            }
            // Upsert the new transactions
            newState = adapter.upsertMany(newState, Object.values(holidays));
            return newState;
        })
        .addCase(getCustomerById.rejected, state => {
            return {...state, isFetching: false};
        })


        /** HANDLE NON-API ACTIONS ====================================== */
        .addCase(clearAllAction.fulfilled, () => adapter.removeAll({...initialState}))
        .addCase(clearErrorsAction.fulfilled, state => ({...state, errorMessage: undefined}))
);
