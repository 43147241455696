import {createReducer} from '@reduxjs/toolkit';

import {initialState} from './state';
import {
    clearAllAction,
    clearErrorsAction,
    deregisterFcmTokenAction,
    forgotAction,
    getUserAction,
    loginAction,
    logoutAction,
    registerFcmTokenAction,
    setPasswordAction,
} from './actions';

import {deleteAction as deleteUserAction} from "../users";

import {ApiResponse, AuthStatus} from "../../models";

export const reducer = createReducer(initialState, builder =>
    builder

        /** HANDLE ACTION ====================================== */
        .addCase(getUserAction.pending, (state, action) => {
            return {...state, errorMessage: undefined, isLoading: true};
        })
        .addCase(getUserAction.fulfilled, (state, action) => {
            const {user} = action.payload.results;
            return {
                ...state,
                errorMessage: undefined,
                user: user,
                isLoading: false,
            };
        })
        .addCase(getUserAction.rejected, (state, action) => {
            const {errorMessage} = action.payload as ApiResponse;
            return {...state, errorMessage, status: AuthStatus.NOT_LOGGED_IN, isLoading: false};
        })

        /** HANDLE ACTION ====================================== */
        .addCase(loginAction.pending, (state, action) => {
            return {...state, errorMessage: undefined, errors: undefined, isLoading: true};
        })
        .addCase(loginAction.fulfilled, (state, action) => {
            const {user, apiAuth} = action.payload.results;
            return {
                ...state,
                errorMessage: undefined,
                formMessage: undefined,
                user: user,
                apiAuth: apiAuth,
                status: AuthStatus.LOGGED_IN,
                isLoading: false
            };
        })
        .addCase(loginAction.rejected, (state, action) => {
            const {errorMessage, errors} = action.payload as ApiResponse;
            return {...state, errorMessage, errors, status: AuthStatus.NOT_LOGGED_IN, isLoading: false};
        })

        /** HANDLE ACTION ====================================== */
        .addCase(forgotAction.pending, (state, action) => {
            const authEmail: string = action?.meta?.arg?.forgotEmail;
            return {...state, authEmail: authEmail, errorMessage: undefined, errors: undefined, formMessage: undefined, isLoading: true};
        })
        .addCase(forgotAction.fulfilled, (state, action) => {
            const {results} = action.payload;
            const authEmail = action?.meta?.arg?.forgotEmail;
            return {
                ...state,
                authEmail: authEmail,
                errorMessage: undefined,
                formMessage: results?.message,
                isLoading: false
            };
        })
        .addCase(forgotAction.rejected, (state, action) => {
            const {errorMessage, errors} = action.payload as ApiResponse;
            return {...state, errorMessage, errors, isLoading: false};
        })

        /** HANDLE ACTION ====================================== */
        .addCase(setPasswordAction.pending, (state, action) => {
            return {...state, isLoading: true, errorMessage: undefined, errors: undefined};
        })
        .addCase(setPasswordAction.fulfilled, (state, action) => {
            return {...state, isLoading: false};
        })
        .addCase(setPasswordAction.rejected, (state, action) => {
            const {errorMessage, errors} = action.payload as ApiResponse;
            return {...state, isLoading: false, errorMessage, errors};
        })

        /** HANDLE ACTION ====================================== */
        .addCase(logoutAction.pending, (state, action) => {
            return {...state, isLoading: true, isLoggingOut:true, errorMessage: undefined};
        })
        .addCase(logoutAction.fulfilled, (state, action) => {
            return {...state, user: null, apiAuth: null, isLoggingOut: false, isLoading: false};
        })
        .addCase(logoutAction.rejected, (state, action) => {
            const {errorMessage} = action.payload as ApiResponse;
            return {...state, apiAuth: null, isLoading: false, isLoggingOut: false, errorMessage};
        })

        /** HANDLE USER DELETE ACTION ====================================== */
        .addCase(deleteUserAction.fulfilled, (state, action) => {
            return {...state, user: null, apiAuth: null, isAuthed: false, isLoggingOut: false, isLoading: false};
        })

        /** HANDLE ACTION ====================================== */
        .addCase(registerFcmTokenAction.pending, (state, action) => {
            return {...state, errorMessage: undefined};
        })
        .addCase(registerFcmTokenAction.fulfilled, (state, action) => {
            const {results} = action.payload;
            return {...state, fcmToken: results.fcmToken};
        })
        .addCase(registerFcmTokenAction.rejected, (state, action) => {
            const {errorMessage} = action.payload as ApiResponse;
            return {...state, errorMessage};
        })

        /** HANDLE ACTION ====================================== */
        .addCase(deregisterFcmTokenAction.pending, (state, action) => {
            return {...state, errorMessage: undefined};
        })
        .addCase(deregisterFcmTokenAction.fulfilled, (state, action) => {
            return {...state, fcmToken: null};
        })
        .addCase(deregisterFcmTokenAction.rejected, (state, action) => {
            const {errorMessage} = action.payload as ApiResponse;
            return {...state, errorMessage};
        })

        /** HANDLE NON-API ACTIONS ====================================== */
        .addCase(clearAllAction.fulfilled, () => ({...initialState}))
        .addCase(clearErrorsAction.fulfilled, state => ({...state, errorMessage: undefined, errors: undefined}))
);
