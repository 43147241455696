import React, {useCallback, useEffect, useMemo} from "react";
import {useAuthStore} from "../../redux/auth";
import {useHistory} from "react-router";
import {apiCacheDuration, apiDomain, apiPrefix} from "../../services.config";
import {camelizeKeys} from "humps";
import apiClient from "../../services/api/apiClient";
import {getCache, hasValidCache, setCache} from "../../services/api/responseCache";

// TODO: Cache Duration only uses default
const DEFAULT_CACHE_DURATION_MS = apiCacheDuration ; // 30 seconds
interface CacheConfig { url: RegExp; duration?: number; }
const cacheConfig: CacheConfig[] = [
    { url: /customers$/,},
    { url: /customers\/\d+/,},
    { url: /bookings$/,},
    { url: /bookings\?customer_id=\d+/,},
    { url: /profile-packages$/,},
    { url: /profile-packages\?customer_id=\d+/,},
    { url: /financial-transactions\?customer_id=\d+/,},
    { url: /communications\?customer_id=\d+/,},
];

// Helper function to check if a URL is cached
const shouldIncludeInCache = (url: string): boolean => {
    return cacheConfig.map( cnf => cnf.url ).some((pattern) => pattern.test(url));
};

interface ApiConfAttributes {
    setMustLogout: (newMustLogout: boolean) => void;
}

const ApiConfigurator: React.FC<ApiConfAttributes> = (props) => {
    const {setMustLogout} = props;
    const authStore = useAuthStore();
    const {isAuthed, apiAuth} = authStore;
    const history = useHistory();

    const logoutUrlPath = `${apiPrefix}/auth/logout`;
    const loginUrlPath = `${apiPrefix}/auth/login`;
    const ignored401 = useMemo(() => ([logoutUrlPath, loginUrlPath]), [logoutUrlPath, loginUrlPath]);

    const contentType = "application/json";

    // Setup Auth API Headers
    useEffect(() => {
        if (isAuthed && apiAuth && apiAuth.tokenType && apiAuth.token) {
            apiClient.defaults.headers.common["Authorization"] = `${apiAuth.tokenType} ${apiAuth.token}`;
        }
    }, [apiAuth, isAuthed]);

    const setApiClientDefaults = useCallback(() => {
        apiClient.interceptors.response.use((response) => {
            if (response.data && response.headers["content-type"] === contentType) {
                response.data = camelizeKeys(response.data);
            }
            return response;
        });

        apiClient.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                if (error?.response?.status === 401) {
                    const errUrl = new URL(error.request.responseURL);
                    if (apiDomain === errUrl.origin && !ignored401.includes(errUrl.pathname)) {
                        setMustLogout(true);
                    }
                    if (apiDomain === errUrl.origin && errUrl.pathname === logoutUrlPath) {
                        if (history.location.pathname !== loginUrlPath) {
                            history.replace("/auth/login");
                        }
                    }
                }

                return Promise.reject(error);
            }
        );

        apiClient.interceptors.request.use((config) => {
            if (config.method === "get"
                && shouldIncludeInCache(config.url as string)
                && hasValidCache(config.url as string, DEFAULT_CACHE_DURATION_MS)) {
                config.adapter = async () => {
                    return new Promise((resolve) => {
                        resolve({
                            data: getCache(config.url as string),
                            status: 200,
                            statusText: "OK (cached)",
                            headers: {},
                            config,
                        });
                    });
                };
            }
            return config;
        });

        apiClient.interceptors.response.use((response) => {
            if (response.config.method === "get") {
                setCache(response.config.url as string, response.data);
            }
            return response;
        });
    }, [history, loginUrlPath, logoutUrlPath, ignored401, setMustLogout])

    // Get the Device Timezone
    // Setup Generic API Headers and middleware
    useEffect(() => {
        setApiClientDefaults();
    }, [setApiClientDefaults]);

    return null;
};

export default ApiConfigurator;
