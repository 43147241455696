import {ApiResponse, ProfilePackage} from "../models";
import apiClient from "./api/apiClient";
import {handleApiError} from "./api/apiErrorHandler";

interface getAllResponse extends ApiResponse {
    results: { profilePackages: ProfilePackage[] }
}

class ProfilePackagesService {

    async getAll(customerId?: number, learnerId?: number): Promise<getAllResponse> {
        let url = this.baseUrl;
        if (customerId || learnerId) {
            url += '?';
        }
        if (customerId) {
            url += `customer_id=${customerId}`;
            if (learnerId) {
                url += '&';
            }
        }
        if (learnerId) {
            url += `learner_id=${learnerId}`;
        }
        return await apiClient
            .get(url)
            .then(
                (res) => res.data,
                (error) => handleApiError(error, "Failed to retrieve data.")
            )
    }

    async setSelectedId(id: number): Promise<any> {
        const fakePromise = () => {
            return new Promise(resolve => {
                resolve({id: id});
            })
        }
        await fakePromise();
        return await fakePromise();
    }

    async setSelectedLearnerId(learnerId: number): Promise<any> {
        const fakePromise = () => {
            return new Promise(resolve => {
                resolve({learnerId: learnerId});
            })
        }
        await fakePromise();
        return await fakePromise();
    }


    constructor(private readonly baseUrl: string) {
    }
}

export const profilePackagesService = new ProfilePackagesService(
    '/profile-packages'
);
