import React, {FC} from "react";
import {BankAccount} from "../../models";
import './banking-details.css';

export interface InputProps {
    payableBankAccount?: BankAccount | null;
    accountCode: string;
    className?: string;
}

const BankingDetails: FC<InputProps> = (props) => {

    const {payableBankAccount, accountCode, className} = props;

    return (
        <div className={className + ' banking-details'}>
            <div><span>Bank</span> <span>{payableBankAccount?.name}</span></div>
            <div><span>Branch</span> <span>{payableBankAccount?.branch}</span></div>
            <div><span>Account</span> <span>{payableBankAccount?.accountNo}</span></div>
            <div><span>Acc Type</span> <span>{payableBankAccount?.type}</span></div>
            <div><span>Reference</span> <span>{accountCode}</span></div>
        </div>
    )
};

export default BankingDetails;
