import {ApiResponse, Booking} from "../models";
import apiClient from "./api/apiClient";
import {handleApiError} from "./api/apiErrorHandler";

interface getAllBookingsResponse extends ApiResponse {
    results: { bookings: Booking[] },
}

interface PostBookingCreateResponse extends ApiResponse {
    results: { booking: Booking },
}

export interface PostBookingCreateArgs {
    profilePackageId: number;
    slotId: number;
    startedTimestamp: number;
}

export interface PostBookingDeleteArgs {
    bookingId: number;
}

export interface PostBookingCancelArgs {
    bookingId: number;
    lessonDateTs: number;
}


class BookingsService {
    async getAllBookings(customerId?: number, learnerId?: number): Promise<getAllBookingsResponse> {
        let url = this.baseUrl;
        if (customerId || learnerId) {
            url += '?';
        }
        if (customerId) {
            url += `customer_id=${customerId}`;
            if (learnerId) {
                url += '&';
            }
        }
        if (learnerId) {
            url += `learner_id=${learnerId}`;
        }
        return await apiClient
            .get(url)
            .then(
                (response) => ({...response.data, customerId, learnerId}),
                (error) => handleApiError(error, "Failed to retrieve bookings data.")
            )
    }

    async postBookingCreate(args: PostBookingCreateArgs): Promise<PostBookingCreateResponse> {
        return await apiClient
            .post(this.baseUrl + `/add`, {
                slot_id: args.slotId,
                profile_package_id: args.profilePackageId,
                start_date: args.startedTimestamp,
            })
            .then(
                (response) => response.data,
                (error) => handleApiError(error, "Failed to create booking.")
            )
    }

    async postBookingDelete(args: PostBookingDeleteArgs): Promise<ApiResponse> {
        return await apiClient
            .post(this.baseUrl + `/delete/${args.bookingId}`)
            .then(
                (response) => response.data,
                (error) => handleApiError(error, "Failed to delete booking.")
            )
    }

    async postBookingCancel(args: PostBookingCancelArgs): Promise<ApiResponse> {
        return await apiClient
            .post(this.baseUrl + `/cancel/${args.bookingId}/${args.lessonDateTs}`)
            .then(
                (response) => response.data,
                (error) => handleApiError(error, "Failed to cancel booking.")
            )
    }

    constructor(private readonly baseUrl: string) {
    }
}

export const bookingsService = new BookingsService(
    '/bookings'
);
