import {createSelector} from '@reduxjs/toolkit';

import {adapter, SlotsState, type} from './state';

const {selectAll} = adapter.getSelectors();

const featureStateSelector = (state: { [type]: SlotsState }) =>
    state[type];

export const selectedIdSelector = createSelector(
    featureStateSelector,
    state => state.selectedId
);

export const selectedDrawKeySelector = createSelector(
    featureStateSelector,
    state => state.selectedDrawKey
);

export const slotsSelector = createSelector(
    featureStateSelector,
    selectAll
);

export const existingDrawKeysSelector = createSelector(
    slotsSelector,
    slots => (slots.map((slot) => slot.drawKey))
);

export const slotsBySelectedDrawKeySelector = createSelector(
    [slotsSelector,
        selectedDrawKeySelector],
    (slots, drawKey) => (slots.filter((slot) => slot.drawKey === drawKey))
);

export const isFetchingSelector = createSelector(
    featureStateSelector,
    (state) => state.isFetching
);

export const errorMessageSelector = createSelector(
    featureStateSelector,
    (state) => state.errorMessage
);


export const slotsByDayOfWeekSelector = createSelector(
    [
        slotsBySelectedDrawKeySelector,
        (state, dow: number) => dow
    ],
    (slots, dow) => slots.filter((slot) => `${slot.dow}` === `${dow}`)
);
