import {createAsyncThunk} from '@reduxjs/toolkit';

import {GetAllSlotsResponse, slotsService} from '../../services';
import {type} from './state';
import {createClearAllAsyncThunk, createClearErrorAsyncThunk} from "../shared-actions";

export const getAllSlotsWeeklyAction = createAsyncThunk<GetAllSlotsResponse, {profilePackageId: number, startDate: number}>(
    `${type}/getAllSlotsWeeklyAction`,
    async (args, {rejectWithValue}) => {
        try {
            const {profilePackageId, startDate} = args;
            return await slotsService.getAllSlotsWeekly(profilePackageId, startDate);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const setSelectedDrawAction = createAsyncThunk(
    `${type}/setSelectedDrawAction`,
    async (arg: { profilePackageId?: number, startDate?: number }) => {
        const {profilePackageId, startDate} = arg;
        const result = await slotsService.setSelectedDraw(profilePackageId, startDate);
        return {
            drawKey: result.drawKey,
        };
    }
);

export const clearProfilePackageDrawsAction = createAsyncThunk(
    `${type}/clearProfilePackageDrawsAction`,
    async (arg: { profilePackageId: number }) => {
        const {profilePackageId} = arg;
        const result = await slotsService.clearProfilePackageDraws(profilePackageId);
        return {
            profilePackageId: result.profilePackageId,
        };
    }
);

export const setSelectedLearnerIdAction = createAsyncThunk(
    `${type}/setSelectedLearnerIdAction`,
    async (arg: { id: number }) => {
        const {id} = arg;
        const result = await slotsService.setSelectedLearnerId(id);
        return {
            activeLearnerId: result.activeLearnerId,
        };
    }
);

export const clearErrorsAction = createClearErrorAsyncThunk(type);
export const clearAllAction = createClearAllAsyncThunk(type);

