import React, {useMemo} from 'react';
import './ProfilePackageAccordion.css';
import {useAppSelector} from "../../../redux/hooks";
import {bookingsByProfilePackageIdSelector} from "../../../redux/bookings";
import {IonAccordion, IonButton, IonChip, IonItem, IonLabel, IonText} from "@ionic/react";
import {Booking, ProfilePackage} from "../../../models";
import {Ages} from "../../../utils/ages";

interface ProfilePackageAccordionProps {
    profilePackage: ProfilePackage;
    useSelfBooking: boolean;
    useClasses: boolean;
    setSelectedBooking: (booking?: Booking) => void;
}

const ProfilePackageAccordion: React.FC<ProfilePackageAccordionProps> = (props) => {
    const {setSelectedBooking, profilePackage, useClasses} = props;
    const bookingsByProfilePackageId = useAppSelector((state) => bookingsByProfilePackageIdSelector(state, profilePackage.id))
    const bookings = useMemo(() => {
        return bookingsByProfilePackageId && bookingsByProfilePackageId.filter((booking) => new Ages(booking.expiredTimestamp).gte(new Ages().subDays()))
    }, [bookingsByProfilePackageId])

    const hasBookings = bookings && bookings.length > 0;
    const noBookingsMessage = <IonText><p className="ion-padding-top">No upcoming bookings</p></IonText>;
    if (bookingsByProfilePackageId === undefined)return noBookingsMessage

    return (
        <IonAccordion className={'profile-package-accordion'} value={`profile-package-accordion-${profilePackage.id}`}>
            <IonItem slot="header" color="light">
                <IonLabel>
                    <IonText>{profilePackage.name}</IonText>
                    <IonText className={"profile-package-info-holder"} color="primary">
                        {useClasses && profilePackage.bookingLimit && profilePackage.bookingLimit > 0 &&
                            (!profilePackage.lessonLimit || profilePackage.bookingLimit < profilePackage.lessonLimit) &&
                            <p>Weekly Limit: {profilePackage.bookingLimit}</p>
                        }
                        {useClasses && profilePackage.lessonLimit && profilePackage.lessonLimit > 0 &&
                            <p>Limit: {profilePackage.usedLessons && `${profilePackage.usedLessons}/`}{profilePackage.lessonLimit}</p>
                        }
                        {useClasses && profilePackage.lessonEndTs &&
                            <p>End Date: {new Ages(profilePackage.lessonEndTs).toFormat()}</p>
                        }
                    </IonText>
                </IonLabel>
                {useClasses && ((
                    (profilePackage.canViewBookings || profilePackage.canManageBookings) &&
                    <IonButton routerLink={`/slots/${profilePackage.id}`} color={"primary"}>
                        {
                            (profilePackage.canManageBookings && "Manage...")
                            || (profilePackage.canViewBookings && "View")
                        }
                    </IonButton>) || <p>{profilePackage.actionStatus}</p>)
                }

            </IonItem>
            <div className="ion-padding-horizontal ion-padding-bottom" slot="content">
                {useClasses && !hasBookings && noBookingsMessage}
                {useClasses && bookings.sort((a, b) => (a.startedTimestamp > b.startedTimestamp ? 1 : -1)).map((booking) => (
                    booking.slot ?
                        <IonChip className="profile-package-booking-chip ion-text-center"
                                 key={`profile-package-booking-${booking.id}`}
                                 onClick={() => {setSelectedBooking(booking)}}
                        >
                            {booking.slot.dowAbbreviation} {!profilePackage.isRecurring && (new Ages(booking.startedTimestamp).toFormat("dd mmm"))} @ {booking.slot.startTimeDisplay} - {booking.slot.endTimeDisplay}
                            <br/>
                            {booking.slot.facility.name}
                        </IonChip>
                        : <></>
                ))}
            </div>
        </IonAccordion>
    );
};

export default ProfilePackageAccordion;
