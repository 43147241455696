import React from 'react';
import './SlotRow.css';
import {IonChip, IonItem, IonLabel, IonText} from "@ionic/react";
import SkeletonText from "../../common/SkeleltonText";


const SlotRowSkeleton: React.FC = (props) => {
    const skeletonTextStyles = {
        display: 'inline-block',
        marginTop: 0,
        verticalAlign: 'bottom'
    }

    return <IonItem>
        <IonLabel>
            <>
                <SkeletonText style={{...skeletonTextStyles, width: '33px', height: '15px'}} />
                <div style={{...skeletonTextStyles, marginLeft: '5px', marginRight: '5px'}}>
                -
                </div>
                <SkeletonText style={{...skeletonTextStyles, width: '33px', height: '15px'}} />
                <br/>
                <IonText className="text-bold">@</IonText>
                <SkeletonText className="text-bold" style={{...skeletonTextStyles, width: '123px', height: '17px', marginLeft: '3px'}} />
                <br/>
                <IonText className="text-bold">by </IonText>
                <SkeletonText className="text-bold" style={{...skeletonTextStyles, width: '10px', height: '17px', marginLeft: '3px'}} />
                <div style={{display: 'inline-block', marginRight: '4px', marginTop: 0, verticalAlign: 'bottom'}}>
                    .
                </div>
                <SkeletonText className="text-bold" style={{...skeletonTextStyles, width: '123px', height: '17px'}} />
            </>
        </IonLabel>
        <IonChip disabled={true} className={"chip-label"} color={"medium"}>Loading</IonChip>
    </IonItem>;
};

export default SlotRowSkeleton;
