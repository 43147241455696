import {apiUrl} from "../services.config";
import axios from "axios";
import {CompaniesResponse} from "../models";

class CompaniesService {

    async getAll(args: { ids: string[] }): Promise<CompaniesResponse | null> {
        return await axios
            .post(this.baseUrl, {site_ids: args.ids})
            .then(
                (res) => {
                    if (! res.status) {
                        return Promise.reject("Failed to retrieve data.")
                    }
                    return res.data;
                },
                (error) => {
                    if (error.response && error.response.data) {
                        return Promise.reject(error.response.data.message)
                    }
                    else {
                        return Promise.reject("Failed to retrieve data.")
                    }
                }
            )
    }

    async setSelectedId(id: string): Promise<any> {
        const fakePromise = () => {
            return new Promise(resolve => {
                resolve({id: id});
            })
        }
        await fakePromise();
        return await fakePromise();
    }


    constructor(private readonly baseUrl: string) {
    }
}

export const companiesService = new CompaniesService(
    apiUrl + '/companies'
);
