import {unwrapResult} from '@reduxjs/toolkit';
import {useCallback} from 'react';

import {AppDispatch} from "../store";
import {useAppDispatch, useAppSelector} from "../hooks";
// Selectors
import {
    errorMessageSelector,
    existingDrawKeysSelector,
    isFetchingSelector,
    selectedDrawKeySelector,
    slotsBySelectedDrawKeySelector,
    slotsSelector
} from "./selectors";
// Actions
import {
    clearAllAction,
    clearErrorsAction,
    clearProfilePackageDrawsAction,
    getAllSlotsWeeklyAction,
    setSelectedDrawAction,
} from "./actions";


export const useSlotsStore = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const slots = useAppSelector(slotsSelector);
    const slotsBySelectedDrawKey = useAppSelector(slotsBySelectedDrawKeySelector);
    const selectedDrawKey = useAppSelector(selectedDrawKeySelector);
    const isFetching = useAppSelector(isFetchingSelector);
    const existingDrawKeys = useAppSelector(existingDrawKeysSelector);
    const errorMessage = useAppSelector(errorMessageSelector);

    const getAllSlotsWeekly = useCallback(
        (args: {profilePackageId: number, startDate: number}) => {
            return dispatch(getAllSlotsWeeklyAction(args)).then(unwrapResult);
        },
        [dispatch]
    );


    const setSelectedDraw = useCallback(
        (args: { profilePackageId?: number, startDate?: number }) => {
            return dispatch(setSelectedDrawAction(args)).then(unwrapResult);
        },
        [dispatch]
    );

    const clearProfilePackageDraws = useCallback(
        (args: { profilePackageId: number }) => {
            return dispatch(clearProfilePackageDrawsAction(args)).then(unwrapResult);
        },
        [dispatch]
    );

    const clearAll = useCallback(
        () => {
            return dispatch(clearAllAction()).then(unwrapResult);
        },
        [dispatch]
    );

    const clearErrors = useCallback(
        () => {
            return dispatch(clearErrorsAction()).then(unwrapResult);
        },
        [dispatch]
    );

    return {
        slots,
        slotsBySelectedDrawKey,
        isFetching,
        errorMessage,
        getAllSlotsWeekly,
        clearProfilePackageDraws,
        selectedDrawKey,
        setSelectedDraw,
        existingDrawKeys,
        clearAll,
        clearErrors,
    } as const;
};
