export enum CommunicationType {
    EMAIL = 1,
    SMS = 2,
    PUSH = 3,
}

export interface Communication {
    id: number;
    customerId: number;
    sentAtTs: number;
    subject: string;
    message: string;
    type: CommunicationType;
}

export interface KeyedCommunications {
    [key: number]: Communication;
}
