import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';

import {DocumentPage} from '../components';
import {useCustomerStore} from "../../../../redux/customers";
import {useFinancialTransactionStore} from "../../../../redux/financial-transactions";
import {useHistory} from "react-router";

type Props = {
    documentId: number;
};

export const DocumentPageContainer: React.FC<Props> = props => {
    const {documentId} = props;
    const customerStore = useCustomerStore();
    const {activeCustomer} = customerStore;
    const financialTransactionStore = useFinancialTransactionStore();
    const {selectedFinancialTransaction, selectedId, setSelectedId,
           financialTransactionIdsByActiveCustomer: transactionIds} = financialTransactionStore;
    const [prevDocumentAvailable, setPrevDocumentAvailable] = useState<boolean>(false);
    const [nextDocumentAvailable, setNextDocumentAvailable] = useState<boolean>(false);
    const history = useHistory();

    useEffect(() => {
        setSelectedId(documentId);
    }, [documentId])

    useEffect(() => {
        if (transactionIds && selectedId && transactionIds.indexOf(selectedId) > -1) {
            const transactionKeys = Object.keys(transactionIds);
            const currentKeyIndex = transactionIds.indexOf(selectedId);
            if (transactionKeys.length > 1) {
                if (currentKeyIndex < (transactionKeys.length - 1)){
                    setNextDocumentAvailable(true);
                }
                else {
                    setNextDocumentAvailable(false);
                }
                if(currentKeyIndex > 0){
                    setPrevDocumentAvailable(true);
                }
                else {
                    setPrevDocumentAvailable(false);
                }
            }
            else {
                setNextDocumentAvailable(false);
                setPrevDocumentAvailable(false);
            }
        }

    }, [selectedId])

    const goToPrevDocument =  useCallback(() => {
        if (transactionIds && selectedId) {
            const currentKeyIndex = transactionIds.indexOf(selectedId);
            if (transactionIds.length > 1 && currentKeyIndex > 0) {
                setSelectedId(transactionIds[currentKeyIndex - 1]);
            }
        }
    }, [transactionIds, selectedId, setSelectedId,])

    const goToNextDocument = useCallback(() => {
        if(transactionIds && selectedId){
            const currentKeyIndex = transactionIds.indexOf(selectedId);
            if (transactionIds.length > 1 && currentKeyIndex < transactionIds.length) {
                setSelectedId(transactionIds[currentKeyIndex + 1]);
            }
        }
    }, [transactionIds, selectedId, setSelectedId,])

    return <DocumentPage activeCustomer={activeCustomer}
                        document={selectedFinancialTransaction}
                        prevDocumentAvailable={prevDocumentAvailable}
                        nextDocumentAvailable={nextDocumentAvailable}
                        goToPrevDocument={goToPrevDocument}
                        goToNextDocument={goToNextDocument}
    />;
};
