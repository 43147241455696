import {createReducer} from '@reduxjs/toolkit';

import {adapter, initialState} from './state';
import {
    clearAllAction,
    clearErrorsAction,
    getAllCustomers,
    getCustomerById,
    setSelectedCustomerLearnerId,
    setSelectedIdCustomer,
} from './actions';
import {getAllFinancialTransactions} from "../financial-transactions";
import {ApiResponse} from "../../models";

export const reducer = createReducer(initialState, builder =>
    builder
        /** HANDLE ACTION ====================================== */
        .addCase(getAllCustomers.pending, state => {
            return {...state, isFetching: true, errorMessage: undefined};
        })
        .addCase(getAllCustomers.fulfilled, (state, action) => {
            const {customers} = action.payload.results;
            return adapter.setAll({...state, isFetching: false}, customers || []);
        })
        .addCase(getAllCustomers.rejected, (state, action) => {
            const {errorMessage} = action.payload as ApiResponse;
            return {...state, isFetching: false, errorMessage};
        })

        /** HANDLE ACTION ====================================== */
        .addCase(getCustomerById.pending, state => {
            return {...state, isFetching: true, errorMessage: undefined};
        })
        .addCase(getCustomerById.fulfilled, (state, action) => {
            const {customer} = action.payload.results;
            return adapter.updateOne({...state, isFetching: false}, {id: customer.id, changes: customer});
        })
        .addCase(getCustomerById.rejected, (state, action) => {
            const {errorMessage} = action.payload as ApiResponse;
            return {...state, isFetching: false, errorMessage};
        })

        /** HANDLE ACTION ====================================== */
        .addCase(setSelectedIdCustomer.pending, state => {
            return {...state};
        })
        .addCase(setSelectedIdCustomer.fulfilled, (state, action) => {
            const {selectedId} = action.payload;
            return {...state, selectedId: selectedId};
        })
        .addCase(setSelectedIdCustomer.rejected, state => {
            return {...state};
        })

        /** HANDLE ACTION ====================================== */
        .addCase(setSelectedCustomerLearnerId.pending, state => {
            return {...state};
        })
        .addCase(setSelectedCustomerLearnerId.fulfilled, (state, action) => {
            const {selectedLearnerId} = action.payload;
            return {...state, selectedLearnerId: selectedLearnerId};
        })
        .addCase(setSelectedCustomerLearnerId.rejected, state => {
            return {...state};
        })

        /** EXTERNAL ACTIONS =================================== */
        .addCase(getAllFinancialTransactions.pending, state => {
            return {...state, isFetching: true};
        })
        .addCase(getAllFinancialTransactions.fulfilled, (state, action) => {
            const {balances} = action.payload.results;
            return adapter.updateMany({...state, isFetching: false}, Object.values(balances).map((balance) => {
                return {
                    id: balance.customerId,
                    changes: {
                        balance: balance.balance,
                        balanceDisplay: balance.balanceDisplay,
                        balanceBroughtForwardDateDisplay: balance.balanceBroughtForwardDateDisplay,
                        balanceBroughtForwardDisplay: balance.balanceBroughtForwardDisplay,
                    }
                };
            })
            );
        })
        .addCase(getAllFinancialTransactions.rejected, (state, action) => {
            // const {errorMessage} = action.payload as ApiResponse;
            // Let financial handle its own error message
            return {...state, isFetching: false};
        })


        /** HANDLE NON-API ACTIONS ====================================== */
        .addCase(clearAllAction.fulfilled, () => adapter.removeAll({...initialState}))
        .addCase(clearErrorsAction.fulfilled, state => ({...state, errorMessage: undefined}))
);
