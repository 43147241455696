import {createReducer} from '@reduxjs/toolkit';

import {initialState, ModalPageState} from './state';
import {modalPageActions} from './actions';

const createIsOpenReducer = (initialState: ModalPageState, actions: Array<any>) => {
    return createReducer(initialState, builder => {
        actions.forEach(action => {
            builder
                .addCase(action.fulfilled, (state, action) => {
                    const { isOpen } = action.payload;
                    // remove 'set' and 'Action' from the page action
                    const fieldName = `i${action.type.split('/')[1].slice(4).slice(0, -6)}`;
                    return { ...state, [fieldName]: !!isOpen };
                })
                .addCase(action.rejected, (state, action) => {
                    const fieldName = `i${action.type.split('/')[1].slice(4).slice(0, -6)}`;
                    return { ...state, [fieldName]: false };
                });
        });
    });
}

export const reducer = createIsOpenReducer(initialState, modalPageActions);
