import {IonContent, IonPage, IonRefresher, IonRefresherContent} from "@ionic/react";
import React, {RefObject, useEffect} from "react";
import Header from "./Header";
import "./Page.css";
import PageLoading from "./PageLoading"
import {useSystemStore} from "../../redux/system";
import {useAuthStore} from "../../redux/auth";

export interface PageProps {
    children: any;
    // Header Props
    title: any;
    subtitle?: any;
    backRouterLink?: string;
    showMenuButton?: boolean;
    showBackButton?: boolean;
    showHeaderBackgroundColor?: boolean;
    showHeader?: boolean;
    // Loading indicator props
    showLoading?: any | null;
    loadingMessage?: string;
    showLoadingOnPageLoad?: boolean;
    topRightButton?: any;
    className?: string;
    footer?: any;

    doRefresh?: (event: CustomEvent) => void;
    refresherRef?: RefObject<HTMLIonRefresherElement> | ((ref: HTMLIonRefresherElement | null) => void);
    setShowLoading?(loading: boolean | null): void | null;
}


const Page: React.FC<PageProps> = ({
                                       children,
                                       title,
                                       subtitle,
                                       backRouterLink,
                                       showMenuButton = true,
                                       showBackButton = true,
                                       showHeaderBackgroundColor = true,
                                       showHeader = true,
                                       loadingMessage,
                                       showLoading,
                                       topRightButton,
                                       footer,
                                       className,
                                       doRefresh,
                                       refresherRef,
                                   }) => {

    const systemStore = useSystemStore();
    const { isOnline } = systemStore;

    const authStore = useAuthStore();
    const { isLoggingOut } = authStore;

    useEffect(() => {
        if (typeof refresherRef === 'function') {
            return;
        }
        if (refresherRef && refresherRef.current) {
            refresherRef.current.complete();
        }
    }, [refresherRef]);

    return (
        <IonPage className={`${className}`} >
            <>
                <PageLoading showLoading={showLoading || isLoggingOut} loadingMessage={loadingMessage} />
            </>
            {showHeader &&
                <Header title={title} subtitle={subtitle} backRouterLink={backRouterLink} showMenuButton={showMenuButton}
                        showBackgroundColor={showHeaderBackgroundColor}
                        showBackButton={showBackButton} topRightButton={topRightButton}
                />}
            <IonContent fullscreen>
            {isOnline ? null : <div className="network-warning-bar">You are currently offline.</div>}
                {!!doRefresh && <IonRefresher
                    className="pull-to-refresh"
                    slot="fixed"
                    onIonRefresh={doRefresh}
                    ref={refresherRef}
                >
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>}
                {children}
            </IonContent>
            {footer}
        </IonPage>
    );
};

export default Page;
