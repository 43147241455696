import React, {FC} from "react";
import './PublicHeaderTitle.css'
import {IonImg} from "@ionic/react";

export interface PublicHeaderTitleProps {}

const PublicHeaderTitle: FC<PublicHeaderTitleProps> = (props) => {

    return (
        <div className={`public-header-title-wrapper ion-text-center ion-padding-horizontal`}>
            <IonImg className="public-header-title-logo" src={"/assets/logo.png"}/>
        </div>
    )
};

export default PublicHeaderTitle;
