import * as React from 'react';
import {useEffect, useRef} from 'react';

import {CustomerSelectorPage} from '../components';
import {useCustomerStore} from "../../../redux/customers";
import {useHistory} from "react-router";
import {useAuthStore} from "../../../redux/auth";
import {useToastStore} from "../../../redux/toast";
import {useProfilePackageStore} from "../../../redux/profile-packages";
import {useBookingsStore} from "../../../redux/bookings";

type Props = {
};

export const CustomerSelectorPageContainer: React.FC<Props> = props => {

    const history = useHistory();
    const customerStore = useCustomerStore();
    const bookingsStore = useBookingsStore();
    const profilePackageStore  = useProfilePackageStore();
    const toastStore = useToastStore();
    const authStore = useAuthStore();
    const {setSelectedId, isFetching, activeCustomer, customers} = customerStore;
    const {isEmailVerified, me} = authStore;

    const refresherRef = useRef<HTMLIonRefresherElement>(null);
    const {} = props;

    const fetchData = () => {
        if (isEmailVerified){
            customerStore.getAll().catch(() => {
                toastStore.showError({message: "Failed to retrieve account options."});
            }).finally(() => {
                if (refresherRef.current) {
                    refresherRef.current.complete();
                }
            });
            bookingsStore.getAll({}).catch(() => {
                toastStore.showError({message: "Failed to retrieve account booking data."});
            });
            profilePackageStore.getAll({}).catch(() => {
                toastStore.showError({message: "Failed to retrieve account package data."});
            });
        }
        else {
            me().finally(() => {
                if (refresherRef.current) {
                    refresherRef.current.complete();
                }
            });
        }
    }

    const handleCustomerSelectClick = (selectedId: number) => {
        setSelectedId({id: selectedId}).then(() => {
            history.push('/active-customer');
        });
    }

    useEffect(() => {
        if(isEmailVerified && fetchData){
            fetchData();
        }
    }, [isEmailVerified])


    const handleRefresh = async (event: CustomEvent) => {
        if (!isFetching){
            fetchData();
        }
    };

    return <CustomerSelectorPage
        customerSelected={handleCustomerSelectClick}
        doRefresh={handleRefresh}
        refresherRef={refresherRef}

        isEmailVerified={!!(isEmailVerified)}
        isFetching={isFetching}
        activeCustomer={activeCustomer}
        customers={customers}
    />;
};
