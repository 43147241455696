import {createReducer} from '@reduxjs/toolkit';

import {initialState} from './state';
import {clearAllAction, clearErrorsAction, deleteAction, getAction, setIsOpenDeleteModalAction,} from './actions';
import {ApiResponse, AuthStatus} from "../../models";

export const reducer = createReducer(initialState, builder =>
    builder
        /** HANDLE ACTION ====================================== */
        .addCase(getAction.pending, (state, action) => {
            return {...state, errorMessage: undefined, isLoading: true};
        })
        .addCase(getAction.fulfilled, (state, action) => {
            const {user} = action.payload.results;
            return {
                ...state,
                errorMessage: undefined,
                user: user,
                isLoading: false,
            };
        })
        .addCase(getAction.rejected, (state, action) => {
            const {errorMessage} = action.payload as ApiResponse;
            return {...state, errorMessage, status: AuthStatus.NOT_LOGGED_IN, isLoading: false};
        })

        /** HANDLE ACTION ====================================== */
        .addCase(deleteAction.pending, (state, action) => {
            return {...state, errorMessage: undefined, isLoading: true};
        })
        .addCase(deleteAction.fulfilled, (state, action) => {
            return {...state, errorMessage: undefined, isLoading: false};
        })
        .addCase(deleteAction.rejected, (state, action) => {
            const {errorMessage} = action.payload as ApiResponse;
            return {...state, errorMessage, isLoading: false};
        })

        /** HANDLE ACTION ====================================== */
        .addCase(setIsOpenDeleteModalAction.fulfilled, (state, action) => {
            const { isOpen } = action.payload;
            return { ...state, isOpenDeleteModal: !!isOpen };
        })
        .addCase(setIsOpenDeleteModalAction.rejected, (state, action) => {
            return { ...state, isOpenDeleteModal: false };
        })


        /** HANDLE NON-API ACTIONS ====================================== */
        .addCase(clearAllAction.fulfilled, () => ({...initialState}))
        .addCase(clearErrorsAction.fulfilled, state => ({...state, errorMessage: undefined, errors: undefined}))
);
