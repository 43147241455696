import {createSelector} from '@reduxjs/toolkit';

import {adapter, FinancialTransactionsState, type} from './state';
import {selectedIdSelector as customerSelectedIdSelector} from "../customers/selectors";

const {selectAll} = adapter.getSelectors();

const featureStateSelector = (state: { [type]: FinancialTransactionsState }) => state[type];

export const selectedIdSelector = createSelector(
    featureStateSelector,
    state => state.selectedId
);

export const errorMessageSelector = createSelector(
    featureStateSelector,
    state => state.errorMessage
);

export const isFetchingFinancialTransactionsSelector = createSelector(
    featureStateSelector,
    state => state.isFetching
);

export const financialTransactionsSelector = createSelector(
    featureStateSelector,
    selectAll
);

export const selectedFinancialTransactionSelector = createSelector(
    financialTransactionsSelector,
    selectedIdSelector,
    (financialTransactions, selectedId) => {
        // Find the financial transaction with the selectedId
        return financialTransactions.find(transaction => transaction.id === selectedId);
    }
);

export const financialTransactionsByActiveCustomerSelector = createSelector(
    [
        financialTransactionsSelector,
        customerSelectedIdSelector,
    ],
    // Output selector
    (financialTransactions, customerId) => financialTransactions ? financialTransactions.filter(financialTransaction => financialTransaction.customerId === customerId) : financialTransactions
);

export const financialTransactionIdsByActiveCustomerSelector = createSelector(
    financialTransactionsByActiveCustomerSelector,
    (financialTransactions) => {
        // Find the financial transaction with the selectedId
        return financialTransactions.map(transaction => transaction.id);
    }
);
