import {unwrapResult} from '@reduxjs/toolkit';
import {useCallback} from 'react';

import {holidaysByActiveCustomerSelector, isFetchingSelector, selectedIdSelector,} from './selectors';

// Action (service) interfaces
import {clearAllAction, clearErrorsAction,} from './actions';

import {useAppDispatch, useAppSelector} from "../hooks";
import {AppDispatch} from "../store";

export const useHolidaysStore = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const selectedId = useAppSelector(selectedIdSelector);
    const isFetching = useAppSelector(isFetchingSelector);
    const holidaysByActiveCustomer = useAppSelector(holidaysByActiveCustomerSelector);

    const clearAll = useCallback(
        () => {
            return dispatch(clearAllAction()).then(unwrapResult);
        },
        [dispatch]
    );

    const clearErrors = useCallback(
        () => {
            return dispatch(clearErrorsAction()).then(unwrapResult);
        },
        [dispatch]
    );


    return {
        isFetching,
        holidaysByActiveCustomer,
        clearAll,
        clearErrors,
        selectedId,
    } as const;
};
