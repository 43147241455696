import {ToastOptions} from "@ionic/core/dist/types/components/toast/toast-interface";

export const type = 'toast';

export interface ToastState extends ToastOptions {
    isOpen: boolean,
    toastColor?: string,
    duration?: number,
}

export const initialState: ToastState = {
    isOpen: false,
    toastColor: 'primary',
    duration: 5000,
};
