import {IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonText, IonTitle, IonToolbar} from "@ionic/react";
import {menuController} from '@ionic/core/components';
import React from "react";
import IconButton from "../common/IconButton/IconButton";
import {arrowBack, menu} from 'ionicons/icons';
import './Header.css';

interface HeaderProps {
    title: any;
    subtitle?: any;
    backRouterLink?: string;
    showMenuButton?: boolean;
    showBackButton?: boolean;
    showBackgroundColor?: boolean;
    topRightButton?: any;
    onClickBackButton?: () => void;
    icon?: any;
}

const toggleMenu = () => {
    menuController.toggle();
}

const Header: React.FC<HeaderProps> = (
    {
        title,
        subtitle,
        showMenuButton,
        showBackButton = true,
        showBackgroundColor = true,
        backRouterLink,
        topRightButton,
        onClickBackButton,
    }
) => {
    return (
        <IonHeader className="app-header">
            <IonToolbar className={`app-header-toolbar${showBackgroundColor && " app-header-toolbar-primary"}`}>

                <IonButtons slot="start">
                    {showMenuButton &&
                        <IconButton onClick={toggleMenu} icon={menu}/>
                    }
                    {showBackButton && onClickBackButton &&
                        <IonButton fill="clear" color="primary" onClick={onClickBackButton}>
                            <IonIcon icon={arrowBack} slot="start" />
                        </IonButton>
                    }
                    {
                        showBackButton && !showMenuButton && !backRouterLink && !onClickBackButton &&
                        <IonBackButton className="app-back-button" defaultHref={'/home'} icon={arrowBack} text=""/>
                    }
                    {showBackButton && backRouterLink &&
                        <IonBackButton className="app-back-button" defaultHref={backRouterLink} icon={arrowBack} text=""/>
                    }
                    {
                        !showBackButton && !showMenuButton &&
                        <div slot="end" className={"menu-space-holder"}>&nbsp;</div>
                    }
                </IonButtons>
                {
                    title && typeof (title) === 'string' ?
                        <IonTitle>
                            <h4 className="ion-no-margin">{title}</h4>
                            {
                                subtitle && typeof (subtitle) === 'string' ?
                                    <IonText>{subtitle}</IonText>
                                    :
                                    subtitle
                            }
                        </IonTitle>
                        :
                        title
                }

                {topRightButton ||
                    <div slot="end" className={"menu-space-holder"}>&nbsp;</div>
                }
            </IonToolbar>
        </IonHeader>
    );
};

export default Header;
