import {unwrapResult} from '@reduxjs/toolkit';
import {useCallback} from 'react';

import {
    clearAllAction,
    clearErrorsAction,
    createAction,
    deleteAction,
    getAllAction,
    getByIdAction,
    setSelectedIdAction
} from './actions';
import {
    errorMessageSelector,
    isChangingWaitingBookingSelector,
    isFetchingWaitingBookingsSelector,
    selectedIdSelector,
    selectedSelector,
    waitingBookingsSelector,
} from './selectors';

// Action (service) interfaces
import {PostWaitingBookingCreateArgs, PostWaitingBookingDeleteArgs} from "../../services";

import {useAppDispatch, useAppSelector} from "../hooks";
import {AppDispatch} from "../store";

export const useWaitingBookingsStore = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const waitingBookings = useAppSelector(waitingBookingsSelector);
    const selected = useAppSelector(selectedSelector);
    const isFetching = useAppSelector(isFetchingWaitingBookingsSelector);
    const selectedId = useAppSelector(selectedIdSelector);
    const isChangingWaitingBooking = useAppSelector(isChangingWaitingBookingSelector);
    const errorMessage = useAppSelector(errorMessageSelector);

    const getAll = useCallback(
        (args: {customerId?: number, learnerId?: number}) => {
            return dispatch(getAllAction(args)).then(unwrapResult);
        },
        [dispatch]
    );

    const getById = useCallback(
        (args: {waitingBookingId: number}) => {
            return dispatch(getByIdAction(args)).then(unwrapResult);
        },
        [dispatch]
    );

    const setSelectedId = useCallback(
        (args: {waitingBookingId?: number}) => {
            return dispatch(setSelectedIdAction(args)).then(unwrapResult);
        },
        [dispatch]
    );

    const waitingBookingCreate = useCallback(
        (args: PostWaitingBookingCreateArgs) => {
            return dispatch(createAction(args)).then(unwrapResult);
        },
        [dispatch]
    );

    const waitingBookingDelete = useCallback(
        (args: PostWaitingBookingDeleteArgs) => {
            return dispatch(deleteAction(args)).then(unwrapResult);
        },
        [dispatch]
    );


    const clearAll = useCallback(
        () => {
            return dispatch(clearAllAction()).then(unwrapResult);
        },
        [dispatch]
    );


    const clearErrors = useCallback(
        () => {
            return dispatch(clearErrorsAction()).then(unwrapResult);
        },
        [dispatch]
    );


    return {
        isFetching,
        isChangingWaitingBooking,
        errorMessage,
        waitingBookings,
        getById,
        getAll,
        setSelectedId,
        selected,
        clearAll,
        clearErrors,
        selectedId,
        waitingBookingCreate,
        waitingBookingDelete,
    } as const;
};
