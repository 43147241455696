import React, {useCallback, useEffect, useState} from 'react';
import {useHistory} from "react-router";
import {useCustomerStore} from "../../redux/customers";
import {useAuthStore} from "../../redux/auth";
import {useSlotsStore} from "../../redux/slots";
import {useProfilePackageStore} from "../../redux/profile-packages";
import {useBookingsStore} from "../../redux/bookings";
import {Device} from "@capacitor/device";
import {Capacitor} from "@capacitor/core";
import {useHolidaysStore} from "../../redux/holidays";
import {useCommunicationsStore} from "../../redux/communications";
import {useWaitingBookingsStore} from "../../redux/waiting-bookings";

interface Props {
    mustLogout: boolean,
    mustLogoutWithoutApi: boolean,
    setMustLogout: (newMustLogout: boolean) => void,
}

const LogoutHandler: React.FC<Props> = props => {
    const isDeviceAvailable = Capacitor.isPluginAvailable('Device');
    const {mustLogout, setMustLogout, mustLogoutWithoutApi} = props;
    const [deviceUuid, setDeviceUuid] = useState<string>();
    const history = useHistory();
    const customerStore = useCustomerStore();
    const authStore = useAuthStore();
    const profilePackageStore = useProfilePackageStore();
    const slotsStore = useSlotsStore();
    const waitingBookingsStore = useWaitingBookingsStore();
    const bookingsStore = useBookingsStore();
    const communicationsStore = useCommunicationsStore();
    const holidaysStore = useHolidaysStore();

    const {clearAll: communicationsClearAll} = communicationsStore;
    const {clearAll: bookingsClearAll} = bookingsStore;
    const {clearAll: waitingBookingsClearAll} = waitingBookingsStore;
    const {clearAll: holidaysClearAll} = holidaysStore;
    const {clearAll: slotsClearAll} = slotsStore;
    const {clearAll: customerClearAll} = customerStore;
    const {clearAll: ppClearAll} = profilePackageStore;
    const {isLoading: authIsLoading, isLoggingOut, isAuthed, logout: apiLogout, clearAll: authClearAll} = authStore;

    const clearAllAndRedirect = useCallback(() => {
        Promise.all([
            slotsClearAll(),
            bookingsClearAll(),
            waitingBookingsClearAll(),
            holidaysClearAll(),
            communicationsClearAll(),
            customerClearAll(),
            ppClearAll(),
            authClearAll(),
        ]).then(() => {
            history.replace('/auth/login');
        }).catch(() => {
            console.error('Error while clearing local storage');
        });
    }, [history, slotsClearAll, bookingsClearAll, waitingBookingsClearAll, customerClearAll, ppClearAll, authClearAll])

    const logout = useCallback(() => {
        setMustLogout(false);
        if (isAuthed){
            if (isDeviceAvailable){
                Device.getId().then((deviceId) => {
                    setDeviceUuid(deviceId.identifier)
                }).finally(() => {
                    apiLogout({deviceUuid}).finally(() => {
                        clearAllAndRedirect();
                    });
                });
            }
            else {
                apiLogout({deviceUuid}).finally(() => {
                    clearAllAndRedirect();
                });
            }
        } else {
            clearAllAndRedirect();
        }
    }, [apiLogout, clearAllAndRedirect, setMustLogout, deviceUuid, isAuthed, isDeviceAvailable]);

    useEffect(() => {
        if (mustLogout) {
            logout();
        }
        else if(!isAuthed && (authIsLoading || isLoggingOut)){
            clearAllAndRedirect();
        }
    }, [mustLogout])

    useEffect(() => {
        if (mustLogoutWithoutApi) {
            clearAllAndRedirect();
        }
    }, [mustLogoutWithoutApi])

    return null;
};

export default LogoutHandler;
