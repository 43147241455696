import {createAsyncThunk} from '@reduxjs/toolkit';

import {type} from './state';
import {communicationsService, getAllCommunicationsResponse} from "../../services";
import {createClearAllAsyncThunk, createClearErrorAsyncThunk} from "../shared-actions";

export const getAllAction = createAsyncThunk<getAllCommunicationsResponse, { customerId?: number }>(
    `${type}/getAllAction`,
    async (args, {rejectWithValue}) => {
        try {
            const {customerId} = args;
            return {
                ...(await communicationsService.getAll(customerId)),
                customerId,
            };
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


export const setSelectedIdAction = createAsyncThunk(
    `${type}/setSelectedIdAction`,
    async (args: {id?: number}) => {
        const result = await communicationsService.setSelectedId(args);
        return {
            selectedId: result.id,
        };
    }
);


export const clearErrorsAction = createClearErrorAsyncThunk(type);
export const clearAllAction = createClearAllAsyncThunk(type);

