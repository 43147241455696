import React from 'react';
import './IconButton.css';
import {IonButton, IonIcon} from "@ionic/react";
import {IconButtonProps} from "./IconButtonProps";

const IconButton: React.FC<IconButtonProps> = ({className, loadingIcon,  isLoading, color, icon, slot, routerLink, onClick}) => {
    return (
        <IonButton slot={slot} className={`slx-icon-button ${className}`} color={color} onClick={onClick} routerLink={routerLink} fill="clear" disabled={isLoading}>
            <IonIcon slot="icon-only" icon={isLoading && loadingIcon ? loadingIcon : icon }/>
        </IonButton>
    );
};

export default IconButton;
