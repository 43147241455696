import {createEntityAdapter, EntityState} from '@reduxjs/toolkit';

import {Holiday} from '../../models';

export const type = 'holidays';

export interface HolidaysState extends EntityState<Holiday, number> {
    isFetching: boolean;
    selectedId: number | null;
}

export const adapter = createEntityAdapter<Holiday>({
});

export const initialState: HolidaysState = adapter.getInitialState({
    isFetching: false,
    selectedId: null,
});
