import React, {useMemo} from 'react';
import './SlotRow.css';
import {IonItem, IonLabel, IonText} from "@ionic/react";
import {Company, ProfilePackage, Slot} from "../../../models";
import SlotBookingStatusChips from "../SlotBookingStatusChips";
import SlotAvailabilityStatusChips from "../SlotAvailabilityStatusChips";
import SlotAvailableLabel from "../SlotAvailableLabel";
import SlotButtons from "../SlotButtons";
import {useAppSelector} from "../../../redux/hooks";
import {bookingIdsFilteredSelector} from "../../../redux/bookings";
import {
    waitingBookingIdsFilteredSelector,
    waitingBookingOrderNumbersFilteredSelector
} from "../../../redux/waiting-bookings";

export interface SlotRowProps {
    canAction: boolean;
    profilePackage: ProfilePackage;
    company: Company;
    slot: Slot;

    onRowClicked: (slot: Slot) => void;
    onBookClicked: (slotId: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
    onWaitClicked: (slotId: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
    onCancelWaitClicked: (waitingBookingId: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
    onDeleteClicked: (bookingId: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
    onCancelClicked: (bookingId: number, lessonDateTs: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
}

const SlotRow: React.FC<SlotRowProps> = (props) => {
    const {canAction, profilePackage, company, slot, onBookClicked, onWaitClicked, onCancelWaitClicked, onCancelClicked, onDeleteClicked, onRowClicked} = props;
    const selectorArgs = useMemo(() => ({profilePackageId: profilePackage.id, slots: [slot]}), [profilePackage.id, slot]);
    // visibleBookingIds is repeated in SlotModal
    const visibleBookingIds = useAppSelector((state) => bookingIdsFilteredSelector(state, selectorArgs ));
    // visibleWaitingBookingIds is repeated in SlotModal
    const visibleWaitingBookingIds = useAppSelector((state) => waitingBookingIdsFilteredSelector(state, selectorArgs ));
    // visibleWaitingBookingOrderNumbers is repeated in SlotModal
    const visibleWaitingBookingOrderNumbers = useAppSelector((state) => waitingBookingOrderNumbersFilteredSelector(state, selectorArgs ));
    // slotDescription is repeated in SlotModal
    const slotDescription = `${slot.dowAbbreviation} @ ${slot.startTimeDisplay} - ${slot.lessonDateDisplay}`;

    const slotWaitingOrderNumber = (visibleWaitingBookingOrderNumbers && visibleWaitingBookingOrderNumbers.hasOwnProperty(slot.id)
    && visibleWaitingBookingOrderNumbers[slot.id]) || undefined;

    return <IonItem className="slot-row" key={`profile-package-slot-${slot.id}`} onClick={() => {onRowClicked(slot)}}>
        <IonLabel>
            <>
                <IonText className="text-bold" key={`slot-${slot.id}-time`}>{`${slot.startTimeDisplay} - ${slot.endTimeDisplay} `}</IonText>
                {slot.lessonType && [<br key='br-lesson-type'/>, slot.lessonType.name]}
                {slot.facility && [<br key='br-facility'/>, <IonText className="text-bold" key={`slot-${slot.id}-at-text`}>@&nbsp;</IonText>, slot.facility.name]}
                <br key='br-by'/>
                <IonText className="text-bold" key={`slot-${slot.id}-by-text`}>by </IonText>
                {slot.employees && slot.employees.map((employee, iEmployee) => {
                    return <IonText key={`profile-package-slot-${slot.id}-employee-${employee.id}`}
                    >{employee.fullName}{iEmployee < slot.employees.length - 1 && `,`} </IonText>;
                })}
            </>
        </IonLabel>
        <>
            <SlotAvailableLabel slot={slot} className="slot-available ion-text-sm" waitingOrderNumber={slotWaitingOrderNumber} />
            <SlotButtons
                key={`slot-row-${slot.id}-buttons`}
                company={company}
                slot={slot}
                profilePackage={profilePackage}
                canAction={canAction}
                visibleBookingIds={visibleBookingIds}
                visibleWaitingBookingIds={visibleWaitingBookingIds}
                slotDescription={slotDescription}
                onCancelClicked={onCancelClicked}
                onDeleteClicked={onDeleteClicked}
                onWaitClicked={onWaitClicked}
                onCancelWaitClicked={onCancelWaitClicked}
                onBookClicked={onBookClicked}
            />
        </>
        <SlotAvailabilityStatusChips slot={slot} canAction={canAction} />
        <SlotBookingStatusChips slot={slot} canAction={canAction} />
    </IonItem>;
};

export default SlotRow;
