import {ProfilePackage, Slot} from "../models";
import apiClient from "./api/apiClient";
import {handleApiError} from "./api/apiErrorHandler";

export interface GetAllSlotsResponse {
    results: {
        slots: Slot[],
        profilePackage: ProfilePackage,
        startDateTs: string,
        endDateTs: string,
    },
    profilePackageId?: number
    startDate: number
}

class SlotsService {

    async getAllSlotsWeekly(profilePackageId: number, startDate: number): Promise<GetAllSlotsResponse> {
        return await apiClient
            .get(this.baseUrl + `/weekly/${profilePackageId}/${startDate}`)
            .then(
                (response) => ({...response.data, profilePackageId: profilePackageId, startDate: startDate}),
                (error) => handleApiError(error, "Failed to retrieve slot data.")
            )
    }

    async setSelectedDraw(profilePackageId: number|undefined, startDate: number|undefined): Promise<{ drawKey: string }> {
        const fakePromise = () => {
            return new Promise(resolve => {
                resolve({});
            })
        }
        await fakePromise();
        return {
            drawKey: `${profilePackageId}-${startDate}`
        };
    }

    async clearProfilePackageDraws(profilePackageId: number): Promise<{ profilePackageId: string }> {
        const fakePromise = () => {
            return new Promise(resolve => {
                resolve({});
            })
        }
        await fakePromise();
        return {
            profilePackageId: `${profilePackageId}`
        };
    }

    async setSelectedLearnerId(learnerId: number): Promise<any> {
        const fakePromise = () => {
            return new Promise(resolve => {
                resolve({activeLearnerId: learnerId});
            })
        }
        await fakePromise();
        return await fakePromise();
    }

    constructor(private readonly baseUrl: string) {
    }
}

export const slotsService = new SlotsService(
    '/slots'
);
