import * as React from 'react';
import {useEffect, useState} from 'react';

import {useForm} from "react-hook-form";
import {useAuthStore} from '../../../../redux/auth';
import {useHistory} from "react-router";
import {useToastStore} from "../../../../redux/toast";
import {AuthSetPasswordPartial} from "../components";
import {formFieldInputs as setPasswordFormFieldInputs,} from "../../../../forms/auth/set-password.form";
import {formFieldInputs as requestCodeFormFieldInputs,} from "../../../../forms/auth/request-code.form";

export interface AuthSetPasswordPartialContainerProps {
    processTitle: string;
    processMessage?: string;
}

export const AuthSetPasswordPartialContainer: React.FC<AuthSetPasswordPartialContainerProps> = containerProps => {
    const authStore = useAuthStore();
    const toastStore = useToastStore();
    const history = useHistory();
    const [hasCode, setHasCode] = useState<boolean|null>(null);
    const [mustSetAuthEmail, setMustSetAuthEmail] = useState<boolean>(false);
    const {isLoading, authEmail, login, errors, errorMessage, clearErrors} = authStore;
    const {processTitle, processMessage} = containerProps;

    const requestCodeForm = useForm<{ email: string }>({
        mode: "onSubmit",
        defaultValues: {
            // email: authEmail
        }
    });

    const setPasswordForm = useForm<{ email: string, code: string, password: string }>({
        mode: "onSubmit",
        // defaultValues: { email: authEmail },
    });
    const {setValue: setPasswordFormSetValue } = setPasswordForm;

    useEffect(() => {
        errorMessage && toastStore.showError({message: errorMessage}).then(() => clearErrors());
    }, [errorMessage])

    useEffect(() => {
        if(errors && Object.keys(errors).length > 0){
            Object.keys(errors).map((fieldName: string) => {
                errors[fieldName].map((fieldError: string) => {
                    // @ts-ignore
                    setPasswordForm.setError(fieldName, {message: fieldError})
                    // @ts-ignore
                    requestCodeForm.setError(fieldName, {message: fieldError})
                })
            })
        }
    }, [errors])

    useEffect(() => {
        if (mustSetAuthEmail){
            setMustSetAuthEmail(false);
            setPasswordFormSetValue('email', authEmail || '', {
                shouldTouch: true,
                shouldDirty: true,
                shouldValidate: true,
            });
            setPasswordForm.setFocus('code');
        }
    }, [mustSetAuthEmail])

    useEffect(() => {
        setMustSetAuthEmail(true);
        clearErrors();
        setPasswordForm.clearErrors();
        requestCodeForm.clearErrors();
    }, [])

    const handleSubmitRequest = (data: { email: string }) => {
        authStore.forgot({forgotEmail: data.email}).then((response) => {
            setHasCode(true);
            setMustSetAuthEmail(true);
            toastStore.showSuccess({message: response.message || '', header: 'Code sent'});
        }).catch((data) => {
            toastStore.showError({message: data.message || '', header: 'Password Reset Failed'});
        });
    };

    const handleSubmitResetPassword = (data: { email: string, code: string, password: string }) => {
        authStore.setPassword(data).then((response) => {
            toastStore.showSuccess({message: response.results.message || '', header: 'Password has been set'});
            login({username: data.email, password: data.password});
        }).catch(() => { /** Nothing to do here */ });
    };

    const chooseHasCodeClicked = (selectedHasCode: boolean) => {
        setHasCode(selectedHasCode);
        setPasswordForm.clearErrors();
        requestCodeForm.clearErrors();
    };

    const resetHasCodeClicked = () => {
        setHasCode(null);
        setPasswordForm.clearErrors();
        requestCodeForm.clearErrors();
    }

    const goBackClicked = () => {
        setHasCode(null);
        setPasswordForm.clearErrors();
        requestCodeForm.clearErrors();
        history.goBack();
    }

    return <AuthSetPasswordPartial
        processTitle={processTitle}
        processMessage={processMessage}

        isLoading={isLoading}
        hasCode={hasCode}
        chooseHasCodeClicked={chooseHasCodeClicked}

        requestCodeForm={requestCodeForm}
        requestCodeHandleSubmit={handleSubmitRequest}
        requestCodeFormFields={requestCodeFormFieldInputs}

        setPasswordForm={setPasswordForm}
        setPasswordHandleSubmit={handleSubmitResetPassword}
        setPasswordFormFields={setPasswordFormFieldInputs}

        setHasCodeClicked={resetHasCodeClicked}
        goBackClicked={goBackClicked}
    />;
};
