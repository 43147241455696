import {createSelector} from '@reduxjs/toolkit';

import {ModalPageState, type} from './state';

const featureStateSelector = (state: { [type]: ModalPageState }) => state[type];

const createIsOpenSelector = (fieldName: keyof ModalPageState) => {
    return createSelector(
        featureStateSelector,
        state => state[fieldName]
    );
};

export const isOpenWaitingBookingAcceptSelector = createIsOpenSelector('isOpenWaitingBookingAccept');
export const isOpenAboutSelector = createIsOpenSelector('isOpenAbout');
export const isOpenPrivacySelector = createIsOpenSelector('isOpenPrivacy');
export const isOpenTermsOfUseSelector = createIsOpenSelector('isOpenTermsOfUse');
