import React, {ReactElement} from 'react';
import {IonButton} from "@ionic/react";

export interface FormSubmitButtonProps {
    disabled?: boolean;
    outerClassName?: string;
    className?: string;
    mustWrap?: boolean;
    fill?: 'clear' | 'outline' | 'solid' | 'default';
    size?: 'small' | 'default' | 'large';
    children?: any;
}

const FormSubmitButton: React.FC<FormSubmitButtonProps> = (props) => {
    const {className, fill, outerClassName, size, mustWrap = true, disabled, children} = props;
    const inner: ReactElement = (<IonButton
        disabled={disabled}
        className={className || `ion-margin`}
        fill={fill || 'solid'}
        size={size}
        type="submit"
    >
        {children || "Save"}</IonButton>);

    return (
        (!mustWrap && inner) ||
        (<div className={outerClassName || 'ion-text-right ion-margin-top'}>
            {inner}
        </div>)
    );
};

export default FormSubmitButton;
