import {createSelector} from '@reduxjs/toolkit';

import {adapter, BookingsState, type} from './state';
import {Booking, Slot} from "../../models";
import {Ages} from "../../utils/ages";

const {selectAll} = adapter.getSelectors();

const featureStateSelector = (state: { [type]: BookingsState }) => state[type];

export const selectedIdSelector = createSelector(
    featureStateSelector,
    state => state.selectedId
);

export const isFetchingBookingsSelector = createSelector(
    featureStateSelector,
    state => state.isFetching
);

export const errorMessageSelector = createSelector(
    featureStateSelector,
    (state) => state.errorMessage
);

export const isChangingBookingSelector = createSelector(
    featureStateSelector,
    (state) => state.isChangingBooking
);

export const bookingsSelector = createSelector(
    featureStateSelector,
    selectAll
);

export const bookingsByProfilePackageIdSelector = createSelector(
    [
        bookingsSelector,
        // Take the second arg, `profilePackageId`, and forward to the output selector
        (state, profilePackageId: number | undefined): number => profilePackageId || -1
    ],
    // Output selector
    (bookings, profilePackageId) => bookings ? bookings.filter(booking => booking.profilePackageId === profilePackageId) : bookings
)

interface bookingsFiltersSelectorArgs {
    debug?: boolean,
    profilePackageId?: number,
    slots?: Slot[],
    dows?: number[],
    weekStartDateTs?: number,
}

const filterBookings = (bookings: Booking[], args: bookingsFiltersSelectorArgs) => bookings ? bookings.filter(booking => {
    const {profilePackageId, slots, dows} = args;
    if (profilePackageId && booking.profilePackageId !== profilePackageId){
        return false;
    }

    if (slots && slots.length === 0) {
        return false;
    }

    if (dows && !dows.includes(booking.slot.dow)) {
        return false;
    }

    // if (weekStartDateTs) {
    //     let bookingFound: boolean = false;
    //     for (let i = 0; i < 7; i++) {
    //         const workingTimestamp = new Ages(weekStartDateTs).addDays(i).toTs();
    //
    //         if (booking.startedTimestamp <= workingTimestamp
    //             && (!booking.expiredTimestamp || booking.expiredTimestamp >= workingTimestamp)
    //             && booking.slot
    //             && booking.slot.dow === tsToDayOfWeekNum(workingTimestamp)
    //         ) {
    //             bookingFound = true;
    //             i = 7;
    //         }
    //     }
    //
    //     if (!bookingFound){
    //         return false;
    //     }
    // }

    if (slots) {
        const bookedSlots = slots.filter((slot) => {
            return slot.id === booking.slotId
        });
        if (bookedSlots.length === 0) {
            return false;
        }

        const lessonDates = bookedSlots.map(bookedSlot => bookedSlot.lessonDateTs)
            .filter((d, i, lds) => lds.indexOf(d) === i) // Unique/Distinct only
            .sort((a, b) => (a > b ? 1 : -1));  // Sort by date

        const firstLessonDate = new Ages(lessonDates[0]);
        const lastLessonDate = new Ages(lessonDates[lessonDates.length - 1]);
        const bookingStartDate = new Ages(booking.startedTimestamp);
        const bookingExpireDate = (booking.expiredTimestamp && new Ages(booking.expiredTimestamp)) || null;
        if (!(bookingStartDate.lte(lastLessonDate) && (!bookingExpireDate || bookingExpireDate.gte(firstLessonDate)))) {
            return false;
        }
    }

    return true;
}) : []

export const bookingsFilteredSelector = createSelector(
    [
        bookingsSelector,
        (state, args: bookingsFiltersSelectorArgs): bookingsFiltersSelectorArgs => args || {profilePackageId: -1, slots: []}
    ],
    // Output selector
    (bookings, args) => filterBookings(bookings, args)
)

export const bookingIdsFilteredSelector = createSelector(
    bookingsFilteredSelector,
    // Output selector
    bookings => Object.fromEntries(bookings.map((booking) => {
        return [booking.slotId, booking.id]
    }))
)
