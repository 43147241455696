import * as React from 'react';
import './slots-page.component.css';
import Page from "../../../components/core/Page";
import {IonButton, IonChip, IonIcon, IonItem, IonLabel, IonList, IonText, IonToggle, IonToolbar} from "@ionic/react";

import {Booking, Company, Employee, Facility, Learner, LessonType, ProfilePackage, Slot} from "../../../models";
import {Ages, dateToFormat} from "../../../utils/ages";
import {chevronBack, chevronForward} from "ionicons/icons";
import SkeletonText from "../../../components/common/SkeleltonText";
import SlotRow from "../../../components/slots/SlotRow/SlotRow";
import SlotRowSkeleton from "../../../components/slots/SlotRow/SlotRowSkeleton";
import SlotModal from "../../../components/slots/SlotModal/SlotModal";
import SlotFilterModal from "../../../components/slots/SlotFilterModal/SlotFilterModal";

export interface AvailableDateOption {
    active: boolean,
    ts: number,
    date: Date,
    isBooked: boolean,
    isWaitingBooked: boolean,
    isPast: boolean,
    isHoliday: boolean
}

type SlotsPageProps = {
    company?: Company;
    learner?: Learner;
    profilePackage?: ProfilePackage;
    availableDates: AvailableDateOption[];
    bookings?: Booking[];
    slots?: Slot[];
    slotTypes?: LessonType[];
    facilities?: Facility[];
    employees?: Employee[];

    // Loading states
    isFetching: boolean;
    isChangingBooking: boolean;
    isInitialLoadComplete: boolean;

    // Booking Events
    onBookClicked: (slotId: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
    onWaitClicked: (slotId: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
    onCancelWaitClicked: (waitingBookingId: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
    onDeleteClicked: (bookingId: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
    onCancelClicked: (bookingId: number, lessonDateTs: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;

    // Dates
    setSelectedDate: (date: number) => void;
    selectedDate: number;
    selectedDateIsToday: boolean;
    selectedWeekIndex: number;
    weeksAvailableFuture: number;
    weeksAvailablePast: number;

    // Filtering
    isSlotFilterModalOpen: boolean;
    onSlotFilterOpenClick: () => void;

    onCloseSlotFilterModal: () => void;
    filterAvailableOnly: boolean;
    setFilterAvailableOnly: (availableOnly: boolean) => void;
    filterBookedOnly: boolean;
    setFilterBookedOnly: (bookedOnly: boolean) => void;
    filterSlotType?: number;
    setFilterSlotType: (id?: number) => void;
    filterFacility?: number;
    setFilterFacility: (id?: number) => void;
    filterEmployee?: number;
    setFilterEmployee: (id?: number) => void;

    onPreviousWeekClicked: () => void;
    onNextWeekClicked: () => void;

    // Slot UI
    selectedSlot?: Slot;
    isSlotModalOpen: boolean;
    onSlotClick: (slot: Slot) => void;
    onCloseModal: () => void;
};

export const SlotsPage: React.FC<SlotsPageProps> = props => {
    const {
        company,
        learner,
        profilePackage,
        availableDates,
        selectedWeekIndex,
        slots,
        facilities,
        employees,
        slotTypes,
        isFetching,
        isChangingBooking,
        isInitialLoadComplete,

        onBookClicked,
        onWaitClicked,
        onCancelWaitClicked,
        onDeleteClicked,
        onCancelClicked,
        setSelectedDate,
        selectedDate,
        selectedDateIsToday,
        onPreviousWeekClicked,
        onNextWeekClicked,
        weeksAvailableFuture,
        weeksAvailablePast,

        filterAvailableOnly,
        setFilterAvailableOnly,
        filterBookedOnly,
        setFilterBookedOnly,
        filterSlotType,
        setFilterSlotType,
        filterFacility,
        setFilterFacility,
        filterEmployee,
        setFilterEmployee,

        isSlotFilterModalOpen,
        onCloseSlotFilterModal,
        onSlotFilterOpenClick,

        selectedSlot,
        isSlotModalOpen,
        onSlotClick,
        onCloseModal,
    } = props;

    const isFiltered = (filterAvailableOnly || filterBookedOnly || filterFacility || filterEmployee || filterSlotType);

    /**
     * DatesToolbar Components split off for convenience
     * Move to file if you need to add functionality
     * @constructor
     */
    const DatesToolbar: React.FC = () => {
        return (
            // Current Date Range
            <div className={"dates-toolbar-holder"}>
            <IonToolbar className="dates-toolbar">
                {availableDates && selectedWeekIndex > -weeksAvailablePast && (
                    <IonChip disabled={isFetching || isChangingBooking} onClick={onPreviousWeekClicked}
                             className={'ion-text-center ion-no-padding text-bold date-filter-week-changer date-filter-option-show-history'}
                             key="date-filter-option-previous-week"
                    >
                        <IonIcon icon={chevronBack}/>
                    </IonChip>
                )}
                {
                    availableDates && availableDates.map((dateFilterOption, i) =>
                        (<IonChip
                            className={`ion-text-center ion-no-padding ion-no-margin date-filter-option 
                                 ${dateFilterOption.isPast && ' date-filter-option-is-past'}
                                 ${dateFilterOption.isBooked && ' date-filter-option-is-booked'}
                                 ${dateFilterOption.isWaitingBooked && ' date-filter-option-is-waiting-booked'}`}
                            outline={selectedDate !== dateFilterOption.ts}
                            disabled={!dateFilterOption.active || isChangingBooking || isFetching}
                            color={selectedDate === dateFilterOption.ts ? "primary" : undefined}
                            key={`date-filter-option-${i}`}
                            onClick={() => {
                                setSelectedDate(dateFilterOption.ts)
                            }}
                        >
                            <div
                                className={`date-filter-option-inner ${selectedDate === dateFilterOption.ts && " text-bold"}`}
                            >
                                {dateToFormat(dateFilterOption.date, window.innerWidth <= 450 ? 'DD' : 'ddd')}
                                <br/>
                                {
                                    (isFetching &&
                                    <SkeletonText style={{'height': '8px'}}></SkeletonText>)
                                    || dateFilterOption.date.getDate()
                                }
                                {!isFetching && <div className={"date-filter-option-booked-marker"}></div>}
                            </div>
                        </IonChip>)
                    )
                }
                {availableDates && selectedWeekIndex < weeksAvailableFuture && (
                    <IonChip disabled={isFetching || isChangingBooking} onClick={onNextWeekClicked}
                             className={'ion-text-center ion-no-padding text-bold date-filter-week-changer date-filter-option-show-history'}
                             key="date-filter-option-next-week"
                    >
                        <div className="ion-text-center ion-no-padding date-filter-week-changer-inner ">
                            <IonIcon icon={chevronForward}/>
                        </div>
                    </IonChip>
                )}
            </IonToolbar>
        </div>
    )};

    /**
     * FiltersToolbar Components split off for convenience
     * Move to file if you need to add functionality or reuse
     * @constructor
     */
    const FiltersToolbar: React.FC = () => (
        <IonToolbar className="filters-toolbar ion-margin-bottom">
            { !filterBookedOnly &&
                (facilities?.length === 1 && !filterFacility)
                && (employees?.length === 1 && !filterEmployee)
                && (slotTypes?.length === 1 && !filterSlotType) &&
                <IonItem slot="start" lines="none">
                    <IonLabel>
                        <IonToggle onIonChange={event => setFilterAvailableOnly(event.detail.checked)} checked={filterAvailableOnly}>Available</IonToggle>
                    </IonLabel>
                </IonItem>
            }
            {
                (filterBookedOnly ||
                    ((facilities && facilities?.length > 1) || filterFacility)
                || ((employees && employees?.length > 1) || filterEmployee)
                || ((slotTypes && slotTypes?.length > 1) || filterSlotType)) &&
                <IonItem slot="start" lines="none">
                    <IonButton onClick={onSlotFilterOpenClick} color={"primary"} >
                        {!isFiltered && "Filters"}
                        {isFiltered && "Filtered"}
                    </IonButton>

                    { isFiltered &&
                        <IonButton onClick={() => {
                            setFilterFacility(undefined);
                            setFilterSlotType(undefined);
                            setFilterEmployee(undefined);
                            setFilterBookedOnly(false);
                            setFilterAvailableOnly(false);
                        }} color={"light"}>Clear</IonButton>
                    }
                </IonItem>

            }
            <IonItem  className="ion-text-right" lines="none" slot="end" >
                <IonLabel style={{paddingTop: "9px", paddingBottom: "4px", textWrap: "nowrap"}}>
                    {!isFetching && selectedDate && selectedDate > 0 && new Ages(selectedDate).toFormat("dd mmm yyyy")}
                    {isFetching && <SkeletonText style={{display: "inline-block", width: "90px", height: "20px", marginTop: "-5px" }} />}
                </IonLabel>
            </IonItem>
        </IonToolbar>
    );

    return (
        <Page className={"slots-page ion-text-center"}
              title={`${profilePackage?.name}`}
              subtitle={learner?.fullName}
              showBackButton={true}
              showMenuButton={false}
              showLoading={false}
        >

            <DatesToolbar />

            {(!profilePackage?.isRecurring && <FiltersToolbar />)}
            {
                !isFetching && ((!slots || slots.length === 0) &&
                    <IonText>
                        <>
                            <h4>{(((profilePackage?.isRecurring && `No bookings to ${selectedDateIsToday ? 'today' : 'show'}`))
                                || (
                                    filterBookedOnly ? (<> No bookings found. <br key="br-no-slot-1"/><br key="br-no-slot-2"/> Change the [My Bookings] filter to see what's available</>)
                                        : "No slots available"
                                ))}</h4>
                        </>
                    </IonText>
                )
            }
            {
                    <IonList>
                        {!isInitialLoadComplete && isFetching && <SlotRowSkeleton/>}

                        {(!isFetching || isInitialLoadComplete) && profilePackage && company && slots && slots.length > 0
                            && slots.map((slot) => (
                                <SlotRow
                                    key={`slot-${slot.id}`}
                                    company={company}
                                    slot={slot}
                                    profilePackage={profilePackage}
                                    onRowClicked={onSlotClick}
                                    onCancelClicked={onCancelClicked}
                                    onDeleteClicked={onDeleteClicked}
                                    onBookClicked={onBookClicked}
                                    onWaitClicked={onWaitClicked}
                                    onCancelWaitClicked={onCancelWaitClicked}
                                    canAction={isChangingBooking || isFetching}
                                />
                            ))
                        }
                    </IonList>
            }
            {profilePackage && (
                <SlotFilterModal
                    isOpen={isSlotFilterModalOpen}
                    onClose={onCloseSlotFilterModal}

                    company={company}
                    facilities={facilities}
                    employees={employees}
                    slotTypes={slotTypes}

                    filterAvailableOnly={filterAvailableOnly}
                    setFilterAvailableOnly={setFilterAvailableOnly}
                    filterBookedOnly={filterBookedOnly}
                    setFilterBookedOnly={setFilterBookedOnly}
                    filterSlotType={filterSlotType}
                    setFilterSlotType={setFilterSlotType}
                    filterFacility={filterFacility}
                    setFilterFacility={setFilterFacility}
                    filterEmployee={filterEmployee}
                    setFilterEmployee={setFilterEmployee}

                />
            )}
            {profilePackage && company && selectedSlot && (
                <SlotModal
                    isOpen={isSlotModalOpen}
                    onClose={onCloseModal}
                    company={company}
                    slot={selectedSlot}
                    profilePackage={profilePackage}
                    canAction={isChangingBooking || isFetching}
                    onCancelClicked={onCancelClicked}
                    onDeleteClicked={onDeleteClicked}
                    onBookClicked={onBookClicked}
                    onWaitClicked={onWaitClicked}
                    onCancelWaitClicked={onCancelWaitClicked}

                />
            )}
        </Page>
    );
};
