// Generic Interfaces
export interface FormFields {
    [field: string]: any
}

export interface Form {
    fields: FormFields;
    errors?: { [fieldName: string]: string[] };
    errorMessage?: string;
}

export const formInitialState: Form = {
    fields: {},
    errors: undefined,
    errorMessage: undefined,
}
