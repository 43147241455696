import * as React from 'react';

import {AuthSetPasswordPartial} from "../../auth-set-password/auth-set-password.partial";

type Props = {};

export const AuthForgotPageContainer: React.FC<Props> = props => {

    return <AuthSetPasswordPartial processTitle={'Reset Password'}/>
};
