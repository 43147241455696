import * as React from 'react';

import {AuthLoginPageContainer} from './containers';

interface AuthLoginPageInterface {

}

export const AuthLoginPage: React.FC<AuthLoginPageInterface> = (props) => {
    const {} = props;

    return (
        <>
            <AuthLoginPageContainer/>
        </>
    );
};
