import {unwrapResult} from '@reduxjs/toolkit';
import {useCallback} from 'react';

import {
    clearAllAction,
    clearErrorsAction,
    getAllCustomers,
    getCustomerById,
    setSelectedCustomerLearnerId,
    setSelectedIdCustomer,
} from './actions';
import {
    activeCompanySelector,
    activeCustomerSelector,
    activeLearnerSelector,
    customersCountSelector,
    customersSelector,
    errorMessageSelector,
    isFetchingSelector,
    selectedIdSelector,
} from './selectors';
import {useAppDispatch, useAppSelector} from "../hooks";
import {AppDispatch} from "../store";

export const useCustomerStore = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const isFetching = useAppSelector(isFetchingSelector);
    const errorMessage = useAppSelector(errorMessageSelector);
    const customersCount = useAppSelector(customersCountSelector);
    const customers = useAppSelector(customersSelector);
    const activeCustomer = useAppSelector(activeCustomerSelector);
    const activeLearner = useAppSelector(activeLearnerSelector);
    const activeCompany = useAppSelector(activeCompanySelector);
    const selectedId = useAppSelector(selectedIdSelector);

    // const bookingsByProfilePackageId = useCallback(
    //     (profilePackageId: string ) => {
    //         useAppSelector((state) => bookingsByProfilePackageIdSelector(state, profilePackageId))
    //         return bookingsByProfilePackageIdSelector;
    //     },
    //     []
    // );

    const getAll = useCallback(
        () => {
            return dispatch(getAllCustomers()).then(unwrapResult);
        },
        [dispatch]
    );

    const getById = useCallback(
        (id: number) => {
            return dispatch(getCustomerById(id)).then(unwrapResult);
        },
        [dispatch]
    );

    const clearAll = useCallback(
        () => {
            return dispatch(clearAllAction()).then(unwrapResult);
        },
        [dispatch]
    );

    const clearErrors = useCallback(
        () => {
            return dispatch(clearErrorsAction()).then(unwrapResult);
        },
        [dispatch]
    );

    const setSelectedId = useCallback(
        (arg: { id: number }) => {
            return dispatch(setSelectedIdCustomer(arg)).then(unwrapResult);
        },
        [dispatch]
    );

    const setSelectedLearnerId = useCallback(
        (arg: { learnerId: number }) => {
            return dispatch(setSelectedCustomerLearnerId(arg)).then(unwrapResult);
        },
        [dispatch]
    );

    return {
        isFetching,
        errorMessage,
        customersCount,
        customers,
        activeCompany,
        activeCustomer,
        activeLearner,
        getAll,
        getById,
        clearAll,
        clearErrors,
        setSelectedId,
        setSelectedLearnerId,
        selectedId,
    } as const;
};
