import {createAsyncThunk} from '@reduxjs/toolkit';

import {customersService, getByIdResponse} from '../../services';
import {type} from './state';
import {createClearAllAsyncThunk, createClearErrorAsyncThunk} from "../shared-actions";

export const getAllCustomers = createAsyncThunk(
    `${type}/getAllCustomers`,
    async (_, {rejectWithValue}) => {
        try {
            return await customersService.getAll();
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getCustomerById = createAsyncThunk<getByIdResponse, number>(
    `${type}/getCustomerById`,
    async (id: number, {rejectWithValue}) => {
        try {
            return await customersService.getById(id);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const setSelectedIdCustomer = createAsyncThunk(
    `${type}/setSelectedIdCustomer`,
    async (arg: { id: number }) => {
        const {id} = arg;
        const result = await customersService.setSelectedId(id);
        return {
            selectedId: result.id,
        };
    }
);

export const setSelectedCustomerLearnerId = createAsyncThunk(
    `${type}/setSelectedCustomerLearnerId`,
    async (arg: { learnerId: number }) => {
        const {learnerId} = arg;
        const result = await customersService.setSelectedLearnerId(learnerId);
        return {
            selectedLearnerId: result.learnerId,
        };
    }
);

export const clearErrorsAction = createClearErrorAsyncThunk(type);
export const clearAllAction = createClearAllAsyncThunk(type);
