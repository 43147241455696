import {ApiResponse} from "../models";
import apiClient from "./api/apiClient";
import {handleApiError} from "./api/apiErrorHandler";

class PasswordService {

    async forgot(forgotEmail: string): Promise<ApiResponse> {
        return await apiClient
            .post(this.baseUrl + "/forgot", {
                email: forgotEmail,
            })
            .then(
                (response) => response.data,
                (error) => handleApiError(error, 'Request Failed')
            )
    }

    async setPassword(email: string, password: string, code: string): Promise<ApiResponse> {
        return await apiClient
            .post(this.baseUrl + "/set", {
                email: email,
                password: password,
                code: code
            })
            .then(
                (response) => response.data,
                (error) => handleApiError(error, 'Request Failed')
            )
    }

    constructor(private readonly baseUrl: string) {
    }
}

export const passwordService = new PasswordService(
    '/password'
);
