import {createAsyncThunk} from '@reduxjs/toolkit';

import {type} from './state';
import {financialTransactionsService, getAllFinancialTransactionsResponse} from "../../services";
import {createClearAllAsyncThunk, createClearErrorAsyncThunk} from "../shared-actions";


export const getAllFinancialTransactions = createAsyncThunk<getAllFinancialTransactionsResponse, { customerId?: number }>(
    `${type}/getAllFinancialTransactions`,
    async (args, {rejectWithValue}) => {
        try {
            const {customerId} = args;
            return await financialTransactionsService.getAllFinancialTransactions(customerId)
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


export const setSelectedIdFinancialTransaction = createAsyncThunk(
    `${type}/setSelectedIdFinancialTransaction`,
    async (id?: number) => {
        const result = await financialTransactionsService.setSelectedId(id);
        return {
            selectedId: result.id,
        };
    }
);

export const clearErrorsAction = createClearErrorAsyncThunk(type);
export const clearAllAction = createClearAllAsyncThunk(type);

