import * as React from 'react';

import {AuthForgotPageContainer} from './containers';

interface Props {

}

export const AuthForgotPage: React.FC<Props> = () => {

  return (
    <>
      <AuthForgotPageContainer />
    </>
  );
};
