import * as Forms from "../forms";
import {FormFieldInputs} from "../../components/input/Input";

export interface FormFields extends Forms.FormFields {
    username?: string
    password?: string
}

export const formFieldInputs: FormFieldInputs = {
    username: {
        name: "username",
        label: "Email",
        type: "email",
        rules: {
            required: "This field is required",
            maxLength: {
                value: 100,
                message: "Cannot be more than 100 chars"
            },
        },
    },
    password: {
        name: "password",
        label: "Password",
        type: "password",
        rules: {
            required: "This field is required",
            maxLength: {
                value: 100,
                message: "Password is too long"
            },
        },
    },
}

export interface Form extends Forms.Form { fields: FormFields }

export const initialState: Form = {
    ...Forms.formInitialState,
    // fields: { // Add initial field values if needed }
    fields: {
        username: undefined,
        password: undefined,
    }
}

export const formName = 'login';
