import * as React from 'react';

import {AuthRequestVerificationPageContainer} from './containers';

export const AuthRequestVerificationPage: React.FC = () => {

  return (
    <>
      <AuthRequestVerificationPageContainer />
    </>
  );
};
