import * as Forms from "../forms";
import {FormFieldInputs} from "../../components/input/Input";

export interface FormFields extends Forms.FormFields {
    username?: string
    password?: string
    code?: string
}

export const formFieldInputs: FormFieldInputs = {
    email: {
        name: "email",
        label: "Email",
        type: "email",
        rules: {
            required: "This field is required",
            maxLength: {
                value: 100,
                message: "Cannot be more than 100 chars"
            },
        },
    },
    code: {
        name: "code",
        label: "Code",
        type: "one-time-code",
        rules: {
            required: "This field is required",
            maxLength: {
                value: 150,
                message: "Cannot be more than 100 chars"
            },
        },
    },
    password: {
        name: "password",
        label: "Password",
        type: "password",
        rules: {
            required: "This field is required",
            maxLength: {
                value: 150,
                message: "Cannot be more than 100 chars"
            },
        }
    },
};

export interface Form extends Forms.Form { fields: FormFields }

export const initialState: Form = {
    ...Forms.formInitialState,
    // fields: { // Add initial field values if needed }
}

export const formName = 'setPassword';
