import * as React from 'react';

import {AuthSetPasswordPartialContainer, AuthSetPasswordPartialContainerProps} from './containers';


export const AuthSetPasswordPartial: React.FC<AuthSetPasswordPartialContainerProps> = (props) => {

  return (
    <>
      <AuthSetPasswordPartialContainer {...props} />
    </>
  );
};
