import {createReducer} from '@reduxjs/toolkit';

import {adapter, initialState} from './state';
import {bookingCreateAction, bookingDeleteAction, clearAllAction, clearErrorsAction, getAllBookings,} from './actions';
import {ApiResponse} from "../../models";

export const reducer = createReducer(initialState, builder =>
    builder
        /** HANDLE ACTION ====================================== */
        .addCase(getAllBookings.pending, state => {
            return {...state, isFetching: true, errorMessage: undefined};
        })
        .addCase(getAllBookings.fulfilled, (state, action) => {
            const { bookings, learnerId, customerId } = action.payload.results;
            if (state.entities && (learnerId || customerId)) {
                // Get the ids of the bookings related to the specific learner or customer
                const bookingIdsToRemove = state.ids.filter(id =>
                    state.entities.hasOwnProperty(id) &&
                    ((learnerId && state.entities[id]?.learnerId === learnerId) ||
                    (customerId))
                );
                // Remove the old bookings related to the specified learner or customer
                state = adapter.removeMany(state, bookingIdsToRemove);
            }
            // Upsert the new bookings related to the specified learner or customer
            return adapter.upsertMany({...state, isFetching: false}, bookings || []);
        })
        .addCase(getAllBookings.rejected, (state, action) => {
            const { errorMessage } = action.payload as ApiResponse;
            return {...state, isFetching: false, errorMessage};
        })

        /** HANDLE ACTION ====================================== */
        .addCase(bookingCreateAction.pending, state => {
            return {...state, isChangingBooking: true, errorMessage: undefined};
        })
        .addCase(bookingCreateAction.fulfilled, (state, action) => {
            const {booking} = action.payload.results;
            if (booking){
                return adapter.upsertOne({...state, isChangingBooking: false}, booking);
            }
            else {
                return {...state, isChangingBooking: false};
            }
        })
        .addCase(bookingCreateAction.rejected, (state, action) => {
            const { errorMessage } = action.payload as ApiResponse;
            return {...state, isChangingBooking: false, errorMessage};
        })

        /** HANDLE ACTION ====================================== */
        .addCase(bookingDeleteAction.pending, state => {
            return {...state, isChangingBooking: true, errorMessage: undefined};
        })
        .addCase(bookingDeleteAction.fulfilled, (state, action) => {
            const {bookingId} = action.payload;
            if (bookingId){
                return adapter.removeOne({...state, isChangingBooking: false}, bookingId);
            }
            else {
                return {...state, isChangingBooking: false};
            }
        })
        .addCase(bookingDeleteAction.rejected, (state, action) => {
            const { errorMessage } = action.payload as ApiResponse;
            return {...state, isChangingBooking: false, errorMessage};
        })

        /** HANDLE NON-API ACTIONS ====================================== */
        .addCase(clearAllAction.fulfilled, () => adapter.removeAll({...initialState}))
        .addCase(clearErrorsAction.fulfilled, state => ({...state, errorMessage: undefined}))
);
