import {Redirect, Route} from "react-router-dom";
import React from "react";
import {RouteProps} from "react-router";
import {useAuthStore} from "../../../redux/auth";

interface GuardedRouteProps extends RouteProps {
    guestOnly?: boolean,
    component: any,
    redirectTo?: false | string;
}

const GuardedRoute: React.FC<RouteProps & GuardedRouteProps> = ({
    guestOnly = false,
    component: Component,
    redirectTo = false,
    ...rest
}) => {
    const {isAuthed} = useAuthStore();

    const renderComponent = (props: any) =>
        isAuthed === !guestOnly ? (
            <Component {...props} />
        ) : (
            <Redirect to={guestOnly ? "/home" : "/auth/login"} />
        );

    if (redirectTo && redirectTo.charAt(0) === "/") {
        return <Redirect to={redirectTo} />;
    }

    return <Route {...rest} render={renderComponent} />;
};

export default GuardedRoute;
