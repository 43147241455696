import React, {FC} from "react";
import './TabFooter.css'
import {IonButton, IonCol, IonFooter, IonGrid, IonIcon, IonRow, IonToolbar} from "@ionic/react";
import {calendar, newspaper} from 'ionicons/icons';
import {Customer} from "../../models";

export interface TabFooterProps {
    activeRoute: string;
    customer: Customer;
}

const TabFooter: FC<TabFooterProps> = (props) => {

    const {
        activeRoute, customer
    } = props;

    const isHomePage = activeRoute === "/home";
    const isAccountPage = activeRoute === "/account";

    return (
        <IonFooter className={"tab-footer ion-text-center"}>
            <IonToolbar className="ion-no-padding">
                <IonGrid className="ion-no-padding">
                    <IonRow>
                        <IonCol className={`tab-footer-button-holder ${isHomePage ? 'tab-footer-button-holder-active' : ''}`}>
                            <IonButton className={`tab-footer-button`} routerLink={"/home"} routerDirection={'none'} fill='clear' expand="full">
                                <IonIcon icon={calendar} size='large' className={`tab-footer-icon ${isHomePage ? 'tab-footer-icon-active' : ''}`} />
                            </IonButton>
                        </IonCol>
                        { customer.company.useBilling &&
                            <IonCol className={`tab-footer-button-holder ${isAccountPage ? 'tab-footer-button-holder-active' : ''}`}>
                                <IonButton className={`tab-footer-button`} routerLink={"/account"} routerDirection={'none'} fill='clear' expand="full">
                                    <IonIcon icon={newspaper} size='large' className={`tab-footer-icon ${isAccountPage ? 'tab-footer-icon-active' : ''}`}  />
                                </IonButton>
                            </IonCol>
                        }
                    </IonRow>
                </IonGrid>
            </IonToolbar>
        </IonFooter>
    )
};

export default TabFooter;
