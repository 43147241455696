import * as React from 'react';
import './privacy.page.css';
import {IonText} from "@ionic/react";
import ModalPage from "../../components/core/ModalPage";
import {useModalPageStore} from "../../redux/modal-pages";

type Props = {
    onClose?: () => void,
};

export const PrivacyPage: React.FC<Props> = props => {
    const {onClose} = props;
    const modalPageStore = useModalPageStore();
    const {isOpenPrivacy, setIsOpenPrivacy} = modalPageStore;

    return (
        <ModalPage className={"privacy-page"}
                   title={"Privacy Policy"}
                   isOpen={!!isOpenPrivacy}
                   setIsOpen={setIsOpenPrivacy}
                   onClose={onClose}
        >
            <div className="ion-padding ion-margin-horizontal">

                <h1>Privacy Policy</h1>
                <h5>swool.io Customer Mobile App</h5>

                <p>Last updated: 1st March 2023</p>

                <h2>Introduction</h2>
                <p>This Privacy Policy outlines how the swool.io Customer Mobile App ("the App") collects, uses, and discloses your personal information when you use the App. The App is provided by swool.io, a SaaS system used by schools, coaches, and businesses for various management services. The App is designed for clients of swool-serviced businesses to manage their bookings and view account history.</p>
                <p>Please note that this Privacy Policy applies only to the App and not to the entirety of swool.io's services. The privacy of your personal information in relation to the services provided by the businesses using swool.io as their billing and scheduling platform is the responsibility of those businesses.</p>
                <p>By using the App, you agree to the collection and use of your personal information in accordance with this Privacy Policy.</p>

                <h2>Information We Collect</h2>
                <p>To provide and maintain the functionality of the App, we may collect the following types of personal information:</p>
                <ul>
                    <li>Personal identification information: such as your name, email address, phone number, and date of birth.</li>
                    <li>Billing information: such as your financial transactions history or other information.</li>
                    <li>Technical information: such as your device's IP address, operating system, browser type, and app usage data.</li>
                </ul>

                <h2>How We Use Your Information</h2>
                <p>We use your personal information for the following purposes:</p>
                <ul>
                    <li>To provide and maintain the App's features and functionality, including processing bookings and managing account history.</li>
                    <li>To communicate with you about updates, promotions, or important information related to the App.</li>
                    <li>To improve the App by analyzing usage data and user feedback.</li>
                    <li>To detect, prevent, and address technical issues within the App.</li>
                    <li>To comply with legal obligations or to protect the rights, property, or safety of swool.io and its users.</li>
                </ul>

                <h2>Disclosure of Your Information</h2>
                <p>We may share your personal information with third parties under the following circumstances:</p>
                <ul>
                    <li>With the swool-serviced businesses you interact with through the App to enable them to provide services to you.</li>
                    <li>With service providers who assist us in operating and maintaining the App or providing other services on our behalf.</li>
                    <li>When required by law, legal process, or to comply with a request from a governmental authority.</li>
                    <li>To protect the rights, property, or safety of swool.io, its users, or the public.</li>
                </ul>

                <h2>Your Rights Under POPIA</h2>
                <p>As a data subject under the Protection of Personal Information Act (POPIA), you have the following rights with respect to your personal information:</p>
                <ul>
                    <li>The right to be informed about the collection and use of your personal information.</li>
                    <li>The right to access your personal information.</li>
                    <li>The right to rectify any inaccurate or incomplete personal information.</li>
                    <li>The right to request the deletion or removal of your personal information under certain circumstances.</li>
                    <li>The right to restrict the processing of your personal information under certain circumstances.</li>
                    <li>The right to data portability, which allows you to obtain and reuse your personal information for your own purposes across different services.</li>
                    <li>The right to object to the processing of your personal information under certain circumstances.</li>
                </ul>
                <p>If you wish to exercise any of your rights under POPIA, please contact us using the contact information provided in this Privacy Policy. We may require you to provide proof of your identity before we can process your request.</p>

                <h2>Security</h2>
                <p>We are committed to protecting the security of your personal information. We use appropriate technical and organizational measures to safeguard your information from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of electronic transmission or storage is 100% secure, and we cannot guarantee the absolute security of your information.</p>

                <h2>Changes to This Privacy Policy</h2>
                <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of the policy. You are advised to review this Privacy Policy periodically for any changes. Your continued use of the App following the posting of changes constitutes your acceptance of those changes.</p>

                <h2>Contact Us</h2>
                <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
                <ul>
                    <li>
                        <IonText className="text-semi-bold">Email: &nbsp;</IonText><a href="mailto:hello@solunetix.co.za">hello@solunetix.co.za</a>
                    </li>
                </ul>
            </div>
        </ModalPage>
    );
};
