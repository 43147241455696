import React from "react";
import {IonButton, IonHeader, IonModal, IonTitle, IonToolbar} from "@ionic/react";

import './ActionModal.css';

interface ActionModalProps {
    children: any;
    isOpen: boolean;
    onClose: () => void;

    title: string,
    buttonCloseText?: string,
}

const ActionModal: React.FC<ActionModalProps> = (
    {
        children,
        isOpen,
        onClose,
        title,
        buttonCloseText = "Close",
    }) => {

    return (
        <IonModal isOpen={isOpen} onDidDismiss={onClose} className={"ion-text-center action-modal"}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        {title}
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <>
                {children}
                <IonButton onClick={onClose} className="ion-margin-bottom" expand="full">{ buttonCloseText }</IonButton>
            </>
        </IonModal>
    );
};

export default ActionModal;
