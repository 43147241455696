import {IonCardTitle, IonChip, IonCol, IonGrid, IonRow} from '@ionic/react';
import * as React from 'react';
import {RefObject} from 'react';

import Page from "../../../../components/core/Page";
import './account-page.component.css';
import {Customer, FinancialTransaction} from "../../../../models";
import BankingDetails from "../../../../components/financials/banking-details";
import SubtitleText from "../../../../components/common/SubtitleText/SubtitleText";
import CurrencyPrefix from "../../../../components/financials/currency-prefix";
import Separator from "../../../../components/common/Seperator/Seperator";
import TabFooter from "../../../../components/core/TabFooter";
import SkeletonText from "../../../../components/common/SkeleltonText";

type Props = {
    isFetching: boolean;
    activeCustomer: Customer | null;
    transactions: FinancialTransaction[];
    transactionSelected: (documentId: number) => void;
    doRefresh: () => void;
    refresherRef?: RefObject<HTMLIonRefresherElement> | ((ref: HTMLIonRefresherElement | null) => void);
};

export const AccountPage: React.FC<Props> = props => {
    const {activeCustomer, transactionSelected, transactions, isFetching, refresherRef, doRefresh} = props;
    const customerHasTransactions = (transactions && Object.keys(transactions).length > 0)
    const customerHasFinancial = activeCustomer?.balanceDisplay || customerHasTransactions;

    return (
        <Page className={"account-page"}
              title={'Account'}
              showMenuButton={false}
              showBackButton={true}
              topRightButton={false}
              footer={activeCustomer && <TabFooter activeRoute={'/account'} customer={activeCustomer}/>}
              showLoading={false}
              doRefresh={doRefresh}
              refresherRef={refresherRef}
        >


            <div className={"ion-text-left ion-padding"}>
                <IonChip color={'primary'} className={"ion-float-sm-end ion-margin-bottom text-semi-bold"}>Account
                    Code: {activeCustomer?.accountCode}</IonChip>
                <IonCardTitle>{activeCustomer?.fullName}</IonCardTitle>
            </div>

            <Separator/>

            {(!customerHasFinancial && <SubtitleText>No account history found</SubtitleText>)}
            {customerHasFinancial &&
                <IonGrid className={"ion-text-left ion-padding"}>
                    <IonRow>
                        <IonCol>
                            <SubtitleText className={"ion-no-margin ion-no-padding text-bold"}>Account
                                History</SubtitleText>
                        </IonCol>
                        <IonCol className={"ion-text-right text-semi-bold"}>
                            <IonChip color={'tertiary'}>
                                Balance:&nbsp;<CurrencyPrefix customer={activeCustomer}/>
                                {(!isFetching && activeCustomer?.balanceDisplay) || <SkeletonText style={{width: "65px", height: "13px"}} />}
                            </IonChip>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            }
            {customerHasFinancial &&
                <IonGrid className={"transactions-grid ion-text-left ion-padding"}>
                    <IonRow className={"ion-nowrap transactions-grid-headings"}>
                        <IonCol className={"ion-text-left ion-no-padding"}>Date</IonCol>
                        <IonCol className={"ion-text-left ion-no-padding"}>Description</IonCol>
                        <IonCol className={"ion-no-padding ion-text-right"}>Amount</IonCol>
                        <IonCol className={"ion-no-padding ion-text-right"}>Total</IonCol>
                    </IonRow>
                    {customerHasTransactions && Object.values(transactions)
                        .sort((a, b) => (a.runningOrder < b.runningOrder ? 1 : -1))
                        .map((transaction) => (
                            <IonRow onClick={() => {
                                transactionSelected(transaction.id);
                            }} className={"transaction-row ion-nowrap"} key={`customer-transaction-${transaction.id}`}
                            >
                                <IonCol>{transaction.transactionDateDisplay}</IonCol>
                                <IonCol>{transaction.description}</IonCol>
                                <IonCol className={"ion-text-right"}><CurrencyPrefix customer={activeCustomer}
                                />{transaction.amountSignedDisplay}</IonCol>
                                <IonCol className={"ion-text-right"}><CurrencyPrefix customer={activeCustomer}
                                />
                                    {(!isFetching && transaction.runningTotal) || <SkeletonText style={{lineHeight: 1.1, marginBottom: 0, marginTop: 0, display: "inline-block", width: "60px", height: "16px"}} />}
                                </IonCol>
                            </IonRow>
                        ))}
                    {
                        isFetching && (activeCustomer?.balanceBroughtForwardDisplay || !customerHasFinancial) && (
                            <IonRow className={"transaction-row ion-nowrap"}
                                    key={`customer-transaction-skeleton-forward`}
                            >
                                <IonCol><SkeletonText style={{width: '60px', height: '16px'}} key="placeholder-customer-transaction-date"/></IonCol>
                                <IonCol><SkeletonText style={{width: '120px', height: '16px'}} key="placeholder-customer-transaction-description"/></IonCol>
                                <IonCol className={"ion-text-right"}><SkeletonText style={{display: "inline-block", width: "60px", height: "16px"}} key="placeholder-customer-transaction-amount"/></IonCol>
                                <IonCol className={"ion-text-right"}><SkeletonText style={{display: "inline-block", width: "60px", height: "16px"}} key="placeholder-customer-transaction-total"/></IonCol>
                            </IonRow>

                        )
                    }
                    {
                        (!isFetching && activeCustomer?.balanceBroughtForwardDisplay && activeCustomer?.balanceBroughtForwardDisplay !== '0.00' &&
                            <IonRow className={"transaction-row ion-nowrap"}
                                    key={`customer-transaction-brought-forward`}
                            >
                                <IonCol>{activeCustomer.balanceBroughtForwardDateDisplay}</IonCol>
                                <IonCol>Brought forward</IonCol>
                                <IonCol className={"ion-text-right"}><CurrencyPrefix customer={activeCustomer}
                                />{activeCustomer.balanceBroughtForwardDisplay}</IonCol>
                                <IonCol className={"ion-text-right"}><CurrencyPrefix customer={activeCustomer}
                                />{activeCustomer.balanceBroughtForwardDisplay}</IonCol>
                            </IonRow>
                        )
                    }
                </IonGrid>
            }

            {
                activeCustomer?.payableBankAccount &&
                (activeCustomer?.payableBankAccount.name
                    || activeCustomer?.payableBankAccount.branch
                    || activeCustomer?.payableBankAccount.type
                    || activeCustomer?.payableBankAccount.accountNo
                )
                && [
                    <Separator key={'banking-details-separator-key'}/>,
                    <div className={'ion-margin'} key={'banking-details-separator-holder-key'}>
                        <SubtitleText className={"ion-no-margin"}>Banking Details</SubtitleText>
                        <BankingDetails
                            className={'ion-margin-top ion-margin-bottom'}
                            accountCode={activeCustomer.accountCode}
                            payableBankAccount={activeCustomer?.payableBankAccount}
                        />
                    </div>
                ]
            }


            <div className={'ion-margin ion-padding'}>&nbsp;</div>
        </Page>
    );
};
