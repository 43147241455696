import React from "react";
import {IonItem, IonLabel, IonToggle} from "@ionic/react";

import './SlotFilterModal.css';
import {Company, Employee, Facility, LessonType} from "../../../models";
import ActionModal from "../../core/ActionModal";
import SlotFilter from "./SlotFilter";

interface SlotFilterModalProps {
    isOpen: boolean;
    onClose: () => void;
    company?: Company;

    filterAvailableOnly: boolean;
    setFilterAvailableOnly: (availableOnly: boolean) => void;

    filterBookedOnly: boolean;
    setFilterBookedOnly: (bookedOnly: boolean) => void;

    facilities?: Facility[],
    filterFacility?: number;
    setFilterFacility: (id?: number) => void;

    slotTypes?: LessonType[],
    filterSlotType?: number;
    setFilterSlotType: (id?: number) => void;

    employees?: Employee[],
    filterEmployee?: number;
    setFilterEmployee: (id?: number) => void;
}

const SlotFilterModal: React.FC<SlotFilterModalProps> = (
    {
        isOpen,
        onClose,
        company,
        facilities,
        filterAvailableOnly,
        setFilterAvailableOnly,
        filterBookedOnly,
        setFilterBookedOnly,
        filterFacility,
        setFilterFacility,
        slotTypes,
        filterSlotType,
        setFilterSlotType,
        employees,
        filterEmployee,
        setFilterEmployee,
    }) => {

    return (
        <ActionModal isOpen={isOpen} onClose={onClose} title={"Filters"}>
            <>
                <IonItem lines="full">
                    <IonLabel>
                        <IonToggle onIonChange={event => setFilterBookedOnly(event.detail.checked)}
                                   checked={filterBookedOnly}
                        >Only my bookings</IonToggle>
                    </IonLabel>
                </IonItem>
                <IonItem lines="full">
                    <IonLabel>
                        <IonToggle onIonChange={event => setFilterAvailableOnly(event.detail.checked)}
                                   checked={filterAvailableOnly}
                        >Only available slots</IonToggle>
                    </IonLabel>
                </IonItem>
                <SlotFilter
                    key={"filter-facilities"}
                    filterOptions={facilities}
                    setSelectedId={setFilterFacility}
                    selectedId={filterFacility}
                    name="Facility"
                    displayField="name"
                />
                <SlotFilter
                    key={"filter-slot-types"}
                    filterOptions={slotTypes}
                    setSelectedId={setFilterSlotType}
                    selectedId={filterSlotType}
                    name={`${company?.langLesson} Type`}
                    displayField="name"
                />
                <SlotFilter
                    key={"filter-employees"}
                    filterOptions={employees}
                    setSelectedId={setFilterEmployee}
                    selectedId={filterEmployee}
                    name={company?.langEmployee || "Host"}
                    displayField="fullName"
                />
            </>
        </ActionModal>
    );
};

export default SlotFilterModal;
