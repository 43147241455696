import {ApiResponse} from "../../models";

export function handleApiError(error: any, defaultMessage?: string): Promise<ApiResponse> {
    let response: ApiResponse;
    if (typeof error === "string") {
        response = {
            errorMessage: error
        };
    } else if (error?.response?.data?.message) {
        response = {
            errorMessage: error.response.data.message
        };
    } else if (error?.response?.data) {
        response = {
            errorMessage: error.response.data
        };
    } else {
        response = {
            errorMessage: defaultMessage || "Something went wrong while making a request to the server. Please check back later."
        };
    }

    if (error?.response?.data?.errors){
        response = {...response, errors: error?.response?.data?.errors};
    }

    if (error?.response?.status) {
        response.status = error.response.status;
    }

    return Promise.reject(response);
}
