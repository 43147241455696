import * as React from 'react';

import {AccountPageContainer} from './containers';

interface Props {

}

export const AccountPage: React.FC<Props> = (props) => {
    const {} = props;
    return (
        <AccountPageContainer />
    );
};
