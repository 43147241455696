import React from 'react';
import './LearnerCard.css';
import {useAppSelector} from "../../../redux/hooks";
import {profilePackagesByLearnerIdSelector} from "../../../redux/profile-packages";
import {IonAccordionGroup, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle} from "@ionic/react";
import {Booking, Customer, Learner} from "../../../models";
import ProfilePackageAccordion from "../ProfilePackageAccordion/ProfilePackageAccordion";

export interface LearnerCardProps {
    learner: Learner;
    customer: Customer;

    setSelectedBooking: (booking?: Booking) => void;
}

const LearnerCard: React.FC<LearnerCardProps> = (props) => {
    const {learner, customer, setSelectedBooking} = props;

    const profilePackagesByLearnerId = useAppSelector((state) => profilePackagesByLearnerIdSelector(state, learner.id))

    return (
        <IonCard className={"ion-text-left "} key={`learner-${learner.id}`}>
            <IonCardHeader>
                <IonCardTitle>{learner.fullName}</IonCardTitle>
                {learner.level && <IonCardSubtitle>{learner.level.name}</IonCardSubtitle>}
            </IonCardHeader>
            <IonCardContent className={"ion-no-padding"}>
                {
                    <IonAccordionGroup>
                        {
                            profilePackagesByLearnerId && profilePackagesByLearnerId.map((profilePackage, index) => (
                                <ProfilePackageAccordion
                                    key={`profile-package-accordion-${profilePackage.id}`}
                                    profilePackage={profilePackage}
                                    setSelectedBooking={setSelectedBooking}
                                    useSelfBooking={customer?.company?.useSelfBooking}
                                    useClasses={customer?.company?.useClasses}
                                ></ProfilePackageAccordion>
                            ))
                        }
                    </IonAccordionGroup>
                }
            </IonCardContent>
        </IonCard>
    );
};

export default LearnerCard;
