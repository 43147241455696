import React, {useEffect} from 'react';
import {IonToast} from "@ionic/react";
import {useToastStore} from "../../redux/toast";
import './AppToast.css';

const AppToast: React.FC = () => {
    const toastStore = useToastStore();
    const {hide, setMessage, setHeader, setColor, isOpen, header, message, color, setIsOpen } = toastStore;

    useEffect(() => {
        clearToast();
    }, [])

    const clearToast = () => {
        setHeader('');
        setMessage('');
        setColor('primary');
        setIsOpen(false);
        hide();
    }

    return (
        <IonToast
            cssClass={'app-toast'}
            isOpen={isOpen}
            position={'top'}
            header={header}
            message={message}
            color={color}
            duration={3000}
            onDidDismiss={clearToast}
        />
    )
};

export default AppToast;
