import {unwrapResult} from '@reduxjs/toolkit';
import {useCallback} from 'react';

import {setIsOnlineAction,} from './actions';

import {isOnlineSelector,} from './selectors';

import {useAppDispatch, useAppSelector} from "../hooks";
import {AppDispatch} from "../store";

export const useSystemStore = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const isOnline = useAppSelector(isOnlineSelector);

    const setIsOnline = useCallback(
        (args: {isOnline: boolean}) => {
            return dispatch(setIsOnlineAction(args)).then(unwrapResult);
        },
        [dispatch]
    );

    return {
        isOnline,
        setIsOnline,
    } as const;
};
