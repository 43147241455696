import {createSelector} from '@reduxjs/toolkit';

import {ToastState, type} from './state';

const featureStateSelector = (state: { [type]: ToastState }) =>
    state[type];


export const isOpenSelector = createSelector(
    featureStateSelector,
    state => state.isOpen
);

export const headerSelector = createSelector(
    featureStateSelector,
    state => state.header
);

export const messageSelector = createSelector(
    featureStateSelector,
    state => state.message
);

export const colorSelector = createSelector(
    featureStateSelector,
    state => state.color
);

export const buttonsSelector = createSelector(
    featureStateSelector,
    state => state.buttons
);




