import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import {persistor, store} from './redux';
import {Provider} from 'react-redux';
import {PersistGate} from "redux-persist/integration/react";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import PageLoading from "./components/core/PageLoading";

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
      <Provider store={store}>
          <PersistGate loading={ <PageLoading showLoading={false}/>} persistor={persistor}>
              <App/>
          </PersistGate>
      </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
