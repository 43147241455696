import React from 'react';
import './Seperator.css';

export interface SeparatorProps {
    className?: string;
}

const Separator: React.FC<SeparatorProps> = (props) => {
    const {className} = props;
    return (<div className={`content-separator ${className}`}></div>);
};

export default Separator;
