import {ApiResponse, User} from "../models";
import apiClient from "./api/apiClient";
import {handleApiError} from "./api/apiErrorHandler";

export interface UserDeleteApiRequest {
    password: string;
}

interface MeResponse extends ApiResponse {
    results: { user: User };
}

class UsersService {

    async getUser(): Promise<MeResponse> {
        return await apiClient
            .get(this.baseUrl + "/me")
            .then(
                (response) => response.data,
                (error) => handleApiError(error, "Failed to fetch user")
            )
    }

    async userDelete(args: UserDeleteApiRequest): Promise<ApiResponse> {
        return await apiClient
            .post(this.baseUrl + "/delete", args)
            .then(
                (response) => response.data,
                (error) => handleApiError(error, "Failed to delete user")
            )
    }

    constructor(private readonly baseUrl: string) {
    }
}

export const usersService = new UsersService('/users');
