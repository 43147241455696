import * as React from 'react';
import './about.page.css';
import ModalPage from "../../components/core/ModalPage";
import {useModalPageStore} from "../../redux/modal-pages";

type Props = {
    onClose?: () => void,
};

export const AboutPage: React.FC<Props> = props => {
    const {onClose} = props;
    const modalPageStore = useModalPageStore();
    const {isOpenAbout, setIsOpenAbout} = modalPageStore;

    return (
        <ModalPage className={"about-page"}
                   title={"About"}
                   isOpen={!!isOpenAbout}
                   setIsOpen={setIsOpenAbout}
                   onClose={onClose}
        >
            <div className="ion-padding ion-margin-horizontal">
                <h2>About swool.io</h2>

                <p>Welcome to the swool.io Customer App, your go-to platform for managing interactions with businesses
                    utilizing swool.io's services. Designed with your convenience in mind, our app offers quick access
                    to account history, booking management, and more.</p>

                <h3>Key Features:</h3>
                <ul>
                    <li>Account History: Stay informed with a comprehensive view of your previous bookings, payments,
                        and updates.
                    </li>
                    <li>Booking Management: Effortlessly schedule, reschedule, or cancel appointments, classes, or
                        workshops at your convenience.
                    </li>
                    <li>Progress Tracking: Keep tabs on your attendance, progress and achievements using the integrated
                        tools provided by the businesses you engage with.
                    </li>
                    <li>Notifications &amp; Reminders: Never miss an important update or appointment with personalized
                        alerts and reminders.
                    </li>
                </ul>

                <h3>Why swool.io?</h3>
                <p>As an extension of the swool.io platform, our app is tailored to meet the needs of clients who engage
                    with swool.io-supported businesses. Experience greater flexibility and control over your
                    interactions with service providers.</p>

                <h3>How it Works:</h3>
                <ul>
                    <li>Sign Up/Log In: Use your app swool.io account credentials to sign up or log in.</li>
                    <li>Connect with Your Service Provider: Your account will be linked to your service provider either
                        by them initiating the connection from their side or by confirming an existing connection. Check
                        with the business you engage with to ensure a seamless experience.
                    </li>
                    <li>Start Managing Your Experience: Enjoy the convenience of managing your bookings, tracking your
                        progress, and receiving communications from your service providers directly in the app.
                    </li>
                </ul>

                <h3>Privacy &amp; Security:</h3>
                <p>At swool.io, we take your privacy and security very seriously. Our app adheres to strict data
                    protection standards and ensures that your personal information is always safe and secure. For more
                    details, please review our Privacy Policy.</p>

                <h3>Support:</h3>
                <p>For any questions, concerns, or technical assistance, please reach out to your service provider.
                    Their dedicated customer support team here to help you make the most of your swool.io
                    experience.</p>

                <h3>Stay Connected:</h3>
                <p>Follow us on social media to stay up-to-date with the latest news, updates, and promotions:</p>
                <ul>
                    <li><strong>Facebook:</strong> <a href="https://www.facebook.com/swool.io/"
                    >facebook.com/swool.io</a></li>
                    <li><strong>Instagram:</strong> <a href="https://www.instagram.com/swool.io/"
                    >instagram.com/swool.io</a></li>
                    <li><strong>LinkedIn:</strong> <a href="https://za.linkedin.com/company/swool-io"
                    >za.linkedin.com/company/swool-io</a></li>
                </ul>
                <p>Thank you for choosing swool.io! We are committed to helping you excel in your journey with swool.io
                    powered businesses.</p>

            </div>
        </ModalPage>
    );
};
