import {IonContent, IonModal} from "@ionic/react";
import React from "react";
import Header from "./Header";
import "./Page.css";

export interface ModalPageProps {
    children: any;
    // Modal Props
    isOpen?: boolean;
    setIsOpen: (isOpen: boolean) => {};
    onClose?: () => void;
    // Header Props
    title: any;
    subtitle?: any;
    showHeaderBackgroundColor?: boolean;
    showHeader?: boolean;
    // Loading indicator props
    topRightButton?: any;
    className?: string;
    footer?: any;

    setShowLoading?(loading: boolean | null): void | null;
}


const ModalPage: React.FC<ModalPageProps> = (
    {
        children,
        isOpen,
        setIsOpen,
        onClose,
        title,
        subtitle,
        showHeaderBackgroundColor = true,
        showHeader = true,
        topRightButton,
        footer,
        className,
    }
) => {
    return (
        <IonModal isOpen={isOpen}
                  onDidDismiss={onClose}
                  className={`modal-page  ${className}`}
        >
            {showHeader &&
                <Header title={title}
                        subtitle={subtitle}
                        showMenuButton={false}
                        showBackButton={true}
                        onClickBackButton={() => {
                            setIsOpen(false)
                        }}
                        showBackgroundColor={showHeaderBackgroundColor}
                        topRightButton={topRightButton}

                />}
            <IonContent>
                {children}
            </IonContent>
            {footer}
        </IonModal>
    );
};

export default ModalPage;
