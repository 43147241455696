import {createSelector} from '@reduxjs/toolkit';

import {State, type} from './state';

const featureStateSelector = (state: { [type]: State }) =>
    state[type];

export const errorMessageSelector = createSelector(
    featureStateSelector,
    state => state.errorMessage
);

export const isLoadingSelector = createSelector(
    featureStateSelector,
    state => state.isLoading
);

export const isOpenDeleteModalSelector = createSelector(
    featureStateSelector,
    state => state.isOpenDeleteModal
);
