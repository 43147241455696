import * as React from 'react';

import {DocumentPageContainer} from './containers';
import {useParams} from "react-router";

interface RouterParams {
    id: string;
}

export const DocumentPage: React.FC<RouterParams> = (props) => {
    const {id} = useParams<RouterParams>();
    return (
        <DocumentPageContainer documentId={parseInt(id)} />
    );
};
