import React, {useMemo} from "react";
import {Company, ProfilePackage, Slot} from "../../../models";
import SlotButtons from "../SlotButtons";
import {useAppSelector} from "../../../redux/hooks";
import {bookingIdsFilteredSelector, bookingsFilteredSelector} from "../../../redux/bookings";
import './SlotModal.css';
import SlotDetails from "../SlotDetails/SlotDetails";
import {waitingBookingIdsFilteredSelector} from "../../../redux/waiting-bookings";
import ActionModal from "../../core/ActionModal";

interface SlotModalProps {
    isOpen: boolean;
    onClose: () => void;
    company: Company;
    slot: Slot;
    profilePackage: ProfilePackage;
    canAction: boolean;
    onBookClicked: (slotId: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
    onWaitClicked: (slotId: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
    onCancelWaitClicked: (waitingBookingId: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
    onDeleteClicked: (bookingId: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
    onCancelClicked: (bookingId: number, lessonDateTs: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
}

const SlotModal: React.FC<SlotModalProps> = (
    {
        isOpen,
        onClose,
        company,
        slot,
        profilePackage,
        canAction,
        onBookClicked,
        onWaitClicked,
        onCancelWaitClicked,
        onDeleteClicked,
        onCancelClicked,
    }) => {
    const selectorArgs = useMemo(() => ({profilePackageId: profilePackage.id, slots: [slot]}), [profilePackage.id, slot]);
    const visibleBookings = useAppSelector((state) => bookingsFilteredSelector(state, selectorArgs));
    const visibleBookingIds = useAppSelector((state) => bookingIdsFilteredSelector(state, selectorArgs));
    // visibleBookingIds is repeated in SlotRow
    // waitingBookingIdsFilteredSelector is repeated in SlotRow
    const visibleWaitingBookingIds = useAppSelector((state) => waitingBookingIdsFilteredSelector(state, selectorArgs));
    // slotDescription is repeated in SlotRow
    const slotDescription = `${slot.dowAbbreviation} @ ${slot.startTimeDisplay} - ${slot.lessonDateDisplay}`;
    // const selectedBooking = useAppSelector((state) => bookingIdsFilteredSelector(state, selectorArgs));

    return (
        <ActionModal isOpen={isOpen} onClose={onClose} title={"Slot Details"}>
            <SlotDetails canAction={canAction} slot={slot} />
            <SlotButtons
                key={`slot-modal-${slot.id}-buttons`}
                company={company}
                slot={slot}
                profilePackage={profilePackage}
                canAction={canAction}
                visibleBookings={visibleBookings}
                visibleBookingIds={visibleBookingIds}
                visibleWaitingBookingIds={visibleWaitingBookingIds}
                slotDescription={slotDescription}
                onCancelClicked={onCancelClicked}
                onDeleteClicked={onDeleteClicked}
                onBookClicked={onBookClicked}
                onWaitClicked={onWaitClicked}
                onCancelWaitClicked={onCancelWaitClicked}
                deleteText={'Cancel Booking'}
                cancelText={'Cancel Booking'}
                bookText={'Make Booking'}
                waitText={'Queue on Waiting List'}
                expand="full"
            />
        </ActionModal>
    );
};

export default SlotModal;
