import React from "react";
import './BookingModal.css';
import ActionModal from "../../core/ActionModal";
import {Booking, Company, ProfilePackage} from "../../../models";
import SlotDetails from "../../slots/SlotDetails/SlotDetails";
import BookingButtons from "../BookingButtons";
import {useAppSelector} from "../../../redux/hooks";
import {profilePackageByIdSelector} from "../../../redux/profile-packages";


interface BookingModalProps {
    isOpen: boolean;
    onClose: () => void;

    company?: Company;
    booking?: Booking;
    profilePackage?: ProfilePackage;
}

const BookingModal: React.FC<BookingModalProps> = (
    {
        isOpen,
        onClose,
        company,
        booking,

    }) => {

    const profilePackage = useAppSelector((state) => profilePackageByIdSelector(state, booking?.profilePackageId));


    return (
        <ActionModal isOpen={isOpen} onClose={onClose} title={"Booking Details"}>
            {booking && booking.slot && <SlotDetails canAction={false} slot={booking?.slot} lessonDateTs={!profilePackage?.isRecurring ? booking.startedTimestamp : undefined} />}
            {booking && company && profilePackage &&
                <BookingButtons
                    expand="full"
                    company={company}
                    booking={booking}
                    profilePackage={profilePackage}
                />
            }
        </ActionModal>
    );
};

export default BookingModal;
