import {createSelector} from '@reduxjs/toolkit';

import {adapter, type, WaitingBookingsState} from './state';
import {Slot, WaitingBooking} from "../../models";
import {Ages} from "../../utils/ages";

const {selectAll, selectEntities} = adapter.getSelectors();

const featureStateSelector = (state: { [type]: WaitingBookingsState }) => state[type];

const entitiesSelector = createSelector(
    featureStateSelector,
    selectEntities
);

export const selectedIdSelector = createSelector(
    featureStateSelector,
    state => state.selectedId
);

export const selectedSelector = createSelector(
    entitiesSelector,
    selectedIdSelector,
    (entities, id) => id && entities && entities.hasOwnProperty(id) ? entities[id] || null : null
);

export const isFetchingWaitingBookingsSelector = createSelector(
    featureStateSelector,
    state => state.isFetching
);

export const errorMessageSelector = createSelector(
    featureStateSelector,
    (state) => state.errorMessage
);

export const isChangingWaitingBookingSelector = createSelector(
    featureStateSelector,
    (state) => state.isChangingWaitingBooking
);

export const waitingBookingsSelector = createSelector(
    featureStateSelector,
    selectAll
);

interface waitingBookingsFiltersSelectorArgs {
    profilePackageId?: number,
    slots?: Slot[],
    dows?: number[],
    weekStartDateTs?: number,
}

const filterWaitingBookings = (waitingBookings: WaitingBooking[], args: waitingBookingsFiltersSelectorArgs) => waitingBookings ? waitingBookings.filter(waitingBooking => {
    const {profilePackageId, slots, dows} = args;
    if (profilePackageId && waitingBooking.profilePackageId !== profilePackageId){
        return false;
    }

    if (slots && slots.length === 0) {
        return false;
    }

    if (dows && !dows.includes(waitingBooking.slot.dow)) {
        return false;
    }

    if (slots) {
        const bookedSlots = slots.filter((slot) => {
            return slot.id === waitingBooking.slotId
        });
        if (bookedSlots.length === 0) {
            return false;
        }

        const lessonDates = bookedSlots.map(bookedSlot => bookedSlot.lessonDateTs)
            .filter((d, i, lds) => lds.indexOf(d) === i) // Unique/Distinct only
            .sort((a, b) => (a > b ? 1 : -1));  // Sort by date

        const firstLessonDate = new Ages(lessonDates[0]);
        const lastLessonDate = new Ages(lessonDates[lessonDates.length - 1]);
        const waitingBookingStartDate = new Ages(waitingBooking.startedTimestamp);
        const waitingBookingExpireDate = (waitingBooking.expiredTimestamp && new Ages(waitingBooking.expiredTimestamp)) || null;
        if (!(waitingBookingStartDate.lte(lastLessonDate) && (!waitingBookingExpireDate || waitingBookingExpireDate.gte(firstLessonDate)))) {
            return false;
        }
    }

    return true;
}) : []

export const waitingBookingIdsFilteredSelector = createSelector(
    [
        waitingBookingsSelector,
        // Take the second arg, `profilePackageId`, and forward to the output selector
        (state, args: waitingBookingsFiltersSelectorArgs): waitingBookingsFiltersSelectorArgs => args || {profilePackageId: -1, slots: []}
    ],
    // Output selector
    (waitingBookings, args) => Object.fromEntries(filterWaitingBookings(waitingBookings, args).map((waitingBooking) => {
        return [waitingBooking.slotId, waitingBooking.id]
    }))
)

export const waitingBookingOrderNumbersFilteredSelector = createSelector(
    [
        waitingBookingsSelector,
        // Take the second arg, `profilePackageId`, and forward to the output selector
        (state, args: waitingBookingsFiltersSelectorArgs): waitingBookingsFiltersSelectorArgs => args || {profilePackageId: -1, slots: []}
    ],
    // Output selector
    (waitingBookings, args) => Object.fromEntries(filterWaitingBookings(waitingBookings, args).map((waitingBooking) => {
        return [waitingBooking.slotId, waitingBooking.orderNumber]
    }))
)
