import {createReducer} from '@reduxjs/toolkit';

import {adapter, initialState} from './state';
import {
    clearAllAction,
    clearErrorsAction,
    clearProfilePackageDrawsAction,
    getAllSlotsWeeklyAction,
    setSelectedDrawAction,
    setSelectedLearnerIdAction
} from './actions';
import {ApiResponse} from "../../models";

export const reducer = createReducer(initialState, builder =>
    builder
        .addCase(getAllSlotsWeeklyAction.pending, state => {
            return {...state, isFetching: true, errorMessage: undefined};
        })
        .addCase(getAllSlotsWeeklyAction.fulfilled, (state, action) => {
            const {results, profilePackageId, startDate} = action.payload;
            const {slots} = results;
            const drawKey = `${profilePackageId}-${startDate}`;
            let newState = adapter.getInitialState({ ...state, isFetching: false });

            if (!!profilePackageId) {
                // Get existing slots for the specified profilePackage
                const existingSlots = adapter.getSelectors().selectAll(state).filter((slot) => slot.drawKey === drawKey);
                // Create a Set of new slot IDs for faster lookup
                const newSlotDrawKeysSet = new Set(Object.values(slots).map((slot) => `${slot.drawKey}-${slot.id}`));
                // Remove slots that are not in the new slots array
                for (const existingSlot of existingSlots) {
                    if (!newSlotDrawKeysSet.has(`${existingSlot.drawKey}-${existingSlot.id}`)) {
                        newState = adapter.removeOne(newState, `${existingSlot.drawKey}-${existingSlot.id}`);
                    }
                }
                // Upsert the new slots
                newState = adapter.upsertMany(newState, Object.values(slots));
            } else {
                // Replace all slots with the new slots
                newState = adapter.setAll(newState, Object.values(slots));
            }
            return newState;
        })
        .addCase(getAllSlotsWeeklyAction.rejected, (state, action) => {
            const { errorMessage } = action.payload as ApiResponse;
            return {...state, isFetching: false, errorMessage};
        })

        /** HANDLE NON-API ACTIONS ====================================== */
        .addCase(setSelectedDrawAction.fulfilled, (state, action) => {
            const {drawKey} = action.payload;
            return {...state, selectedDrawKey: drawKey};
        })
        .addCase(clearProfilePackageDrawsAction.fulfilled, (state, action) => {
            const {profilePackageId} = action.payload;
            const ids = state.ids as string[];
            const idsToRemove = ids.filter(id => id.startsWith(`${profilePackageId}-`));

            // Remove all matching entities
            idsToRemove.forEach(id => {
                adapter.removeOne(state, id);
            });

            // return {...state};
        })
        .addCase(setSelectedLearnerIdAction.fulfilled, (state, action) => {
            const {activeLearnerId} = action.payload;
            return {...state, activeLearnerId: activeLearnerId};
        })
        .addCase(clearAllAction.fulfilled, () => adapter.removeAll({...initialState}))
        .addCase(clearErrorsAction.fulfilled, state => ({...state, errorMessage: undefined}))
);
