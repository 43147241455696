import * as React from 'react';
import {useEffect} from 'react';


import {AuthLoginPage} from '../components';
import {useForm} from "react-hook-form";
import {useHistory} from "react-router";
import {useCustomerStore} from "../../../../redux/customers";
import {useAuthStore} from "../../../../redux/auth";
import {useToastStore} from "../../../../redux/toast";

import {
    formFieldInputs as loginFormFieldInputs,
    FormFields as LoginFormFields,
} from "../../../../forms/auth/login.form";

type Props = {};

export const AuthLoginPageContainer: React.FC<Props> = props => {
    const history = useHistory();
    const authStore = useAuthStore();
    const customerStore = useCustomerStore();
    const {} = customerStore

    const loginForm = useForm<LoginFormFields>({mode: "onSubmit",});
    const {setValue, clearErrors: formClearErrors, setError: formSetError} = loginForm;

    const toastStore = useToastStore();
    const {showError} = toastStore;
    const {isLoading, errorMessage, errors, clearErrors: authClearErrors, clearAll: authClearAll} = authStore;

    useEffect(() => {
        if (!!errorMessage) {
            showError({message: errorMessage});
            authClearErrors();
        }
    }, [errorMessage])

    useEffect(() => {
        if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((fieldName: string) => {
                errors[fieldName].map((fieldError: string) => {
                    formSetError(fieldName, {message: fieldError})
                })
            })
        } else {
            formClearErrors();
        }
    }, [errors])

    useEffect(() => {
        formClearErrors();
        authClearAll();
    }, []);


    const goToForgot = () => {
        history.replace('/auth/forgot');
    };

    const onSubmitLogin = (data: { username: string, password: string }) => {
        authStore.login({...data}).then((response) => {
            if (!response?.results?.apiAuth?.error) {
                toastStore.showSuccess({message: response.message || 'Logged in successfully', duration: 2000});
            }
        }).catch(() => { setValue('password', '') });
    };

    return <AuthLoginPage
        isLoading={isLoading || customerStore.isFetching}
        errorMessage={errorMessage}
        goToForgot={goToForgot}
        loginHandleSubmit={onSubmitLogin}
        loginForm={loginForm}
        formFields={loginFormFieldInputs}
    />;
};
