interface CacheItem {
    timestamp: number;
    data: any;
}

const responseCache: Record<string, CacheItem> = {};

export const hasValidCache = (url: string, cacheDurationMs: number): boolean => {
    const cacheItem = responseCache[url];
    if (!cacheItem) return false;

    const currentTime = Date.now();
    return currentTime - cacheItem.timestamp < cacheDurationMs;
};

export const getCache = (url: string): any => {
    return responseCache[url]?.data;
};

export const setCache = (url: string, data: any): void => {
    responseCache[url] = {
        timestamp: Date.now(),
        data,
    };
};
