import {IonImg,} from '@ionic/react';
import * as React from 'react';
import {RefObject} from 'react';

import Page from "../../../components/core/Page";
import './home-page.component.css';
import TabFooter from "../../../components/core/TabFooter";
import LearnerCard from "../../../components/learners/LearnerCard/LearnerCard";
import {Booking, Customer} from "../../../models";
import IconButton from "../../../components/common/IconButton/IconButton";
import {notifications} from "ionicons/icons";
import BookingModal from "../../../components/bookings/BookingModal/BookingModal";

type Props = {
    customer?: Customer | null;
    doRefresh: (event: CustomEvent) => void;
    refresherRef: RefObject<HTMLIonRefresherElement> | ((ref: HTMLIonRefresherElement | null) => void);

    selectedBooking?: Booking;
    setSelectedBooking: (booking?: Booking) => void;
};

export const HomePage: React.FC<Props> = props => {
    const {
        customer,
        doRefresh,
        refresherRef,

        selectedBooking,
        setSelectedBooking,
    } = props;

    return (
        <Page className={"home-page"}
              title={undefined}
              footer={customer && <TabFooter activeRoute={'/home'} customer={customer}/>}
              showMenuButton={true}
              showBackButton={false}
              showLoading={false}
              doRefresh={doRefresh}
              refresherRef={refresherRef}
              topRightButton={<IconButton icon={notifications} slot="end" routerLink={"/notifications"}/>}
        >

            <div className="header-logo-wrapper ion-text-center">
                <IonImg className="header-logo"
                        src={customer && customer.company?.appLogoUrl ? customer.company.appLogoUrl : '/assets/logo.png'}
                />
            </div>

            {
                customer?.learners && customer.learners.map((learner) => (
                    <LearnerCard
                        key={`learner-card-${learner.id}`}
                        customer={customer}
                        learner={learner}
                        setSelectedBooking={setSelectedBooking}
                    />
                ))
            }

            {customer && (
                <BookingModal
                    isOpen={!!selectedBooking}
                    onClose={() => { setSelectedBooking(undefined); }}
                    company={customer.company}
                    booking={selectedBooking}
                />
            )}
        </Page>
    );
};
