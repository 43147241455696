import {ApiResponse, KeyedCommunications} from "../models";
import apiClient from "./api/apiClient";
import {handleApiError} from "./api/apiErrorHandler";

export interface getAllCommunicationsResponse extends ApiResponse {
    results: { communications: KeyedCommunications };
    customerId?: number;
}

class CommunicationsService {
    async getAll(customerId?: number): Promise<getAllCommunicationsResponse> {

        const url = `${this.baseUrl}` + (!!customerId ? `?customer_id=${customerId}` : '' )
        return await apiClient
            .get(url)
            .then(
                (response) => response.data,
                (error) => handleApiError(error, "Failed to retrieve communications data.")
            )
    }

    async setSelectedId(args: {id?: number}): Promise<any> {
        const fakePromise = () => {
            return new Promise(resolve => {
                resolve({id: args.id});
            })
        }
        await fakePromise();
        return await fakePromise();
    }

    constructor(private readonly baseUrl: string) { }
}

export const communicationsService = new CommunicationsService(
    '/communications'
);
