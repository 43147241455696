import * as React from 'react';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';


import {CommunicationsPage} from '../components';
import {useCommunicationsStore} from "../../../redux/communications";
import {CommunicationType} from "../../../models";
import {useCustomerStore} from "../../../redux/customers";

type Props = {
};

export const CommunicationsPageContainer: React.FC<Props> = props => {
    const communicationsStore = useCommunicationsStore();
    const {setSelectedId, communications, getAll, isFetching, selectedCommunication} = communicationsStore;
    const customerStore = useCustomerStore();
    const {activeCustomer, selectedId: selectedCustomerId} = customerStore;
    const refresherRef = useRef<HTMLIonRefresherElement>(null);
    const [isOpenCommunication, setIsOpenCommunication] = useState<boolean>(false);

    const [typeFilter, setTypeFilter] = useState({
        [CommunicationType.EMAIL]: true,
        [CommunicationType.SMS]: true,
        [CommunicationType.PUSH]: true,
    });

    const customerCommunications = useMemo(() => {
        return communications.filter((communication) => {
            return activeCustomer && communication.customerId === activeCustomer.id;
        }).sort((a, b) => (a.sentAtTs < b.sentAtTs ? 1 : -1));
    }, [communications, activeCustomer]);

    const countCommunicationTypes = () => {
        return communications.reduce((acc, comm) => {
            acc[comm.type] = (acc[comm.type] || 0) + 1;
            return acc;
        }, {} as { [key: number]: number });
    };
    const communicationTypeCounts = useMemo(() => countCommunicationTypes(), [communications]);

    const filteredCommunications = useMemo(() => {
        return customerCommunications.filter((comm) => {
            return typeFilter[comm.type];
        });
    }, [communications, typeFilter]);

    const fetchData = useCallback(() => {
        if (activeCustomer){
            getAll({customerId: activeCustomer.id}).finally(() => {
                if (refresherRef.current) {
                    refresherRef.current.complete();
                }
            })
        }
    }, [getAll, activeCustomer, refresherRef])

    const handleTypeFilterChange = (type: CommunicationType, checked: boolean) => {
        setTypeFilter({ ...typeFilter, [type]: checked });
    };

    const handleRefresh = async (event: CustomEvent) => {
        if (activeCustomer){
            fetchData();
        }
    };

    useEffect(() => {
        if (selectedCustomerId){
            fetchData()
        }
    }, [selectedCustomerId])

    const handleCommunicationClicked = (id: number) => {
        if (id){
            setSelectedId({id}).then(() => {
                setIsOpenCommunication(true);
            });
        }
    };

    return <CommunicationsPage
        isFetching={isFetching}
        selectedCommunication={selectedCommunication}
        communications={customerCommunications}
        communicationTypeCounts={communicationTypeCounts}
        filteredCommunications={filteredCommunications}
        doRefresh={handleRefresh}
        refresherRef={refresherRef}
        handleTypeFilterChange={handleTypeFilterChange}
        onCommunicationClicked={handleCommunicationClicked}
        typeFilter={typeFilter}
        isOpenCommunication={isOpenCommunication}
        setIsOpenCommunication={setIsOpenCommunication}
    />;
};
