import React from 'react';
import './SlotDetails.css';
import {IonItem, IonLabel, IonText} from "@ionic/react";
import {Slot} from "../../../models";
import SlotBookingStatusChips from "../SlotBookingStatusChips";
import SlotAvailabilityStatusChips from "../SlotAvailabilityStatusChips";
import SlotAvailableLabel from "../SlotAvailableLabel";
import {Ages} from "../../../utils/ages";

export interface SlotDetailsProps {
    canAction: boolean;
    slot: Slot;
    lessonDateTs?: number;
}

const SlotDetails: React.FC<SlotDetailsProps> = (props) => {
    const {canAction, slot, lessonDateTs} = props;

    const slotDateTs = lessonDateTs ?? slot.lessonDateTs;
    const slotDate = !!slotDateTs && new Ages(slotDateTs);

    return <>
        <IonItem key={`slot-details-${slot.id}-heading`} className="slot-details-heading">
            <IonLabel>
                <h2 className="ion-no-margin ion-margin-top">{`${slot.startTimeDisplay} - ${slot.endTimeDisplay} `}</h2>
                {slotDate && <p className="ion-no-margin" style={{marginRight: "10px"}}>{slotDate.toFormat("ddd, dd mmm yyyy")}</p>}
            </IonLabel>
            <SlotAvailabilityStatusChips positionSlot={"end"} slot={slot} canAction={canAction}/>
            <SlotBookingStatusChips positionSlot={"end"} slot={slot} canAction={canAction}/>
        </IonItem>
        {slot.lessonType &&
            <IonItem key={`slot-details-${slot.id}-subheading`}>
                <IonLabel className="ion-text-wrap">
                    <IonText>{slot.lessonType?.name}</IonText>
                </IonLabel>
            </IonItem>
        }
        <IonItem key={`slot-details-${slot.id}-details`}>
            <IonLabel>
                <SlotAvailableLabel slot={slot}/>
                {slot.facility && (
                    <p><IonText className="text-semi-bold">Facility:</IonText>&nbsp;{slot.facility?.name}</p>
                )}
                {slot.employees && slot.employees.length > 0 && (
                    <p><IonText className="text-semi-bold">Hosted
                        By:</IonText>&nbsp;{slot.employees?.map((employee) => employee.fullName).join(", ")}</p>
                )}
            </IonLabel>
        </IonItem>

        {slot.lessonType && slot.lessonType.description &&
            <IonItem key={`slot-details-${slot.id}-description`} className={"slot-details-description"}>
                <IonLabel className="ion-text-wrap">
                    <div className="slot-details-description-inner">
                    <p style={{whiteSpace: 'pre-wrap'}}>{slot.lessonType?.description}</p>
                    </div>
                </IonLabel>
            </IonItem>
        }
    </>;
};

export default SlotDetails;
