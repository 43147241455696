import {createAsyncThunk} from '@reduxjs/toolkit';

import {type} from './state';
import {systemsService} from "../../services";


export const setIsOnlineAction = createAsyncThunk(
    `${type}/setIsOnlineAction`,
    async (arg: { isOnline: boolean }) => {
        const {isOnline} = arg;
        const result = await systemsService.setIsOnline(isOnline);
        return {
            isOnline: result.isOnline,
        };
    }
);
