import React, {useEffect, useState} from 'react';
import {PushNotifications} from '@capacitor/push-notifications';
import {Device} from '@capacitor/device';
import {Capacitor} from "@capacitor/core";
import {useAuthStore} from "../../redux/auth";
import {useToastStore} from "../../redux/toast";
import {useCustomerStore} from "../../redux/customers";
import {useHistory} from "react-router";

interface PushListenerProps {
}

const PushListener: React.FC<PushListenerProps> = (props) => {
    const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
    const isDeviceAvailable = Capacitor.isPluginAvailable('Device');
    const history = useHistory();
    const {showError} = useToastStore();
    const {registerFcmToken} = useAuthStore();
    const [deviceUuid, setDeviceUuid] = useState<string>();
    const [devicePlatform, setDevicePlatform] = useState<string>();
    const [deviceFcmToken, setDeviceFcmToken] = useState<string>();
    const [registered, setRegistered] = useState<boolean>(false);

    const customerStore = useCustomerStore();
    const {setSelectedId: setSelectedCustomerId, customers} = customerStore;

    const addListeners = async () => {
        await PushNotifications.addListener('registration', token => {
            setDeviceFcmToken(token.value);
        });

        await PushNotifications.addListener('registrationError', err => {
            showNotificationError(err)
        });

        // Uncomment if needed
        // await PushNotifications.addListener('pushNotificationReceived', notification => {
        //     console.log('Push notification received: ', notification);
        // });
        await PushNotifications.addListener('pushNotificationActionPerformed', notificationAction => {
            if (customers
                && notificationAction.notification
                && notificationAction.notification.data
                && notificationAction.notification.data.customerId){
                const customerId = parseInt(notificationAction.notification.data.customerId);
                const customerExists = Object.keys(customers.filter((customer) => {
                    return customer.id = customerId;
                })).length > 0;

                if (customerExists){
                    setSelectedCustomerId({id: customerId}).then(() => {
                        history.push('/active-customer');
                    });
                }
            }
        });
    }

    const showNotificationError = (errorData: any) => {
        console.error('Error data:', errorData);
        showError({
            message: "Error while registering notifications",
            duration: 2000,
        }).catch(() => {
            console.error('Error while displaying "Toast Error"')
        });
    }

    const registerNotifications = async () => {
        if (isPushNotificationsAvailable){
            let permStatus = await PushNotifications.checkPermissions();

            if (permStatus.receive === 'prompt') {
                permStatus = await PushNotifications.requestPermissions();
            }

            if (permStatus.receive === 'granted') {
                await PushNotifications.register();
            }
        }
    }

    // const getDeliveredNotifications = async () => {
    //     const notificationList = await PushNotifications.getDeliveredNotifications();
    //     console.log('delivered notifications', notificationList);
    // }

    useEffect(() => {
        if (!registered && isPushNotificationsAvailable && isDeviceAvailable) {
            Promise.all([Device.getInfo(), Device.getId(), addListeners()])
                .then(([deviceInfo, deviceId]) => {
                    setDevicePlatform(deviceInfo.platform);
                    setDeviceUuid(deviceId.identifier);
                    if (deviceInfo.platform === 'ios' || deviceInfo.platform === 'android') {
                        registerNotifications().catch(showNotificationError);
                    }
                }).catch(showNotificationError);

        }
    }, [isPushNotificationsAvailable, isDeviceAvailable])

    useEffect(() => {
        if (!registered && deviceFcmToken && devicePlatform && deviceUuid) {
            registerFcmToken({deviceUuid, fcmToken: deviceFcmToken, platform: devicePlatform}).then(() => {
                setRegistered(true);
            });
        }
    }, [deviceFcmToken, devicePlatform, deviceUuid])

    return null;
};

export default PushListener;
