import {createReducer} from '@reduxjs/toolkit';

import {adapter, initialState} from './state';
import {
    clearAllAction,
    clearErrorsAction,
    createAction,
    deleteAction,
    getAllAction,
    getByIdAction,
    setSelectedIdAction,
} from './actions';
import {ApiResponse} from "../../models";

export const reducer = createReducer(initialState, builder =>
    builder
        /** HANDLE ACTION ====================================== */
        .addCase(getAllAction.pending, state => {
            return {...state, isFetching: true, errorMessage: undefined};
        })
        .addCase(getAllAction.fulfilled, (state, action) => {
            const {waitingBookings} = action.payload.results;
            return adapter.setAll({...state, isFetching: false}, waitingBookings || []);
        })
        .addCase(getAllAction.rejected, (state, action) => {
            const { errorMessage } = action.payload as ApiResponse;
            return {...state, isFetching: false, errorMessage};
        })

        /** HANDLE ACTION ====================================== */
        .addCase(getByIdAction.pending, state => {
            return {...state, isFetching: true, errorMessage: undefined};
        })
        .addCase(getByIdAction.fulfilled, (state, action) => {
            const {waitingBooking} = action.payload.results;
            return adapter.upsertOne({...state, isFetching: false}, waitingBooking);
        })
        .addCase(getByIdAction.rejected, (state, action) => {
            const { errorMessage } = action.payload as ApiResponse;
            return {...state, isFetching: false, errorMessage};
        })

        /** HANDLE ACTION ====================================== */
        .addCase(setSelectedIdAction.pending, state => {
            return {...state};
        })
        .addCase(setSelectedIdAction.fulfilled, (state, action) => {
            const {selectedId} = action.payload;
            return {...state, selectedId: selectedId};
        })
        .addCase(setSelectedIdAction.rejected, state => {
            return {...state};
        })

        /** HANDLE ACTION ====================================== */
        .addCase(createAction.pending, state => {
            return {...state, isChangingWaitingBooking: true, errorMessage: undefined};
        })
        .addCase(createAction.fulfilled, (state, action) => {
            const {waitingBooking} = action.payload.results;
            if (waitingBooking){
                return adapter.upsertOne({...state, isChangingWaitingBooking: false}, waitingBooking);
            }
            else {
                return {...state, isChangingWaitingBooking: false};
            }
        })
        .addCase(createAction.rejected, (state, action) => {
            const { errorMessage } = action.payload as ApiResponse;
            return {...state, isChangingWaitingBooking: false, errorMessage};
        })

        /** HANDLE ACTION ====================================== */
        .addCase(deleteAction.pending, state => {
            return {...state, isChangingWaitingBooking: true, errorMessage: undefined};
        })
        .addCase(deleteAction.fulfilled, (state, action) => {
            const {waitingBookingId} = action.payload;
            if (waitingBookingId){
                return adapter.removeOne({...state, isChangingWaitingBooking: false}, waitingBookingId);
            }
            else {
                return {...state, isChangingWaitingBooking: false};
            }
        })
        .addCase(deleteAction.rejected, (state, action) => {
            const { errorMessage } = action.payload as ApiResponse;
            return {...state, isChangingWaitingBooking: false, errorMessage};
        })

        /** HANDLE NON-API ACTIONS ====================================== */
        .addCase(clearAllAction.fulfilled, () => adapter.removeAll({...initialState}))
        .addCase(clearErrorsAction.fulfilled, state => ({...state, errorMessage: undefined}))
);
